<template>
  <div
    v-if="visible"
    class="fixed z-40 inset-0 overflow-y-auto"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <div
      class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
    >
      <!--
      Background overlay, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0"
        To: "opacity-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100"
        To: "opacity-0"
    -->
      <div
        @click.prevent="hide"
        class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        aria-hidden="true"
      ></div>

      <!-- This element is to trick the browser into centering the modal contents. -->
      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
        >&#8203;</span
      >

      <!--
      Modal panel, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        To: "opacity-100 translate-y-0 sm:scale-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100 translate-y-0 sm:scale-100"
        To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
    -->
      <div
        class="inline-block align-bottom bg-white rounded-3xl text-left overflow-hidden shadow-xl transform transition-all my-24 sm:my-8 sm:align-middle sm:max-w-5xl sm:w-full"
      >
        <div class="flex flex-col">
          <div class="bg-gray-800 flex justify-between">
            <div class="md:text-2xl text-white font-bold py-2 pl-6">
              Profile Details
            </div>
            <div
              @click.prevent="hide"
              class="rounded-full cursor-pointer bg-blue-400 text-gray-800 text-xs my-2 mr-6 w-28 flex justify-center items-center font-bold"
            >
              back
            </div>
          </div>
          <div class="grid grid-cols-1 md:grid-cols-3 py-12 px-10 gap-10">
            <div class="rounded-lg overflow-hidden bg-gray-200 text-center">
              <div class="bg-blue-400 text-white p-3 text-xl font-bold">
                General Information
              </div>
              <div
                class="grid grid-cols-2  gap-y-7  my-7 text-center text-sm font-bold"
              >
                <div>Name:</div>
                <div class="text-left">
                  {{ selectedProfile.name }}
                </div>
                <div>Type:</div>
                <div class="text-left">
                  {{ selectedProfile.type }}
                </div>
                <div>Service:</div>
                <div class="text-left">
                  {{ selectedProfile.service }}
                </div>
                <div>Enabled:</div>
                <div class="text-left">
                  {{ selectedProfile.enabled ? "Yes" : "No" }}
                </div>
                <div>Price:</div>
                <div class="text-left">
                  {{ selectedProfile.price }}
                </div>
                <div>Speed:</div>
                <div class="text-left">
                  <span>
                    {{
                      selectedProfile.download_rate_kb > 1023
                        ? selectedProfile.download_rate_kb / 1024 + "M"
                        : selectedProfile.download_rate_kb + "K"
                    }}
                  </span>
                  <span
                    >/<span>
                      {{
                        selectedProfile.upload_rate_kb > 1023
                          ? selectedProfile.upload_rate_kb / 1024 + "M"
                          : selectedProfile.upload_rate_kb + "K"
                      }}
                    </span></span
                  >
                </div>
                <div>IP Pool:</div>
                <div class="text-left">{{ selectedProfile.ip_pool }}</div>
              </div>
            </div>

            <div class="rounded-lg overflow-hidden bg-gray-200 text-center">
              <div class="bg-blue-400 text-white p-3 text-xl font-bold">
                Limits
              </div>
              <div
                class="grid grid-cols-2 gap-y-7 my-7 text-center text-sm font-bold"
              >
                <div>Download Speed:</div>
                <div class="text-left">
                  {{
                    selectedProfile.download_rate_kb > 1023
                      ? selectedProfile.download_rate_kb / 1024 + " Mb"
                      : selectedProfile.download_rate_kb + " Kb"
                  }}
                </div>
                <div>Upload Speed:</div>
                <div class="text-left">
                  {{
                    selectedProfile.upload_rate_kb > 1023
                      ? selectedProfile.upload_rate_kb / 1024 + " Mb"
                      : selectedProfile.upload_rate_kb + " Kb"
                  }}
                </div>
                <div>Qouta Limit:</div>
                <div class="text-left">
                  {{
                    selectedProfile.traffic_limit_value_mb
                      ? selectedProfile.traffic_limit_value_mb + " MB"
                      : "unlimited"
                  }}
                </div>
                <div>Date Limit:</div>
                <div class="text-left">
                  {{
                    selectedProfile.expiration_value > 0
                      ? selectedProfile.expiration_value +
                        selectedProfile.expiration_unit
                      : "unlimited"
                  }}
                </div>
              </div>
            </div>

            <div class="rounded-lg overflow-hidden bg-gray-200 text-center">
              <div class="bg-blue-400 text-white p-3 text-xl font-bold">
                Details
              </div>
              <div
                class="grid grid-cols-2 gap-y-7 my-7 text-center text-sm font-bold"
              >
                <div>Created At:</div>
                <div class="text-left">
                  {{ formatDate(selectedProfile.created_at) }}
                </div>
                <div>Last Update:</div>
                <div class="text-left">
                  {{ formatDate(selectedProfile.updated_at) }}
                </div>
                <div>Online Accounts:</div>
                <div class="text-left">
                  {{ selectedProfile.online_accounts }}
                </div>
                <div>Allowed Nases:</div>
                <div
                  v-for="nas in selectedProfile.allowed_nases"
                  v-bind:key="nas.id"
                  class="text-left"
                >
                  <span
                    class="text-white px-1 text-xs bg-green-400 rounded-lg"
                    >{{ nas.shortname }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "ProfileDetails",
  props: ["visible", "selectedProfile"],
  methods: {
    hide() {
      this.$emit("hide-details");
    },
    formatDate(date) {
      return moment(date).format("MMMM Do YYYY, h:mm a");
    },
  },
};
</script>
