<template>
  <svg viewBox="0 0 46 55" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M45.8333 55.0003V48.8892C45.8333 45.6477 44.5456 42.5389 42.2535 40.2468C39.9614 37.9547 36.8526 36.667 33.6111 36.667H12.2222C8.98069 36.667 5.87192 37.9547 3.57981 40.2468C1.2877 42.5389 0 45.6477 0 48.8892V55.0003"
      fill="#3498D8"
    />
    <path
      d="M22.9166 24.4444C29.6667 24.4444 35.1388 18.9724 35.1388 12.2222C35.1388 5.47207 29.6667 0 22.9166 0C16.1664 0 10.6943 5.47207 10.6943 12.2222C10.6943 18.9724 16.1664 24.4444 22.9166 24.4444Z"
      fill="#3498D8"
    />
  </svg>
</template>

<script>
export default {
  name: "WarningIcon",
};
</script>
