<template>
  <div
    v-if="visible"
    class="fixed z-40 inset-0 overflow-y-auto"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <div
      class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
    >
      <!--
      Background overlay, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0"
        To: "opacity-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100"
        To: "opacity-0"
    -->
      <div
        @click.prevent="hide"
        class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        aria-hidden="true"
      ></div>

      <!-- This element is to trick the browser into centering the modal contents. -->
      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
        >&#8203;</span
      >

      <!--
      Modal panel, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        To: "opacity-100 translate-y-0 sm:scale-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100 translate-y-0 sm:scale-100"
        To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
    -->
      <div
        v-if="!loading"
        class="inline-block align-bottom bg-gray-300 rounded-3xl text-left overflow-hidden shadow-xl transform transition-all my-24 sm:my-8 sm:align-middle sm:max-w-5xl sm:w-full"
      >
        <div class="flex flex-col justify-center items-center">
          <div class=" bg-ssr-side_bar-background flex w-full justify-between">
            <div class="md:text-2xl text-white font-bold py-2 pl-6">
              {{ $t("profiles.renew.accounts_title") }}
            </div>

            <div
              @click.prevent="hide"
              class="rounded-full cursor-pointer bg-ssr-side_bar-button_hover text-white text-xs my-2 mr-6 w-28 flex justify-center items-center font-bold"
            >
              {{ $t("accounts.cancel") }}
            </div>
          </div>
          <div
            class="flex flex-col w-full md:w-1/3 items-center justify-center bg-gray-300"
          >
            <label class="font-bold mt-6">{{ $t("accounts.profile") }}</label>
            <input
              v-model="selectedAccount.name"
              type="text"
              class="bg-white
            rounded-full w-full shadow-lg p-1 pl-3 focus:outline-none"
              :disabled="selectedAccount.id"
              :class="{
                'opacity-50 cursor-not-allowed': selectedAccount.id,
              }"
            />
            <Datepicker
              class="mt-6 w-full"
              v-model="date"
              :format="format"
              :startTime="startTime"
              autoApply
            ></Datepicker>
            <button
              v-if="!confirmation"
              @click.prevent="confirmation = true"
              class="bg-green-500 text-white  rounded-full w-full py-1 px-3 my-6"
            >
              {{ $t("profiles.renew.renew") }}
            </button>
          </div>
          <div
            v-if="confirmation"
            class="grid grid-cols-2 my-2 gap-x-2 w-full md:w-1/3 items-center justify-center bg-gray-300 text-white"
          >
            <div class="col-span-2 flex justify-center text-black">
              {{ $t("profiles.renew.accounts_confirm") }}
            </div>
            <button
              @click.prevent="confirmation = false"
              class="flex justify-center bg-red-500 rounded-full py-1"
            >
              {{ $t("control.no") }}
            </button>
            <button
              @click.prevent="store"
              class="flex justify-center bg-green-500 rounded-full py-1"
            >
              {{ $t("control.yes") }}
            </button>
          </div>
        </div>
      </div>
      <div
        class="inline-block align-bottom bg-gray-300 rounded-3xl text-left overflow-hidden shadow-xl transform transition-all my-24 sm:my-8 sm:align-middle"
        v-if="loading"
      >
        <div class="flex flex-col items-center">
          <img
            class="inline-block h-48 w-48"
            src="@/assets/loading.gif"
            alt="Loading"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { hasPrivilege } from "../../global_constants.js";
import { mapGetters } from "vuex";
import axios from "axios";
import NumericalSpinner from "../formElements/NumericalSpinner.vue";
import Datepicker from "vue3-date-time-picker";
import LoadingOverlay from "../modals/LoadingOverlay.vue";
export default {
  components: {
    NumericalSpinner,
    Datepicker,
  },
  name: "RenewProfile",
  props: ["visible", "selectedAccount"],
  computed: {
    ...mapGetters(["user"]),
  },
  data() {
    return {
      renew_amount: 1,
      profiles: null,
      date: null,
      startTime: {
        hours: 0,
        minutes: 0,
      },
      confirmation: false,
      loading: true,
      format: (date) => {
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        const hour = date.getHours();
        const minute = date.getMinutes();

        return `${day}-${month}-${year} ${hour}:${minute}`;
      },
    };
  },
  async created() {
    // await this.fetchProfiles();
    // this.updateExpiration();
    this.date = moment().add(1, "months");
    this.date.hours(0);
    this.date.minutes(0);
    this.loading = false;
  },
  methods: {
    hide() {
      this.$emit("hide-details");
    },
    formatDate(date) {
      return moment(date).format("MMMM Do YYYY, h:mm a");
    },
    hasPrivilege(privilege) {
      return hasPrivilege(this.user, privilege);
    },
    updateExpiration() {
      var profile = this.profiles.find(
        (e) => e.id == this.selectedAccount.profile.id
      );
      if (moment(this.selectedAccount.expires_at) > moment()) {
        this.date = moment(this.selectedAccount.expires_at).add(
          profile.expiration_value * this.renew_amount,
          profile.expiration_unit
        );
        this.date.hours(0);
        this.date.minutes(0);
      } else {
        this.date = moment().add(
          profile.expiration_value * this.renew_amount,
          profile.expiration_unit
        );
        this.date.hours(0);
        this.date.minutes(0);
      }
    },
    async store() {
      // console.log(moment(this.date).format("yyyy-MM-DD HH:mm"));
      // return;
      const response = await axios.post(
        `/profiles/${this.selectedAccount.id}/renew`,
        {
          // renew_amount: this.renew_amount,
          // profile_id: this.selectedAccount.profile.id,
          expires_at: moment(this.date).format("yyyy-MM-DD HH:mm"),
        }
      );
      if (response.status == 200) {
        this.$router.push({
          path: "/profiles",
          query: { status: "renew_suceess" },
        });
        this.$emit("updated");
      }
    },
    async fetchProfiles() {
      const response = await axios.get(
        "/profiles?type=subscribtion&rows=1000000"
      );
      if (response.status == 200) this.profiles = response.data.data;
    },
  },
};
</script>
