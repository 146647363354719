<template>
  <div
    class="p-3 text-black flex justify-around rounded-3xl"
    style="background-color: #CCDE5D"
  >
    <div
      class="felx flex-col justify-center text-center border-r border-black flex-1"
    >
      <div class="flex justify-center items-center">
        <svg
          class="h-10"
          width="50"
          height="50"
          viewBox="0 0 50 50"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.6667 35.4167L25 43.75L33.3334 35.4167"
            stroke="#262937"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M25 25V43.75"
            stroke="#262937"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M43.5 37.6875C45.3112 36.4138 46.6695 34.5961 47.3777 32.4982C48.0858 30.4003 48.1071 28.1313 47.4383 26.0205C46.7695 23.9096 45.4455 22.0668 43.6585 20.7595C41.8714 19.4521 39.7142 18.7483 37.5 18.75H34.875C34.2484 16.3081 33.076 14.0401 31.4461 12.1168C29.8161 10.1936 27.7711 8.66512 25.465 7.64656C23.1589 6.62801 20.6517 6.14588 18.1323 6.23648C15.6129 6.32709 13.1469 6.98805 10.9199 8.16963C8.69289 9.3512 6.76295 11.0226 5.27537 13.058C3.7878 15.0933 2.78134 17.4397 2.33178 19.9203C1.88221 22.4009 2.00125 24.9512 2.67994 27.3792C3.35863 29.8072 4.57927 32.0495 6.24998 33.9375"
            stroke="#262937"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>

        <div class="flex flex-col justify-center text-right">
          <div class="text-md font-bold text-center">{{ download }}</div>
          <div class="text-sm font-light text-center">Dowload</div>
        </div>
      </div>
      <div class="flex justify-center items-center mt-2">
        <svg
          class="h-10"
          width="50"
          height="50"
          viewBox="0 0 50 50"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M33.3334 33.3333L25 25L16.6667 33.3333"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M25 25V43.75"
            stroke="#262937"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M42.4792 38.3125C44.5112 37.2047 46.1164 35.4518 47.0414 33.3305C47.9665 31.2091 48.1588 28.8401 47.588 26.5973C47.0171 24.3545 45.7156 22.3656 43.8889 20.9447C42.0622 19.5237 39.8143 18.7515 37.5 18.75H34.875C34.2444 16.3109 33.0691 14.0465 31.4374 12.1271C29.8057 10.2076 27.7601 8.68302 25.4544 7.66793C23.1487 6.65284 20.6429 6.17366 18.1253 6.26642C15.6077 6.35918 13.1439 7.02146 10.9192 8.20347C8.69439 9.38548 6.76652 11.0565 5.2805 13.0908C3.79448 15.1251 2.78898 17.4699 2.33958 19.9487C1.89018 22.4276 2.00859 24.9761 2.6859 27.4026C3.36321 29.8291 4.58179 32.0706 6.25004 33.9583"
            stroke="#262937"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M33.3334 33.3333L25 25L16.6667 33.3333"
            stroke="#262937"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>

        <div class="flex flex-col justify-center text-right">
          <div class="text-md font-bold text-center">{{ upload }}</div>
          <div class="text-sm font-light text-center">Upload</div>
        </div>
      </div>
    </div>
    <div class="flex flex-col justify-center items-center flex-1 text-black">
      <svg
        width="50"
        height="50"
        viewBox="0 0 50 50"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0)">
          <path
            d="M47.9166 12.5L28.125 32.2917L17.7083 21.875L2.08331 37.5"
            stroke="black"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M35.4167 12.5H47.9167V25"
            stroke="black"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0">
            <rect width="50" height="50" fill="white" />
          </clipPath>
        </defs>
      </svg>
      <div class="text-xl font-bold">{{ bandwidth }}</div>
      <div class="text-sm font-light">Total Traffic</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TrafficPill",
  props: ["bandwidth", "upload", "download"],
};
</script>
