<template>
  <div class="bg-white rounded-full inline-flex shadow-lg">
    <div
      class="bg-gray-800 flex items-center justify-center text-indigo-400 rounded-full px-1 m-0"
    >
      <button @click.prevent="this.$emit('increase')">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6 15L12 9L18 15"
            stroke="#84C2EA"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>

      <button @click.prevent="this.$emit('decrease')">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M18 9L12 15L6 9"
            stroke="#84C2EA"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
    </div>
    <p v-if="!has_input" class="mx-1 py-1 px-7">
      <!-- {{ (new Array(5).join("0") + value).substr(-5) }} -->
      {{ value }}
    </p>
    <input
      v-if="has_input"
      @change="this.$emit('changeto', value)"
      type="text"
      v-model="comp_value"
      class=" rounded-l-full p-1 inline-block w-20"
    />
  </div>
</template>

<script>
export default {
  name: "NumericalSpinner",
  props: ["value", "has_input"],
  computed: {
    comp_value() {
      return (new Array(5).join("0") + this.value).substr(-5);
    },
  },
};
</script>
