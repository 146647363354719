<template>
  <div class="main-content flex-1 relative h-screen bg-gray-100">
    <loading-overlay :visible="loading" />
    <transition name="fade">
      <error-message
        :visible="isError"
        :message="error_message"
        @hide="isError = false"
      />
    </transition>
    <div class="h-14">HighRadius</div>
    <div class="flex justify-center">
      <div
        class="bg-gray-800 w-full flex flex-col md:flex-row justify-between md:rounded-2xl md:m-6 md:mt-12 text-white"
      >
        <h3 class="font-bold p-4 pl-8 text-2xl">
          {{
            this.account.id
              ? $t("profiles.renew.title")
              : $t("profiles.renew.title")
          }}
        </h3>
        <button
          class="bg-red-500 text-white w-24  md:w-auto rounded-2xl text-sm mt-auto mr-4 mb-2 px-5"
          @click.prevent="this.$router.go(-1)"
        >
          {{ $t("profiles.cancel") }}
        </button>
      </div>
    </div>
    <div class="flex justify-center w-full">
      <div
        class="bg-gray-300 rounded-xl w-full m-6 flex flex-col justify-center items-center pb-12 px-10 md:px-20"
      >
        <div class="flex flex-col w-full md:w-1/3 items-center justify-center">
          <label class="font-bold mt-6">{{ $t("accounts.username") }}</label>
          <input
            v-model="account.username"
            @input="validate"
            type="text"
            class="bg-white
            rounded-full w-full shadow-lg p-1 pl-3 focus:outline-none"
            :disabled="account.id"
            :class="{
              'opacity-50 cursor-not-allowed': account.id,
              'shadow-error': errors.username,
            }"
          />
          <div
            class="text-gray-300 mt-0.5"
            :class="{ 'text-red-600': errors.username }"
          >
            {{ errors.username }}
          </div>
          <div
            class="text-gray-300 mt-0.5"
            :class="{ 'text-red-600': errors.password }"
          >
            {{ errors.password }}
          </div>
          <label class="font-bold mt-6">{{ $t("profiles.profile") }}</label>
          <select
            v-model="account.profile_id"
            @change="validate"
            class="bg-white rounded-full w-full shadow-lg p-1 pl-3 focus:outline-none"
            :class="{ 'shadow-error': errors.profile_id }"
          >
            <option
              v-for="profile in profiles"
              v-bind:key="profile.id"
              :value="profile.id"
              >{{ profile.name }}</option
            >
          </select>
          <div
            class="text-gray-300 mt-0.5"
            :class="{ 'text-red-600': errors.profile_id }"
          >
            {{ errors.profile_id }}
          </div>
          <label class="font-bold mt-6">{{
            $t("profiles.renew.amount")
          }}</label>
          <numerical-spinner
            :value="renew_amount"
            @increase="renew_amount = renew_amount + 1"
            @decrease="
              renew_amount =
                renew_amount > 0 ? renew_amount - 1 : (renew_amount = 0)
            "
          />
        </div>
        <div class="flex flex-col items-center mt-6">
          <button
            :disabled="!validated"
            @click.prevent="store"
            class=" bg-green-500 text-white rounded-lg hover:bg-white hover:text-green-500 py-1 px-3"
            :class="{
              ' cursor-not-allowed opacity-50': !validated,
            }"
          >
            {{ this.account.id ? $t("profiles.renew.renew") : "Add" }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters, mapState } from "vuex";
import NumericalSpinner from "../components/formElements/NumericalSpinner.vue";
import ToggleButton from "../components/formElements/ToggleButton.vue";
import Dropdown from "../components/formElements/Dropdown.vue";
import LoadingOverlay from "../components/modals/LoadingOverlay.vue";
import ErrorMessage from "../components/modals/ErrorMessage.vue";
export default {
  components: {
    NumericalSpinner,
    ToggleButton,
    Dropdown,
    LoadingOverlay,
    ErrorMessage,
  },
  name: "RenewAccount",
  data() {
    return {
      error: "",
      message: "",
      renew_amount: 1,
      account: {
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        username: "",
        password: "",
        address: "",
        notes: "",
        profile_id: "",
        ip_address: "",
        allowed_sessions: 0,
        auto_renew: false,
      },
      loading: false,
      profiles: null,
      isError: false,
      error_message: "",
      errors: {
        username: null,
        password: null,
        profile_id: null,
        ip_address: null,
      },
      validated: true,
    };
  },
  async created() {
    this.loading = true;
    await this.fetchProfiles();
    if (this.$route.params.id) {
      await this.fetchAccount(this.$route.params.id);
    }
    this.loading = false;
  },
  methods: {
    async store() {
      const response = await axios.post(`/accounts/${this.account.id}/renew`, {
        renew_amount: this.renew_amount,
        profile_id: this.account.profile_id,
      });
      if (response.status == 200) {
        this.$router.push({
          path: "/accounts",
          query: { status: "renew_suceess" },
        });
      } else {
        this.errors.username = "Renew failed";
      }
    },
    async fetchProfiles() {
      const response = await axios.get("/profiles?rows=1000000");
      if (response.status == 200) this.profiles = response.data.data;
    },
    async fetchAccount(id) {
      const response = await axios.get(`/accounts/${id}`);
      if (response.status == 200) {
        this.account = response.data.data;
        this.account.profile_id = this.account.profile.id;
      }
    },
    validate() {
      this.validated = true;
      this.errors.username = null;
      if (!this.account.username) {
        this.errors.username = "Username cannot by empty";
        this.validated = false;
      }
      if (this.account.username.length < 3) {
        this.validated = false;
        this.errors.username = "Username must be more than 2 letter";
      }
      if (this.account.username.includes("*")) {
        this.validated = false;
        this.errors.username = "Username cannot have * sign";
      }
      this.errors.password = null;
      if (!this.account.password) {
        this.validated = false;
        this.errors.password = "Password cannot by empty";
      }
      if (this.account.password.length < 3) {
        this.validated = false;
        this.errors.password = "Password must be more than 2 letter";
      }
      this.errors.profile_id = null;
      if (!this.account.profile_id) {
        this.validated = false;
        this.errors.profile_id = "Please select profile";
      }
      this.errors.ip_address = null;
      if (
        /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
          this.account.ip_address
        ) == false &&
        this.has_static_ip
      ) {
        this.validated = false;
        this.errors.ip_address = "Invalid ip address";
      }
    },
  },
};
</script>
