<template>
  <div
    v-if="visible"
    class="fixed z-40 inset-0 overflow-y-auto"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <div
      class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
    >
      <div
        @click.prevent="hide"
        class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        aria-hidden="true"
      ></div>

      <!-- This element is to trick the browser into centering the modal contents. -->
      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
        >&#8203;</span
      >

      <div
        class="inline-block align-bottom bg-white pb-6 rounded-3xl text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:w-full"
      >
        <div class="flex flex-col items-center">
          <div
            class=" bg-ssr-side_bar-background w-full flex mb-6 justify-between"
          >
            <div class="text-2xl text-white font-bold py-2 pl-6">
              {{ $t("control.columns") }}
            </div>
            <div
              @click.prevent="hide"
              class="rounded-full cursor-pointer bg-ssr-side_bar-button_hover text-white text-xs my-2 mr-6 w-28 flex justify-center items-center font-bold"
            >
              {{ $t("control.back") }}
            </div>
          </div>
          <div class=" w-4/5 lg:w-1/2">
            <toggle-button
              v-for="(column, index) in currentColumns"
              :key="index"
              class="mb-2 mx-2"
              :value="column.enabled"
              :enabled_name="column.name"
              :disabled_name="column.name"
              @toggle="
                column.enabled = !column.enabled;
                updateColumns();
              "
            />
          </div>
        </div>
        <!-- <div class="flex justify-end">
          <button
            class=" rounded-xl border-2 font-bold px-6 mx-6 border-gray-700 text-gray-700 hover:text-white hover:bg-gray-700"
            @click.prevent="confirmSelect"
          >
            Apply
          </button>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import ToggleButton from "../formElements/ToggleButton.vue";
export default {
  components: { ToggleButton },
  name: "ColumnsSelect",
  props: ["visible", "columns", "name"],
  data() {
    return {
      currentColumns: null,
    };
  },
  created() {
    this.currentColumns = this.columns;
  },
  methods: {
    hide() {
      this.$emit("hide");
    },
    updateColumns() {
      localStorage.setItem(
        this.name,
        JSON.stringify(Object.values(this.columns))
      );
    },
    confirmSelect() {
      this.$emit("selectconfirmed", this.currentColumns);
    },
  },
};
</script>
