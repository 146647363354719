<template>
  <div>
    <table class="table-auto w-full">
      <thead class="bg-ssr-side_bar-settings text-white font-bold">
        <tr>
          <th>#</th>
          <th>{{ $t("support_tickets.id") }}</th>
          <th>{{ $t("support_tickets.username") }}</th>
          <th>
            <div class="inline-block">
              {{ $t("control.created_at") }}
            </div>
            <div
              class="
                rounded-full
                bg-yellow-400
                text-black text-xs
                inline-block
                px-1
                cursor-pointer
              "
              @click="sort('created_at')"
            >
              <div class="inline-block" v-if="todayTickets > 0">
                {{ $t("support_tickets.today") }}
              </div>
              <div
                class="inline-block font-bold text-sm mx-1"
                v-if="todayTickets > 0"
              >
                {{ todayTickets }}
              </div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-4 w-4 inline-block transform transition-all"
                :class="{ 'rotate-180': !sorted }"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 15l7-7 7 7"
                />
              </svg>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(ticket, index) in tickets"
          :key="ticket.id"
          class="text-base text-center hover:bg-blue-200 cursor-pointer"
          :class="{
            'bg-gray-200': index % 2 == 0,
          }"
          @click="
            this.$store.commit('changeKey');
            this.$router.push(`/services/support?id=${ticket.id}`);
          "
        >
          <td class="font-bold">
            {{ index + 1 + (meta.current_page - 1) * meta.per_page }}
          </td>
          <td>{{ ticket.id }}</td>
          <td>{{ ticket.account.username }}</td>
          {{
            formatDate(ticket.created_at)
          }}
        </tr>
      </tbody>
    </table>
    <div v-if="loading" class="w-full flex justify-center">
      <img
        class="inline-block h-12 w-12"
        src="@/assets/loading.gif"
        alt="Loading"
      />
    </div>
    <pagination-row
      class="mt-2"
      @triggerupdate="fetchDataPortion"
      :meta="meta"
      :small="true"
    />
  </div>
</template>
<script>
import axios from "axios";
import PaginationRow from "../PaginationRow.vue";
import moment from "moment";
export default {
  components: { PaginationRow },
  name: "EndingAccountsTable",
  data() {
    return {
      tickets: null,
      meta: { last_page: 0, current_page: 1 },
      filters: {
        rows: 5,
        page: 1,
      },
      loading: false,
      sorted: false,
      todayTickets: 0,
    };
  },
  async created() {
    await this.fetchTickets();
    this.fetchTodayTickets();
  },
  methods: {
    async fetchTickets(sort = false) {
      this.loading = true;
      var req = `support?rows=${this.filters.rows}&page=${this.filters.page}&status=new&sort=${sort}`;
      if (sort) req += `&order_by=created_at&order_dir=DESC`;
      const response = await axios.get(req);
      this.tickets = response.data.data;
      this.meta = response.data.meta;
      this.loading = false;
    },
    async fetchDataPortion(rows, currentPage) {
      this.filters.rows = 5;
      this.filters.page = currentPage;
      this.fetchTickets();
    },
    formatDate(date) {
      return moment(date).format("yyyy-MM-DD HH:mm");
    },
    fetchTodayTickets() {
      var count = 0;
      this.tickets.forEach((element) => {
        if (moment(element.created_at).dayOfYear() == moment().dayOfYear()) {
          count++;
        }
      });
      this.todayTickets = count;
    },
    sort(value) {
      this.sorted = !this.sorted;
      this.fetchTickets(this.sorted);
    },
  },
};
</script>
