<template>
  <div
    @click.prevent="if (this.$refs.menu != null) this.$refs.menu.close();"
    class="main-content flex-1 bg-gray-100"
  >
    <profile-details
      :visible="showDetails"
      :selectedProfile="selectedData"
      @hide-details="showDetails = false"
    />
    <delete-confirm
      :visible="showDelete"
      @hide="showDelete = false"
      @deleteconfirmed="deleteData"
    />
    <columns-select
      :visible="showColumnsSelect"
      :columns="columns"
      :name="'profiles_columns'"
      @hide="showColumnsSelect = false"
      @selectconfirmed="updateColumns(currentColumns)"
    />
    <transition name="fade">
      <warnning-confirm
        :message="$t('messages.profiles.delete_all')"
        :visible="showDeleteAll"
        @confirmed="groupDelete()"
        @hide="showDeleteAll = false"
      />
    </transition>
    <transition name="fade">
      <warnning-confirm
        :message="$t('messages.profiles.disable_all')"
        :visible="showDisable"
        @confirmed="disableProfile()"
        @hide="showDisable = false"
      />
    </transition>
    <transition name="fade">
      <renew-accounts
        v-if="showRenewAccounts"
        :visible="showRenewAccounts"
        :selectedAccount="selectedData"
        @hide-details="showRenewAccounts = false"
        @updated="
          applyFilters();
          showRenewAccounts = false;
        "
      />
    </transition>

    <transition name="expandy">
      <tables-context :display="showContext" ref="menu">
        <ul
          :class="{
            disabled: !this.$store.getters.settings.license.active,
          }"
        >
          <!-- <li
            @click="this.$router.push(`/profiles/${selectedData.id}/edit`)"
            class="m-2 pr-8 border-b border-black hover:bg-gray-200"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6 inline-block bg-yellow-300 rounded-lg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
              />
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
              />
            </svg>
            {{ $t("actions.view") }}
          </li> -->
          <li
            @click="editData(selectedData)"
            class="m-2 border-b border-black hover:bg-gray-200"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6 inline-block rounded-lg bg-blue-300"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
              />
            </svg>
            {{ $t("actions.edit") }}
          </li>
          <li
            class="m-2 border-b border-black hover:bg-gray-200"
            @click="showDisable = true"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6 inline-block bg-gray-400 text-white rounded-lg"
              viewBox="0 0 24 24"
              fill="#FFFFFF"
            >
              <path d="M0 0h24v24H0V0z" fill="none" />
              <path
                d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM4 12c0-4.42 3.58-8 8-8 1.85 0 3.55.63 4.9 1.69L5.69 16.9C4.63 15.55 4 13.85 4 12zm8 8c-1.85 0-3.55-.63-4.9-1.69L18.31 7.1C19.37 8.45 20 10.15 20 12c0 4.42-3.58 8-8 8z"
              />
            </svg>
            {{ $t("actions.disable") }}
          </li>
          <li
            @click="showDelete = true"
            class="m-2 border-b border-black hover:bg-gray-200"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6 inline-block bg-red-500 text-white rounded-lg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
              />
            </svg>
            {{ $t("actions.delete") }}
          </li>
        </ul>
      </tables-context>
    </transition>

    <div class="p-2 md:p-6" @click.prevent="showFilters = false">
      <div class="flex justify-between">
        <div>
          <button
            class="text-xs lg:text-base rounded-full text-black lg:py-1 px-4 mr-4"
            :class="{
              'bg-blue-300 text-black shadow-blue ': autoUpdate,
              'bg-gray-700 text-white': !autoUpdate,
            }"
            @click.prevent="toggleAutoUpdate"
          >
            {{
              autoUpdate
                ? "✓ " + $t("accounts.auto_update")
                : $t("accounts.auto_update")
            }}
          </button>
          <button
            class="text-xs lg:text-base rounded-full text-black lg:py-1 px-4 mr-4"
            :class="{
              'bg-blue-300 text-black shadow-blue ': filters.save,
              'bg-gray-700 text-white': !filters.save,
            }"
            @click.prevent="toggleSaveFilters"
          >
            {{
              autoUpdate
                ? "✓ " + $t("control.save_filters")
                : $t("control.save_filters")
            }}
          </button>
        </div>
        <div class="flex">
          <button
            class="
              rounded-full
              bg-ssr-side_bar-settings
              text-xs
              lg:text-base
              text-white
              py-1
              lg:py-2
              px-4
              font-bold
              mr-4
            "
            @click.prevent="showColumnsSelect = true"
          >
            ...
          </button>
          <button
            v-if="hasPrivilege('profiles_edit_profiles')"
            @click.prevent="this.$router.push('/profiles/create')"
            class="
              rounded-full
              bg-ssr-side_bar-button_hover
              text-xs
              lg:text-base
              text-white
              lg:p-2
              px-2
            "
            :class="{
              disabled: !this.$store.getters.settings.license.active,
            }"
          >
            {{ $t("profiles.add_profile") }}
          </button>
        </div>
      </div>
      <div class="w-full flex justify-start items-center">
        <div class="rounded-full h-4 w-4 bg-blue-700"></div>
        <p class="font-bold text-blue-700">{{ $t("control.subscribtion") }}</p>
        <div class="w-4"></div>
        <div class="rounded-full h-4 w-4 bg-green-700"></div>
        <p class="font-bold text-green-700">{{ $t("control.prepaid") }}</p>
      </div>
      <div
        class="w-full flex flex-col text-center overflow-auto"
        style="height: 30rem"
      >
        <table>
          <thead class="border-b-2 border-black text-xs md:text-base">
            <th></th>
            <th>
              <table-check-box
                class="w-5 h-5 my-3 mx-4 cursor-pointer"
                :value="allSelected"
                @click="checkAll"
              />
            </th>
            <th v-if="columns.name.enabled">
              <div class="inline-block">{{ $t("profiles.name") }}</div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="
                  h-6
                  w-6
                  inline-block
                  transform
                  transition-all
                  cursor-pointer
                "
                :class="{ 'rotate-180': columns.name.sort }"
                @click="sort('name')"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 15l7-7 7 7"
                />
              </svg>
              <input
                v-if="hasPrivilege('profiles_view_search_profiles')"
                type="text"
                class="
                  bg-white
                  rounded-full
                  w-full
                  shadow-lg
                  p-1
                  m-1
                  focus:outline-none
                  text-center
                "
                :placeholder="$t('control.filter')"
                v-model="filters.name"
                @input="applyFilters"
              />
            </th>
            <th v-if="columns.enabled.enabled">
              <div class="inline-block">{{ $t("profiles.enabled") }}</div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="
                  h-6
                  w-6
                  inline-block
                  transform
                  transition-all
                  cursor-pointer
                "
                :class="{ 'rotate-180': columns.enabled.sort }"
                @click="sort('enabled')"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 15l7-7 7 7"
                />
              </svg>
              <select
                v-if="hasPrivilege('profiles_view_search_profiles')"
                @change="applyFilters"
                v-model="filters.enabled"
                class="
                  bg-white
                  rounded-full
                  w-full
                  shadow-lg
                  p-1
                  pl-3
                  focus:outline-none
                  text-center
                "
              >
                <option
                  v-for="ft in [
                    { id: '', name: $t('control.filter') },
                    { id: '0', name: $t('profiles.disabled') },
                    { id: '1', name: $t('profiles.enabled') },
                  ]"
                  v-bind:key="ft.id"
                  :value="ft.id"
                >
                  {{ ft.name }}
                </option>
              </select>
            </th>
            <th v-if="columns.service.enabled">
              <div class="service">{{ $t("profiles.service") }}</div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="
                  h-6
                  w-6
                  inline-block
                  transform
                  transition-all
                  cursor-pointer
                "
                :class="{ 'rotate-180': columns.service.sort }"
                @click="sort('service')"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 15l7-7 7 7"
                />
              </svg>
              <select
                v-if="hasPrivilege('profiles_view_search_profiles')"
                @change="applyFilters"
                v-model="filters.service"
                class="
                  bg-white
                  rounded-full
                  w-full
                  shadow-lg
                  p-1
                  pl-3
                  focus:outline-none
                  text-center
                "
              >
                <option
                  v-for="ft in [
                    { id: '', name: $t('control.filter') },
                    { id: 'any', name: $t('control.any') },
                    { id: 'hotspot', name: $t('control.hotspot') },
                    { id: 'ppp', name: $t('control.ppp') },
                  ]"
                  v-bind:key="ft.id"
                  :value="ft.id"
                >
                  {{ ft.name }}
                </option>
              </select>
            </th>
            <th v-if="columns.type.enabled">
              <div class="inline-block">{{ $t("profiles.type") }}</div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="
                  h-6
                  w-6
                  inline-block
                  transform
                  transition-all
                  cursor-pointer
                "
                :class="{ 'rotate-180': columns.type.sort }"
                @click="sort('type')"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 15l7-7 7 7"
                />
              </svg>
              <select
                v-if="hasPrivilege('profiles_view_search_profiles')"
                @change="applyFilters"
                v-model="filters.type"
                class="
                  bg-white
                  rounded-full
                  w-full
                  shadow-lg
                  p-1
                  pl-3
                  focus:outline-none
                  text-center
                "
              >
                <option
                  v-for="ft in [
                    { id: '', name: $t('control.filter') },
                    { id: 'subscribtion', name: $t('control.subscribtion') },
                    { id: 'prepaid', name: $t('control.prepaid') },
                  ]"
                  v-bind:key="ft.id"
                  :value="ft.id"
                >
                  {{ ft.name }}
                </option>
              </select>
            </th>
            <th v-if="columns.price.enabled">
              <div class="inline-block">{{ $t("profiles.price") }}</div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="
                  h-6
                  w-6
                  inline-block
                  transform
                  transition-all
                  cursor-pointer
                "
                :class="{ 'rotate-180': columns.price.sort }"
                @click="sort('price')"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 15l7-7 7 7"
                />
              </svg>
              <input
                v-if="hasPrivilege('profiles_view_search_profiles')"
                @change="applyFilters"
                type="text"
                class="
                  bg-white
                  rounded-full
                  w-full
                  shadow-lg
                  p-1
                  m-1
                  focus:outline-none
                  text-center
                "
                :placeholder="$t('control.filter')"
                v-model="filters.price"
              />
            </th>
            <th v-if="columns.speed.enabled">
              <div class="inline-block">{{ $t("profiles.speed_limit") }}</div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="
                  h-6
                  w-6
                  inline-block
                  transform
                  transition-all
                  cursor-pointer
                "
                :class="{ 'rotate-180': columns.speed.sort }"
                @click="sort('download_rate_kb')"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 15l7-7 7 7"
                />
              </svg>
              <input
                v-if="hasPrivilege('profiles_view_search_profiles')"
                type="text"
                class="
                  bg-white
                  rounded-full
                  w-full
                  shadow-lg
                  p-1
                  m-1
                  focus:outline-none
                  text-center
                "
                :placeholder="$t('control.filter')"
                @input="applyFilters"
                v-model="filters.speed"
              />
            </th>
            <th v-if="columns.expiration.enabled">
              <div class="inline-block">{{ $t("profiles.expiration") }}</div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="
                  h-6
                  w-6
                  inline-block
                  transform
                  transition-all
                  cursor-pointer
                "
                :class="{ 'rotate-180': columns.expiration.sort }"
                @click="sort('expiration_value')"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 15l7-7 7 7"
                />
              </svg>
            </th>
            <th v-if="columns.quota.enabled">
              <div class="inline-block">{{ $t("profiles.quota") }}</div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="
                  h-6
                  w-6
                  inline-block
                  transform
                  transition-all
                  cursor-pointer
                "
                :class="{ 'rotate-180': columns.quota.sort }"
                @click="sort('traffic_limit_value_mb')"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 15l7-7 7 7"
                />
              </svg>
              <input
                v-if="hasPrivilege('profiles_view_search_profiles')"
                type="text"
                class="
                  bg-white
                  rounded-full
                  w-full
                  shadow-lg
                  p-1
                  m-1
                  focus:outline-none
                  text-center
                "
                :placeholder="$t('control.filter')"
                @input="applyFilters"
                v-model="filters.quota"
              />
            </th>
            <th v-if="columns.ip_pool.enabled">
              <div>{{ $t("profiles.ip_pool") }}</div>
              <input
                v-if="hasPrivilege('profiles_view_search_profiles')"
                type="text"
                class="
                  bg-white
                  rounded-full
                  w-full
                  shadow-lg
                  p-1
                  m-1
                  focus:outline-none
                  text-center
                "
                :placeholder="$t('control.filter')"
                @input="applyFilters"
                v-model="filters.ip_pool"
              />
            </th>
            <th v-if="columns.total_accounts.enabled">
              <div class="inline-block">
                {{ $t("profiles.total_accounts") }}
              </div>
            </th>
            <th v-if="columns.online_accounts.enabled">
              <div class="inline-block">
                {{ $t("profiles.online_accounts") }}
              </div>
            </th>
            <th v-if="columns.allowed_nases.enabled">
              <div>{{ $t("profiles.allowed_nases") }}</div>
            </th>
            <th v-if="columns.allowed_admins.enabled">
              <div>{{ $t("nases.allowed_admins") }}</div>
            </th>
          </thead>
          <tbody>
            <tr
              v-for="(profile, index) in dataList"
              v-bind:key="profile.id"
              class="text-xs md:text-base"
              :class="{
                'bg-gray-200': index % 2 == 0,
                'bg-blue-300': profile.selected,
              }"
              @contextmenu.prevent="openContextMenu($event, profile)"
            >
              <td class="font-bold">
                {{ index + 1 + (meta.current_page - 1) * meta.per_page }}
              </td>
              <td>
                <table-check-box
                  class="w-5 h-5 my-3 mx-4 cursor-pointer"
                  :value="profile.selected"
                  @click="selectData(profile)"
                />
              </td>
              <td v-if="columns.name.enabled">
                <a
                  class="
                    
                    cursor-pointer
                    border-b 
                    font-bold
                  "
                  :class="{
                    'border-blue-700 text-blue-700':
                      profile.type == 'subscribtion',
                    'border-green-700 text-green-700':
                      profile.type == 'prepaid',
                    disabled: !this.$store.getters.settings.license.active,
                  }"
                  @click="this.$router.push(`/profiles/${profile.id}/edit`)"
                  >{{ profile.name }}</a
                >
              </td>

              <td v-if="columns.enabled.enabled">
                <div
                  v-if="profile.enabled"
                  class="
                    px-3
                    p-1
                    bg-green-600
                    text-white text-center text-xs
                    rounded-full
                    inline-block
                  "
                >
                  {{ $t("profiles.enabled") }}
                </div>
                <div
                  v-if="!profile.enabled"
                  class="
                    px-3
                    p-1
                    bg-gray-800
                    text-white text-center text-xs
                    rounded-full
                    inline-block
                  "
                >
                  {{ $t("profiles.disabled") }}
                </div>
              </td>
              <td v-if="columns.service.enabled">
                {{
                  profile.service == "any" ? $t("control.any") : profile.service
                }}
              </td>
              <td v-if="columns.type.enabled">
                {{
                  profile.type == "prepaid"
                    ? $t("control.prepaid")
                    : $t("control.subscribtion")
                }}
              </td>
              <td v-if="columns.price.enabled">
                {{ profile.price }}
              </td>
              <td v-if="columns.speed.enabled">
                <div
                  v-if="profile.download_rate_kb != 0"
                  class="
                    inline-block
                    bg-blue-400
                    text-white
                    rounded-xl
                    px-1
                    mx-1
                  "
                >
                  {{ formatSpeed(profile.download_rate_kb) }}
                </div>
                <div
                  v-if="profile.upload_rate_kb != 0"
                  class="
                    inline-block
                    bg-red-400
                    text-white
                    rounded-xl
                    px-1
                    mx-1
                  "
                >
                  {{ formatSpeed(profile.upload_rate_kb) }}
                </div>
              </td>
              <td v-if="columns.expiration.enabled">
                <div v-if="profile.has_expiration">
                  <div class="inline-block">{{ profile.expiration_value }}</div>
                  <div class="inline-block ml-1">
                    {{
                      profile.expiration_unit == "days"
                        ? $t("profiles.unit.days")
                        : profile.expiration_unit == "months"
                        ? $t("profiles.unit.months")
                        : $t("profiles.unit.hours")
                    }}
                  </div>
                </div>
                <div v-if="!profile.has_expiration">
                  {{ $t("control.unlimited") }}
                </div>
              </td>

              <td v-if="columns.quota.enabled">
                <div v-if="profile.traffic_limited">
                  {{
                    profile.traffic_limit_value_mb > 1023
                      ? profile.traffic_limit_value_mb / 1024 + " GB"
                      : profile.traffic_limit_value_mb + " MB"
                  }}
                </div>
                <div v-if="!profile.traffic_limited">
                  {{ $t("control.unlimited") }}
                </div>
              </td>
              <td v-if="columns.ip_pool.enabled">
                {{ profile.ip_pool }}
              </td>
              <td v-if="columns.total_accounts.enabled">
                <div
                  v-if="hasPrivilege('profiles_view_profiles_accounts')"
                  class="
                    px-3
                    p-1
                    bg-blue-400
                    text-white text-center text-xs
                    rounded-full
                    inline-block
                  "
                >
                  {{ profile.total_accounts }}
                </div>
              </td>
              <td v-if="columns.online_accounts.enabled">
                <div
                  class="
                    px-3
                    p-1
                    bg-green-400
                    text-white text-center text-xs
                    rounded-full
                    inline-block
                  "
                >
                  {{ profile.online_accounts }}
                </div>
              </td>
              <td v-if="columns.allowed_nases.enabled">
                <div
                  v-if="hasPrivilege('profiles_view_profiles_nases')"
                  class="overflow-y-auto h-20"
                >
                  <div v-for="nas in profile.allowed_nases" v-bind:key="nas.id">
                    <span
                      class="text-white px-2 text-sm bg-green-400 rounded-lg"
                      >{{ nas.shortname }}</span
                    >
                  </div>
                </div>
              </td>
              <td v-if="columns.allowed_admins.enabled">
                <div
                  v-if="hasPrivilege('profiles_view_profiles_admins')"
                  class="overflow-y-auto h-20"
                >
                  <div
                    v-for="admin in profile.allowed_admins"
                    v-bind:key="admin.id"
                  >
                    <span
                      class="text-white px-2 text-sm bg-green-400 rounded-lg"
                      >{{ admin.email }}</span
                    >
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-if="loading" class="w-full flex justify-center">
        <img
          class="inline-block h-12 w-12"
          src="@/assets/loading.gif"
          alt="Loading"
        />
      </div>
      <div
        class="flex justify-start"
        v-if="selection_array.length == 1"
        :class="{
          disabled: !this.$store.getters.settings.license.active,
        }"
      >
        <!-- <div
          @click="
            selectedData = selection_array[0];
            showDetails = true;
          "
          class="ml-2 cursor-pointer group relative"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6 inline-block bg-yellow-300 rounded-lg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
            />
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
            />
          </svg>
          <div
            class="scale-0 transform transition-all ease-linear group-hover:scale-100 absolute"
          >
            {{ $t("actions.view") }}
          </div>
        </div> -->
        <div
          v-if="hasPrivilege('profiles_edit_profiles')"
          @click="editData(selection_array[0])"
          class="mx-3 cursor-pointer group relative"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6 inline-block rounded-lg bg-blue-300"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
            />
          </svg>
          <div
            class="
              scale-0
              transform
              transition-all
              ease-linear
              group-hover:scale-100
              absolute
            "
          >
            {{ $t("actions.edit") }}
          </div>
        </div>
        <div
          v-if="hasPrivilege('profiles_delete_profiles')"
          @click="
            selectedData = selection_array[0];
            showDelete = true;
          "
          class="group cursor-pointer relative"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6 inline-block bg-red-500 text-white rounded-lg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
            />
          </svg>
          <div
            class="
              scale-0
              transform
              transition-all
              ease-linear
              group-hover:scale-100
              absolute
            "
          >
            {{ $t("actions.delete") }}
          </div>
        </div>
      </div>
      <div
        class="flex justify-start"
        v-if="selection_array.length > 1"
        :class="{
          disabled: !this.$store.getters.settings.license.active,
        }"
      >
        <div
          v-if="hasPrivilege('profiles_disable_profiles')"
          class="cursor-pointer group relative mx-3"
          @click="showDisableAll = true"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6 inline-block bg-gray-400 text-white rounded-lg"
            viewBox="0 0 24 24"
            fill="#FFFFFF"
          >
            <path d="M0 0h24v24H0V0z" fill="none" />
            <path
              d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM4 12c0-4.42 3.58-8 8-8 1.85 0 3.55.63 4.9 1.69L5.69 16.9C4.63 15.55 4 13.85 4 12zm8 8c-1.85 0-3.55-.63-4.9-1.69L18.31 7.1C19.37 8.45 20 10.15 20 12c0 4.42-3.58 8-8 8z"
            />
          </svg>
          <div
            class="
              scale-0
              transform
              transition-all
              ease-linear
              group-hover:scale-100
              absolute
            "
          >
            {{ $t("actions.disable") }}
          </div>
        </div>
        <div
          v-if="hasPrivilege('profiles_delete_profiles')"
          @click="showDeleteAll = true"
          class="group cursor-pointer relative"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6 inline-block bg-red-500 text-white rounded-lg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
            />
          </svg>
          <div
            class="
              scale-0
              transform
              transition-all
              ease-linear
              group-hover:scale-100
              absolute
            "
          >
            {{ $t("actions.delete") }}
          </div>
        </div>
      </div>
      <pagination-row
        class="mt-2"
        @triggerupdate="fetchDataPortion"
        :meta="meta"
        :row="filters.rows"
      />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import PillDefault from "../components/PillDefault.vue";
import DeleteConfirm from "../components/modals/DeleteConfirm.vue";
import PaginationRow from "../components/PaginationRow.vue";
import TablesContext from "../components/contexts/TablesContext.vue";
import ColumnsSelect from "../components/modals/ColumnsSelect.vue";
import ProfileDetails from "../components/modals/ProfileDetails.vue";
import TableCheckBox from "../components/formElements/TableCheckBox.vue";
import WarnningConfirm from "../components/modals/WarnningConfirm.vue";
import { mapGetters } from "vuex";
import { hasPrivilege } from "../global_constants.js";
import RenewAccounts from "../components/modals/RenewAccounts.vue";
export default {
  components: {
    PillDefault,
    DeleteConfirm,
    PaginationRow,
    TablesContext,
    ColumnsSelect,
    ProfileDetails,
    TableCheckBox,
    WarnningConfirm,
    RenewAccounts,
  },
  name: "Profiles",
  computed: {
    ...mapGetters(["user"]),
  },
  data() {
    return {
      dataList: null,
      meta: { last_page: 0 },
      selectedData: null,
      showDetails: false,
      showDelete: false,
      showDeleteAll: false,
      showDisable: false,
      showContext: false,
      showRenewAccounts: false,
      loading: true,
      showFilters: false,
      fullList: false,
      filters: {
        rows: 50,
        page: "",
        save: false,
        name: "",
        service: "",
        type: "",
        enabled: "",
        price: "",
        speed: "",
        expiration: "",
        quota: "",
        ip_pool: "",
        online_accounts: "",
        total_accounts: "",
        allowed_nases: "",
        allowed_admins: "",
      },
      checkedBox: true,
      allSelected: false,
      selection_array: [],
      showColumnsSelect: false,
      columns: {
        name: { name: this.$t("profiles.name"), enabled: true, sort: false },
        service: {
          name: this.$t("profiles.service"),
          enabled: false,
          sort: false,
        },
        type: { name: this.$t("profiles.type"), enabled: false, sort: false },
        enabled: {
          name: this.$t("profiles.enabled"),
          enabled: false,
          sort: false,
        },
        price: { name: this.$t("profiles.price"), enabled: false, sort: false },
        speed: {
          name: this.$t("profiles.speed_limit"),
          enabled: true,
          sort: false,
        },
        expiration: {
          name: this.$t("profiles.expiration"),
          enabled: true,
          sort: false,
        },
        quota: { name: this.$t("profiles.quota"), enabled: true, sort: false },
        ip_pool: {
          name: this.$t("profiles.ip_pool"),
          enabled: false,
          sort: false,
        },
        total_accounts: {
          name: this.$t("profiles.total_accounts"),
          enabled: false,
          sort: false,
        },
        online_accounts: {
          name: this.$t("profiles.online_accounts"),
          enabled: false,
          sort: false,
        },
        allowed_nases: {
          name: this.$t("profiles.allowed_nases"),
          enabled: false,
          sort: false,
        },
        allowed_admins: {
          name: this.$t("nases.allowed_admins"),
          enabled: true,
          sort: false,
        },
      },
      autoUpdate: false,
      debounce: null,
    };
  },
  async created() {
    this.prepareFilters();
    this.prepareColumns();
    if (this.$route.query.name) {
      this.filters.name = this.$route.query.name;
      this.columns.name.enabled = true;
    }
    await this.applyFilters();
  },
  methods: {
    hasPrivilege(privilege) {
      return hasPrivilege(this.user, privilege);
    },
    async fetchData() {
      if (this.filters.rows > 0 || this.filters.page > 0) {
        this.fetchDataPortion(this.filters.rows, this.filters.page);
        this.selection_array = [];
        return;
      }
      const response = await axios.get("profiles");
      this.dataList = response.data.data;
      this.meta = response.data.meta;
      this.loading = false;
    },
    prepareFilters() {
      var filters = localStorage.getItem("profiles_filters");
      if (filters != null) {
        filters = JSON.parse(filters);
        if (filters.save) this.filters = filters;
        else this.filters.rows = filters.rows;
      }
    },
    prepareColumns() {
      var cols = localStorage.getItem("profiles_columns");
      if (cols != null) {
        cols = JSON.parse(cols);
        this.columns = {
          name: {
            name: this.$t("profiles.name"),
            enabled: cols[0].enabled,
            sort: false,
          },
          service: {
            name: this.$t("profiles.service"),
            enabled: cols[1].enabled,
            sort: false,
          },
          type: {
            name: this.$t("profiles.type"),
            enabled: cols[2].enabled,
            sort: false,
          },
          enabled: {
            name: this.$t("profiles.enabled"),
            enabled: cols[3].enabled,
            sort: false,
          },
          price: {
            name: this.$t("profiles.price"),
            enabled: cols[4].enabled,
            sort: false,
          },
          speed: {
            name: this.$t("profiles.speed_limit"),
            enabled: cols[5].enabled,
            sort: false,
          },
          expiration: {
            name: this.$t("profiles.expiration"),
            enabled: cols[6].enabled,
            sort: false,
          },
          quota: {
            name: this.$t("profiles.quota"),
            enabled: cols[7].enabled,
            sort: false,
          },
          ip_pool: {
            name: this.$t("profiles.ip_pool"),
            enabled: cols[8].enabled,
            sort: false,
          },
          total_accounts: {
            name: this.$t("profiles.total_accounts"),
            enabled: cols[9].enabled,
            sort: false,
          },
          online_accounts: {
            name: this.$t("profiles.online_accounts"),
            enabled: cols[10].enabled,
            sort: false,
          },
          allowed_nases: {
            name: this.$t("profiles.allowed_nases"),
            enabled: cols[11].enabled,
            sort: false,
          },
          allowed_admins: {
            name: this.$t("nases.allowed_admins"),
            enabled: cols[12] != null ? cols[12].enabled : true,
            sort: false,
          },
        };
      }
    },
    async applyFilters() {
      this.loading = true;
      var requestString = "profiles?filters=1";
      if (this.filters.page != "")
        requestString += `&page=${this.filters.page}`;
      if (this.filters.rows != "")
        requestString += `&rows=${this.filters.rows}`;
      if (this.filters.name != "")
        requestString += `&name=${this.filters.name}`;
      if (this.filters.service != "")
        requestString += `&service=${this.filters.service}`;
      if (this.filters.type != "")
        requestString += `&type=${this.filters.type}`;
      if (this.filters.price != "")
        requestString += `&price=${this.filters.price}`;
      if (this.filters.enabled != "")
        requestString += `&enabled=${this.filters.enabled}`;
      if (this.filters.speed != "")
        requestString += `&speed=${this.filters.speed}`;
      if (this.filters.quota != "")
        requestString += `&quota=${this.filters.quota}`;
      if (this.filters.expiration != "")
        requestString += `&expiration=${this.filters.expiration}`;
      if (this.filters.ip_pool != "")
        requestString += `&ip_pool=${this.filters.ip_pool}`;
      if (this.filters.online_accounts != "")
        requestString += `&online_accounts=${this.filters.online_accounts}`;
      if (this.filters.allowed_nases != "")
        requestString += `&allowed_nases=${this.filters.allowed_nases}`;
      if (this.sort_col)
        requestString += `&order_by=${this.sort_col}&order_dir=${this.sort_dir}`;
      clearTimeout(this.debounce);
      this.debounce = setTimeout(async () => {
        const response = await axios.get(requestString);
        this.dataList = response.data.data;
        if (this.filters.save)
          localStorage.setItem(
            "profiles_filters",
            JSON.stringify(this.filters)
          );
        if (this.dataList.length < 1 && this.filters.page != 1) {
          this.filters.page = 1;
          this.applyFilters();
        }
        if (this.sort_col) {
          if (this.sort_dir == "ASC") {
            this.dataList.sort((a, b) =>
              a[this.sort_col] > b[this.sort_col]
                ? 1
                : b[this.sort_col] > a[this.sort_col]
                ? -1
                : 0
            );
          } else {
            this.dataList.sort((a, b) =>
              a[this.sort_col] < b[this.sort_col]
                ? 1
                : b[this.sort_col] < a[this.sort_col]
                ? -1
                : 0
            );
          }
        }
        this.meta = response.data.meta;
        this.loading = false;
      }, 1000);
    },
    async fetchDataPortion(rows, currentPage) {
      this.filters.rows = rows;
      this.filters.page = currentPage;
      this.applyFilters();
    },
    editData(selectedData) {
      this.$router.push(`/profiles/${selectedData.id}/edit`);
    },
    async deleteData() {
      const response = await axios.delete(`profiles/${this.selectedData.id}`);
      if (response.status == 200) {
        this.fetchData();
        this.showDelete = false;
        this.$router.push({ query: { success: "success" } });
      }
    },
    async disableData() {
      const response = await axios.put(
        `profiles/${this.selectedData.id}/disable`
      );
      if (response.status == 200) {
        this.fetchData();
        this.showDelete = false;
        this.$router.push({ query: { success: "success" } });
      }
    },
    formatDate(date) {
      return moment(date).format("MMMM Do YYYY");
    },
    formatSpeed(speed) {
      if (speed < 0) return "∞";
      if (speed > 1023) return speed / 1024 + " Mb/s";
      else return speed + " Kb/s";
    },
    openContextMenu(e, data) {
      this.selectedData = data;
      this.$refs.menu.open(e);
    },
    checkAll() {
      if (this.allSelected) {
        this.selection_array = [];
        this.dataList.forEach((element) => {
          element.selected = false;
        });
        this.allSelected = false;
      } else {
        this.selection_array = [];
        this.dataList.forEach((element) => {
          element.selected = true;
          this.selection_array.push(element);
        });
        this.allSelected = true;
      }
    },
    selectData(data) {
      if (data.selected == false) {
        data.selected = true;
        this.selection_array.push(data);
      } else {
        data.selected = false;
        this.selection_array.splice(this.selection_array.indexOf(data), 1);
      }
    },
    async groupDelete() {
      const response = await axios.post(`profiles/group`, {
        profiles: this.selection_array,
      });
      if (response.status == 200) {
        this.fetchData();
        this.showDeleteAll = false;
        this.$router.push({ query: { success: "success" } });
      }
    },
    updateColumns(updated) {
      this.columns = updated;
      this.showColumnsSelect = false;
    },
    async toggleAutoUpdate() {
      this.autoUpdate = !this.autoUpdate;
      while (this.autoUpdate) {
        this.applyFilters();
        await new Promise((r) => setTimeout(r, 30000));
      }
    },
    sort(param) {
      this.sort_col = param;
      if (param == "traffic_limit_value_mb") param = "quota";
      if (param == "expiration_value") param = "expiration";
      if (param == "download_rate_kb") param = "speed";
      if (!this.columns[param].sort) {
        this.sort_dir = "ASC";
        Object.entries(this.columns).forEach(([key, val]) => {
          val.sort = false;
        });
        this.columns[param].sort = true;
      } else {
        this.sort_dir = "DESC";
        Object.entries(this.columns).forEach(([key, val]) => {
          val.sort = false;
        });
        this.columns[param].sort = false;
      }
      this.applyFilters();
    },
    sort_expiration() {
      this.sort_col = "expiration_value";
      if (!this.columns.expiration.sort) {
        this.sort_dir = "ASC";
        this.dataList.sort((a, b) =>
          a.expiration_value > b.expiration_value
            ? 1
            : b.expiration_value > a.expiration_value
            ? -1
            : 0
        );
        this.columns.expiration.sort = true;
      } else {
        this.sort_dir = "DESC";
        this.dataList.sort((a, b) =>
          a.expiration_value < b.expiration_value
            ? 1
            : b.expiration_value < a.expiration_value
            ? -1
            : 0
        );
        this.columns.expiration.sort = false;
      }
    },
    sort_speed() {
      this.sort_col = "download_rate_kb";
      if (!this.columns.speed.sort) {
        this.sort_dir = "ASC";
        this.dataList.sort((a, b) =>
          a.download_rate_kb + a.upload_rate_kb >
          b.download_rate_kb + b.upload_rate_kb
            ? 1
            : b.download_rate_kb + b.upload_rate_kb >
              a.download_rate_kb + a.upload_rate_kb
            ? -1
            : 0
        );
        this.columns.speed.sort = true;
      } else {
        this.sort_dir = "DESC";
        this.dataList.sort((a, b) =>
          a.download_rate_kb + a.upload_rate_kb <
          b.download_rate_kb + b.upload_rate_kb
            ? 1
            : b.download_rate_kb + b.upload_rate_kb <
              a.download_rate_kb + a.upload_rate_kb
            ? -1
            : 0
        );
        this.columns.speed.sort = false;
      }
    },
    sort_quota() {
      this.sort_col = "traffic_limit_value_mb";
      if (!this.columns.quota.sort) {
        this.sort_dir = "ASC";
        this.dataList.sort((a, b) =>
          a.traffic_limit_value_mb > b.traffic_limit_value_mb
            ? 1
            : b.traffic_limit_value_mb > a.traffic_limit_value_mb
            ? -1
            : 0
        );
        this.columns.quota.sort = true;
      } else {
        this.sort_dir = "DESC";
        this.dataList.sort((a, b) =>
          a.traffic_limit_value_mb < b.traffic_limit_value_mb
            ? 1
            : b.traffic_limit_value_mb < a.traffic_limit_value_mb
            ? -1
            : 0
        );
        this.columns.quota.sort = false;
      }
    },
    toggleSaveFilters() {
      this.filters.save = !this.filters.save;
      localStorage.setItem("profiles_filters", JSON.stringify(this.filters));
    },
  },
};
</script>
