<template>
  <div class="main-content flex-1 bg-gray-100">
    <loading-overlay :loading="loading_overlay" />
    <div v-if="this.$store.getters.settings.license.active" class="p-4">
      <div
        class="w-full flex flex-col text-center  overflow-auto"
        style="height: 45rem"
      >
        <table>
          <thead class="border-b-2 border-black text-lg">
            <th></th>
            <th class="py-3">{{ $t("accounts.full_name") }}</th>
            <th>{{ $t("accounts.username") }}</th>
            <th>{{ $t("online.type") }}</th>
            <th>{{ $t("accounts.profile") }}</th>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in dataList"
              :key="item.id"
              :class="{
                'bg-gray-200': index % 2 == 0,
              }"
              class=" hover:bg-blue-200 cursor-pointer"
              @click="
                item.type == 'account'
                  ? this.$router.push(
                      `/accounts?useranme=${item.resource.username}`
                    )
                  : this.$router.push(
                      `/cards/cards?useranme=${item.resource.username}`
                    )
              "
            >
              <td>
                {{ index + 1 + (meta.current_page - 1) * meta.per_page }}
              </td>
              <td>
                {{
                  item.type == "account"
                    ? item.resource.first_name + " " + item.resource.last_name
                    : ""
                }}
              </td>
              <td class="py-2">{{ item.resource.username }}</td>
              <td>
                <div v-if="item.type == 'account'">
                  {{ $t("accounts.account") }}
                </div>
                <div v-else>
                  {{ $t("cards.card") }}
                </div>
              </td>
              <td>{{ item.resource.profile.name }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-if="loading" class="w-full flex justify-center">
        <img
          class=" inline-block h-12 w-12"
          src="@/assets/loading.gif"
          alt="Loading"
        />
      </div>
      <pagination-row
        class="mt-2"
        @triggerupdate="fetchDataPortion"
        :meta="meta"
        :row="filters.rows"
      />
    </div>
    <expired-window v-else />
  </div>
</template>
<script>
import axios from "axios";
import LoadingOverlay from "../components/modals/LoadingOverlay.vue";
import PaginationRow from "../components/PaginationRow.vue";
import ExpiredWindow from "../components/ExpiredWindow.vue";
export default {
  name: "Search",
  components: {
    LoadingOverlay,
    PaginationRow,
    ExpiredWindow,
  },
  data() {
    return {
      query: "",
      dataList: [],
      filters: {
        rows: 50,
        page: 1,
      },
      meta: {
        last_page: 0,
      },
      loading_overlay: false,
      loading: false,
    };
  },
  watch: {
    "$route.query.query": {
      handler: function(ff) {
        this.query = this.$route.query.query;
        this.search();
      },
      deep: true,
      immediate: true,
    },
  },
  async created() {
    if (this.$route.query.query) {
      this.query = this.$route.query.query;
      this.search();
    }
    this.$watch(this.$route.query.query, (newQuestion) => {
      console.log("working");
    });
  },
  methods: {
    async search() {
      this.loading = true;
      const response = await axios.get(
        `search?query=${this.query}&rows=${this.filters.rows}&page=${this.filters.page}`
      );

      if (response.status == 200) {
        this.dataList = response.data.data;
        this.meta = response.data.meta;
      }
      this.loading = false;
    },
    async fetchDataPortion(rows, currentPage) {
      this.filters.rows = rows;
      this.filters.page = currentPage;
      this.search();
    },
  },
};
</script>
