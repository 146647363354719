<template>
  <div
    v-if="visible"
    class="fixed z-40 inset-0 overflow-y-auto"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <div
      class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
    >
      <div
        @click.prevent="hide"
        class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        aria-hidden="true"
      ></div>

      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
        >&#8203;</span
      >
      <div
        class="inline-block align-bottom bg-white rounded-3xl text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-5xl sm:w-full"
      >
        <div class="flex flex-col items-center">
          <div class="bg-gray-800 w-full flex justify-between">
            <div class="text-2xl text-gray-800 font-bold py-2 pl-6">
              Delete Account
            </div>
            <div
              @click.prevent="hide"
              class="rounded-full cursor-pointer bg-blue-400 text-gray-800 text-xs my-2 mr-6 w-28 flex justify-center items-center font-bold"
            >
              {{ $t("control.cancel") }}
            </div>
          </div>
          <div class="w-100 h-100 text-red-400 mt-12">
            <svg
              width="100"
              height="100"
              viewBox="0 0 100 100"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.5 25H20.8333H87.5"
                stroke="#EE4545"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M33.3335 24.9999V16.6666C33.3335 14.4564 34.2115 12.3368 35.7743 10.774C37.3371 9.21123 39.4567 8.33325 41.6668 8.33325H58.3335C60.5436 8.33325 62.6633 9.21123 64.2261 10.774C65.7889 12.3368 66.6668 14.4564 66.6668 16.6666V24.9999M79.1668 24.9999V83.3333C79.1668 85.5434 78.2889 87.663 76.7261 89.2258C75.1633 90.7886 73.0436 91.6666 70.8335 91.6666H29.1668C26.9567 91.6666 24.8371 90.7886 23.2743 89.2258C21.7115 87.663 20.8335 85.5434 20.8335 83.3333V24.9999H79.1668Z"
                stroke="#EE4545"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M41.6665 45.8333V70.8332"
                stroke="#EE4545"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M58.3335 45.8333V70.8332"
                stroke="#EE4545"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <div class="w-full bg-red-200 text-center mt-4 p-6">
            <div class="text-red-600">
              {{ $t("messages.delete_confirm") }}
            </div>
            <div class="text-red-600 text-2xl font-bold">
              {{ message }}
            </div>
          </div>
          <div class="w-full flex flex-col my-12 items-center">
            <div
              @click.prevent="confirmDelete"
              class="rounded-full cursor-pointer bg-red-500 text-white px-6 flex justify-center items-center font-bold"
            >
              {{ $t("control.delete") }}
            </div>
            <div
              @click.prevent="hide"
              class="mt-6 rounded-full cursor-pointer bg-blue-400 text-gray-800 px-6 flex justify-center items-center font-bold"
            >
              {{ $t("control.cancel") }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AccountDelete",
  props: ["visible", "message"],
  methods: {
    hide() {
      this.$emit("hide");
    },
    confirmDelete() {
      this.$emit("deleteconfirmed");
    },
  },
};
</script>
