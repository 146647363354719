<template>
  <div class="main-content flex-1 relative bg-gray-100">
    <loading-overlay :visible="loading" />
    <div class="flex justify-center w-full">
      <div
        class="bg-gray-300 rounded-xl w-5/6 flex flex-col items-center pb-12 px-20 m-6"
      >
        <div class="w-full  flex flex-col items-center">
          <div class="grid grid-cols-2 lg:w-1/2 gap-6 mt-6">
            <div class="col-span-2">
              <toggle-button
                :value="mikrotik.auto_reboot"
                :enabled_name="$t('mikrotik.auto_reboot')"
                :disabled_name="$t('mikrotik.auto_reboot_false')"
                @toggle="
                  mikrotik.auto_reboot = !mikrotik.auto_reboot;
                  applied = false;
                "
              />
            </div>
            <div class="col-span-2">
              <toggle-button
                :value="mikrotik.whatsapp_messages"
                :enabled_name="$t('mikrotik.whatsapp_messages_only')"
                :disabled_name="$t('mikrotik.whatsapp_messages_only_false')"
                @toggle="
                  mikrotik.whatsapp_messages = !mikrotik.whatsapp_messages;
                  applied = false;
                "
              />
            </div>
            <div class="col-span-2">
              <toggle-button
                :value="mikrotik.whatsapp_full"
                :enabled_name="$t('mikrotik.whatsapp_full')"
                :disabled_name="$t('mikrotik.whatsapp_full_false')"
                @toggle="
                  mikrotik.whatsapp_full = !mikrotik.whatsapp_full;
                  applied = false;
                "
              />
            </div>
            <div class="col-span-2">
              <toggle-button
                :value="mikrotik.custom_dns"
                :enabled_name="$t('mikrotik.dns')"
                :disabled_name="$t('mikrotik.dns_false')"
                @toggle="
                  mikrotik.custom_dns = !mikrotik.custom_dns;
                  applied = false;
                "
              />
            </div>
            <!-- <div class="col-span-2">
              <toggle-button
                :value="mikrotik.open_speed"
                :enabled_name="$t('mikrotik.open_speed')"
                :disabled_name="$t('mikrotik.open_speed_false')"
                @toggle="
                  mikrotik.open_speed = !mikrotik.open_speed;
                  applied = false;
                "
              />
            </div>
            <div v-if="mikrotik.open_speed">
              <div>{{ $t("control.from") }}</div>
              <Datepicker disabled v-model="start_time" autoApply timePicker />
            </div>
            <div v-if="mikrotik.open_speed">
              <div>{{ $t("control.to") }}</div>
              <Datepicker disabled v-model="stop_time" autoApply timePicker />
            </div> -->
            <button
              @click="if (!applied) store();"
              class="col-span-2 mt-12 bg-green-500 cursor-pointer text-white rounded-full py-1 px-3"
              :class="{
                'cursor-default opacity-50': applied,
                'cursor-pointer': !applied,
              }"
            >
              {{ applied ? $t("settings.applied") : $t("settings.apply") }}
            </button>
            <div v-if="applying" class="col-span-2 flex justify-center">
              <img
                class=" inline-block h-12 w-12"
                src="@/assets/loading.gif"
                alt="Loading"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import NumericalSpinner from "../components/formElements/NumericalSpinner.vue";
import ToggleButton from "../components/formElements/ToggleButton.vue";
import Dropdown from "../components/formElements/Dropdown.vue";
import LoadingOverlay from "../components/modals/LoadingOverlay.vue";
import Datepicker from "vue3-date-time-picker";
export default {
  components: {
    NumericalSpinner,
    ToggleButton,
    Dropdown,
    LoadingOverlay,
    Datepicker,
  },
  name: "Mikrotik",
  async created() {
    this.loading = true;
    const response = await axios.get("mikrotik");
    if (response.status == 200) {
      let temp = response.data.data;
      temp.forEach((element) => {
        switch (element.key) {
          case "mikrotik_auto_reboot":
            this.mikrotik.auto_reboot = element.value;
            break;
          case "mikrotik_custom_dns":
            this.mikrotik.custom_dns = element.value;
            break;
          case "mikrotik_whatsapp_messages":
            this.mikrotik.whatsapp_messages = element.value;
            break;
          case "mikrotik_whatsapp_full":
            this.mikrotik.whatsapp_full = element.value;
            break;
          case "mikrotik_open_speed":
            this.mikrotik.open_speed = element.value;
            break;
        }
      });
    }
    this.loading = false;
  },
  data() {
    return {
      error: "",
      message: "",
      currentImageUrl: null,
      mikrotik: {
        auto_reboot: false,
        custom_dns: false,
        whatsapp_messages: false,
        whatsapp_full: false,
        open_speed: false,
      },
      mikrotik_auto_reboot: false,
      whatsapp_messages_only: false,
      whatsapp_full: false,
      dns: false,
      open_speed: false,
      loading: false,
      applying: false,
      applied: false,
      profiles: null,
      start_time: {
        hours: 2,
        minutes: 0,
      },
      stop_time: {
        hours: 6,
        minutes: 0,
      },
    };
  },
  methods: {
    selectImage() {
      this.setting.network_logo = this.$refs.imageFile.files.item(0);
      this.currentImageUrl = URL.createObjectURL(this.setting.network_logo);
    },
    async store() {
      this.applying = true;
      const response = await axios.post("/mikrotik", this.mikrotik);
      if (response.status == 200) {
        this.applying = false;
        this.applied = true;
        this.$router.push({ query: { success: "success" } });
      }
    },
  },
};
</script>
