<template>
  <div
    class="p-4 text-black flex justify-around rounded-3xl"
    style="background-color: #84C2EA"
  >
    <div
      class="flex flex-col justify-center text-center border-r border-gray-600 w-1/2"
    >
      <div class="text-lg font-bold text-center">
        {{ sysupTime }}
      </div>
      <div class="text-sm font-light text-center">System Up Time</div>
    </div>
    <div class="flex flex-col justify-between text-black w-1/4">
      <div class="text-xl text-gray-600 font-bold text-center">
        {{ this.currentTime.split(":")[0] }}
      </div>
      <div class="text-xl text-gray-600 font-bold text-center">
        {{ this.currentTime.split(":")[1].split(" ")[0] }}
      </div>
      <div class="text-xl text-gray-600 font-bold text-center">
        {{ this.currentTime.split(":")[1].split(" ")[1] }}
      </div>
    </div>
    <div class="flex flex-col justify-center text-gray-600  w-1/4">
      <div class="text-xl font-bold text-center">Time Now</div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "TimePill",
  props: ["sysupTime"],
  data() {
    return {
      currentTime: null,
    };
  },
  created() {
    this.currentTime = moment().format("LT");
    setInterval(() => {
      this.currentTime = moment().format("LT");
    }, 1000);
  },
};
</script>
