<template>
  <div class=" overflow-scroll relative">
    <div
      v-if="accounts.length > 0"
      class="
        flex
        bg-ssr-side_bar-settings
        text-center
        justify-center
        text-white text-xl
        rtl
      "
    >
      <span class="font-sans font-bold">{{
        this.$store.getters.settings.currency
      }}</span>
      {{ $t("control.sum") + ": " + sum }}
    </div>
    <table class="table-auto w-full">
      <thead class="bg-ssr-side_bar-settings text-white font-bold sticky top-0">
        <tr>
          <th>#</th>
          <th>{{ $t("accounts.account") }}</th>
          <th>{{ $t("accounts.username") }}</th>
          <th>{{ $t("transactions.remaining") }}</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(account, index) in accounts"
          :key="account.id"
          class="text-base text-center hover:bg-blue-200 cursor-pointer"
          :class="{
            'bg-gray-200': index % 2 == 0,
          }"
          @click="this.$router.push(`/bills?username_debt=${account.username}`)"
        >
          <td class="font-bold">
            {{ index + 1 + (meta.current_page - 1) * meta.per_page }}
          </td>
          <td>{{ account.first_name + " " + account.last_name }}</td>
          <td>{{ account.username }}</td>
          <td>
            {{ account.debt
            }}<span class="font-sans font-bold">{{
              this.$store.getters.settings.currency
            }}</span>
          </td>
        </tr>
      </tbody>
    </table>
    <div v-if="loading" class="w-full flex justify-center">
      <img
        class=" inline-block h-12 w-12"
        src="@/assets/loading.gif"
        alt="Loading"
      />
    </div>
  </div>
</template>
<script>
import axios from "axios";
import PaginationRow from "../PaginationRow.vue";
export default {
  components: { PaginationRow },
  name: "DebtAccounts",
  data() {
    return {
      accounts: [],
      meta: { last_page: 0, current_page: 1 },
      filters: {
        rows: 5,
        page: 1,
      },
      loading: false,
      len: 30,
      sum: 0,
    };
  },
  async created() {
    await this.fetchAccounts();
  },
  methods: {
    async fetchAccounts() {
      this.loading = true;
      const response = await axios.get(
        `accounts?rows=10000&page=1&in_debt=true`
      );
      this.accounts = response.data.data;
      this.len = 0;
      this.accounts.forEach((account) => {
        this.sum += account.debt;
        this.len += 2;
      });
      this.meta = response.data.meta;
      this.loading = false;
    },
    async fetchDataPortion(rows, currentPage) {
      this.filters.rows = 5;
      this.filters.page = currentPage;
      this.fetchAccounts();
    },
  },
};
</script>
<style scoped>
@keyframes tickerv {
  0% {
    margin-top: 30%;
  }
  50% {
    margin-top: 0%;
  }
  100% {
    margin-top: -30%;
  }
}

.tickerv-wrap ul {
  padding: 0;
  margin: 0;
  /* animation: tickerv 30s infinite; */
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-name: tickerv;
  animation-name: tickerv;
  animation-timing-function: linear;
}

.tickerv-wrap ul:hover {
  animation-play-state: paused;
}
</style>
