<template>
  <div
    @click.prevent="if (this.$refs.menu != null) this.$refs.menu.close();"
    class="main-content flex-1 bg-gray-100"
  >
    <loading-overlay :visible="loading_overlay" />
    <transition name="fade">
      <card-details
        v-if="selectedData"
        :visible="showDetails"
        :selectedCard="selectedData"
        @hide-details="showDetails = false"
        @updated="
          showDetails = false;
          applyFilters();
        "
      />
    </transition>
    <transition name="fade">
      <delete-confirm
        :visible="showDelete"
        @hide="showDelete = false"
        @deleteconfirmed="deleteData"
      />
    </transition>
    <transition name="fade">
      <columns-select
        :visible="showColumnsSelect"
        :columns="columns"
        :name="'cards_columns'"
        @hide="showColumnsSelect = false"
        @selectconfirmed="updateColumns(currentColumns)"
      />
    </transition>
    <transition name="fade">
      <warnning-confirm
        :message="$t('messages.cards.delete_all')"
        :visible="showDeleteAll"
        @confirmed="groupDelete()"
        @hide="showDeleteAll = false"
      />
    </transition>
    <transition name="fade">
      <warnning-confirm
        :message="$t('messages.cards.disable')"
        :visible="showDisable"
        @confirmed="disableData()"
        @hide="showDisable = false"
      />
    </transition>
    <transition name="fade">
      <warnning-confirm
        :message="$t('messages.cards.enable')"
        :visible="showEnable"
        @confirmed="disableData()"
        @hide="showEnable = false"
      />
    </transition>
    <transition name="fade">
      <warnning-confirm
        :message="$t('messages.cards.disconnect')"
        :visible="showDisconnect"
        @confirmed="disconnectData()"
        @hide="showDisconnect = false"
      />
    </transition>
    <transition name="fade">
      <warnning-confirm
        :message="$t('messages.cards.clear')"
        :visible="showClear"
        @confirmed="clearData()"
        @hide="showClear = false"
      />
    </transition>
    <transition name="fade">
      <warnning-confirm
        :message="$t('messages.cards.truncate')"
        :visible="showTruncate"
        @confirmed="cards_truncate()"
        @hide="showTruncate = false"
      />
    </transition>
    <transition name="fade">
      <warnning-message
        :visible="truncateFailed"
        :message="$t('errors.batches.truncate_failed')"
        @hide="truncateFailed = false"
      />
    </transition>
    <transition name="expandy">
      <tables-context :display="showContext" ref="menu">
        <ul
          v-if="selectedData"
          :class="{
            disabled: !this.$store.getters.settings.license.active,
          }"
        >
          <li
            @click="showDetails = true"
            class="m-2 pr-8 border-b border-black hover:bg-gray-200"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6 inline-block bg-yellow-300 rounded-lg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
              />
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
              />
            </svg>
            {{ $t("actions.view") }}
          </li>
          <li
            v-if="
              hasPrivilege('cards_edit_cards') && user.data.type != 'reseller'
            "
            class="m-2 border-b border-black hover:bg-gray-200"
            @click="showClear = true"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6 inline-block bg-green-400 rounded-lg text-white"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
            {{ $t("actions.clear") }}
          </li>
          <li
            @click="
              this.$store.commit('changeKey');
              this.$router.push(`/sessions/${selectedData.username}`);
            "
            class="m-2 pr-8 border-b border-black hover:bg-gray-200"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6 inline-block bg-green-600 text-white rounded-lg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
              />
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
              />
            </svg>
            {{ $t("actions.view_sessions") }}
          </li>
          <li
            v-if="hasPrivilege('cards_disconnect_cards')"
            class="m-2 border-b border-black hover:bg-gray-200"
            @click="showDisconnect = true"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6 inline-block bg-red-400 text-white rounded-lg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
            {{ $t("actions.disconnect") }}
          </li>
          <li
            v-if="hasPrivilege('cards_disable_cards') && !selectedData.enabled"
            class="m-2 border-b border-black hover:bg-gray-200"
            @click="enableData()"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6 inline-block bg-green-500 text-green-500 rounded-lg"
              viewBox="0 0 24 24"
              fill="currentColor"
            >
              <path d="M0 0h24v24H0V0z" fill="none" />
              <path
                d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM4 12c0-4.42 3.58-8 8-8 1.85 0 3.55.63 4.9 1.69L5.69 16.9C4.63 15.55 4 13.85 4 12zm8 8c-1.85 0-3.55-.63-4.9-1.69L18.31 7.1C19.37 8.45 20 10.15 20 12c0 4.42-3.58 8-8 8z"
              />
            </svg>
            {{ $t("actions.enable") }}
          </li>
          <li
            v-if="hasPrivilege('cards_disable_cards') && selectedData.enabled"
            class="m-2 border-b border-black hover:bg-gray-200"
            @click="showDisable = true"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6 inline-block bg-gray-400 text-white rounded-lg"
              viewBox="0 0 24 24"
              fill="#FFFFFF"
            >
              <path d="M0 0h24v24H0V0z" fill="none" />
              <path
                d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM4 12c0-4.42 3.58-8 8-8 1.85 0 3.55.63 4.9 1.69L5.69 16.9C4.63 15.55 4 13.85 4 12zm8 8c-1.85 0-3.55-.63-4.9-1.69L18.31 7.1C19.37 8.45 20 10.15 20 12c0 4.42-3.58 8-8 8z"
              />
            </svg>
            {{ $t("actions.disable") }}
          </li>
          <li
            v-if="hasPrivilege('cards_delete_cards')"
            @click="showDelete = true"
            class="m-2 border-b border-black hover:bg-gray-200"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6 inline-block bg-red-500 text-white rounded-lg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
              />
            </svg>
            {{ $t("actions.delete") }}
          </li>
        </ul>
      </tables-context>
    </transition>

    <div class="p-2 md:p-6" @click.prevent="showFilters = false">
      <div class="flex justify-between">
        <div>
          <button
            class="text-xs lg:text-base rounded-full text-black lg:py-1 px-4 mr-4"
            :class="{
              'bg-blue-300 text-black shadow-blue ': autoUpdate,
              'bg-gray-700 text-white': !autoUpdate,
            }"
            @click.prevent="toggleAutoUpdate"
          >
            {{
              autoUpdate
                ? "✓ " + $t("accounts.auto_update")
                : $t("accounts.auto_update")
            }}
          </button>
          <button
            class="text-xs lg:text-base rounded-full text-black lg:py-1 px-4 mr-4"
            :class="{
              'bg-blue-300 text-black shadow-blue ': filters.save,
              'bg-gray-700 text-white': !filters.save,
            }"
            @click.prevent="toggleSaveFilters"
          >
            {{
              autoUpdate
                ? "✓ " + $t("control.save_filters")
                : $t("control.save_filters")
            }}
          </button>
        </div>
        <div class="flex">
          <button
            class="rounded-full bg-ssr-side_bar-settings text-xs lg:text-base text-white py-1 lg:py-2 px-4 font-bold mr-4"
            @click.prevent="showColumnsSelect = true"
          >
            ...
          </button>
          <button
            v-if="this.$route.params.id"
            class="rounded-full bg-ssr-side_bar-button_hover text-xs lg:text-base text-white px-4 mr-4"
            @click.prevent="exportCsv(this.$route.params.id)"
            :class="{
              disabled: !this.$store.getters.settings.license.active,
            }"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6 inline-block rounded-lg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
              />
            </svg>
            {{ $t("actions.export_csv") }}
          </button>
          <button
            v-if="this.$route.params.id"
            class="rounded-full bg-ssr-side_bar-button_hover text-xs lg:text-base text-white px-4 mr-4"
            @click.prevent="
              this.$router.push(`/cards/${this.$route.params.id}/print`);
              this.$store.commit('changeKey');
            "
            :class="{
              disabled: !this.$store.getters.settings.license.active,
            }"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6 inline-block rounded-lg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z"
              />
            </svg>
            {{ $t("actions.print") }}
          </button>
          <button
            v-if="!this.$route.params.id"
            class="rounded-full bg-ssr-side_bar-button_hover text-xs lg:text-base text-white px-4 mr-4"
            @click.prevent="showTruncate = true"
            :class="{
              disabled: !this.$store.getters.settings.license.active,
            }"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6 inline-block rounded-lg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
              />
            </svg>
            {{ $t("batches.truncate") }}
          </button>
          <!-- <button
            @click.prevent="this.$router.push('/cards/create')"
            class="rounded-md bg-blue-300 text-xs lg:text-base text-black lg:p-2 px-2 "
          >
            Add Cards
          </button> -->
        </div>
      </div>
      <div
        class="w-full flex flex-col text-center overflow-auto"
        style="height: 30rem"
      >
        <table>
          <thead class="border-b-2 border-black text-xs md:text-base">
            <th></th>
            <th>
              <table-check-box
                class="w-5 h-5 my-3 mx-4 cursor-pointer"
                :value="allSelected"
                @click="checkAll"
              />
            </th>
            <th v-if="columns.username.enabled">
              <div class="inline-block">{{ $t("cards.username") }}</div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6 inline-block transform transition-all cursor-pointer"
                :class="{ 'rotate-180': columns.username.sort }"
                @click="sort('username')"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 15l7-7 7 7"
                />
              </svg>
              <input
                v-if="hasPrivilege('cards_view_search_cards')"
                type="text"
                class="bg-white rounded-full w-full shadow-lg p-1 m-1 focus:outline-none text-center"
                :placeholder="$t('control.filter')"
                @input="applyFilters"
                v-model="filters.username"
              />
            </th>
            <th v-if="columns.password.enabled">
              <div class="inline-block">{{ $t("cards.password") }}</div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6 inline-block transform transition-all cursor-pointer"
                :class="{ 'rotate-180': columns.password.sort }"
                @click="sort('password')"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 15l7-7 7 7"
                />
              </svg>
              <input
                v-if="hasPrivilege('cards_view_search_cards')"
                type="text"
                class="bg-white rounded-full w-full shadow-lg p-1 m-1 focus:outline-none text-center"
                :placeholder="$t('control.filter')"
                @input="applyFilters"
                v-model="filters.password"
              />
            </th>
            <th v-if="columns.used.enabled">
              <div class="inline-block">{{ $t("cards.used") }}</div>
              <select
                v-if="hasPrivilege('cards_view_search_cards')"
                @change="applyFilters"
                v-model="filters.used"
                class="bg-white rounded-full w-full shadow-lg p-1 pl-3 focus:outline-none text-center"
              >
                <option
                  v-for="ft in [
                    { id: '', name: $t('control.filter') },
                    { id: '1', name: $t('control.yes') },
                    { id: '0', name: $t('control.no') },
                  ]"
                  v-bind:key="ft.id"
                  :value="ft.id"
                >
                  {{ ft.name }}
                </option>
              </select>
            </th>
            <th v-if="columns.profile.enabled">
              <div>{{ $t("cards.profile") }}</div>
            </th>
            <th v-if="columns.batch.enabled">
              <div>{{ $t("cards.batch") }}</div>
            </th>
            <th v-if="columns.expires_at.enabled">
              <div class="inline-block">{{ $t("cards.expires_at") }}</div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6 inline-block transform transition-all cursor-pointer"
                :class="{ 'rotate-180': columns.expires_at.sort }"
                @click="sort('expires_at')"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 15l7-7 7 7"
                />
              </svg>
              <input
                v-if="hasPrivilege('cards_view_search_cards')"
                type="text"
                class="bg-white rounded-full w-full shadow-lg p-1 m-1 focus:outline-none text-center"
                :placeholder="'EX: 2022-01-01'"
                @input="applyFilters"
                v-model="filters.expires_at"
              />
            </th>
            <th v-if="columns.remaining_quota_mb.enabled">
              <div class="inline-block">{{ $t("cards.used_quota") }}</div>
            </th>
            <th v-if="columns.active.enabled">
              <div class="inline-block">{{ $t("cards.status") }}</div>
              <select
                v-if="hasPrivilege('cards_view_search_cards')"
                @change="applyFilters"
                v-model="filters.active"
                class="bg-white rounded-full w-full shadow-lg p-1 pl-3 focus:outline-none text-center"
              >
                <option
                  v-for="ft in [
                    { id: '', name: $t('control.all') },
                    { id: 'active', name: $t('control.active') },
                    { id: 'expired', name: $t('control.expired') },
                    { id: 'expiring', name: $t('control.expiring') },
                    { id: 'disabled', name: $t('control.disabled') },
                  ]"
                  v-bind:key="ft.id"
                  :value="ft.id"
                >
                  {{ ft.name }}
                </option>
              </select>
            </th>
            <th v-if="columns.online.enabled">
              <div class="inline-block">{{ $t("cards.connection") }}</div>
              <select
                v-if="hasPrivilege('cards_view_search_cards')"
                @change="applyFilters"
                v-model="filters.online"
                class="bg-white rounded-full w-full shadow-lg p-1 pl-3 focus:outline-none text-center"
              >
                <option
                  v-for="ft in [
                    { id: '', name: $t('control.all') },
                    { id: 'online', name: $t('control.online') },
                    { id: 'offline', name: $t('control.offline') },
                  ]"
                  v-bind:key="ft.id"
                  :value="ft.id"
                >
                  {{ ft.name }}
                </option>
              </select>
            </th>
          </thead>
          <tbody>
            <tr
              v-for="(card, index) in dataList"
              v-bind:key="card.id"
              class="text-xs md:text-base"
              :class="{
                'bg-gray-200': index % 2 == 0,
                'bg-blue-300': card.selected,
              }"
              @contextmenu.prevent="openContextMenu($event, card)"
            >
              <td class="font-bold">
                {{ index + 1 + (meta.current_page - 1) * meta.per_page }}
              </td>
              <td>
                <table-check-box
                  class="w-5 h-5 my-3 mx-4 cursor-pointer"
                  :value="card.selected"
                  @click="selectData(card)"
                />
              </td>
              <td
                v-if="columns.username.enabled"
                :class="{
                  disabled: !this.$store.getters.settings.license.active,
                }"
              >
                <a
                  class="cursor-pointer underline text-blue-800"
                  @click.prevent="
                    selectedData = card;
                    showDetails = true;
                  "
                >
                  {{ card.username }}
                </a>
              </td>

              <td v-if="columns.password.enabled">
                <div v-if="hasPrivilege('cards_view_cards_passwords')">
                  {{ card.password }}
                </div>
              </td>
              <td v-if="columns.used.enabled">
                <div v-if="hasPrivilege('cards_view_cards_status')">
                  <div
                    v-if="!card.used"
                    class="inline-block px-4 p-1 bg-green-600 text-green-200 text-center text-xs rounded-full"
                  >
                    {{ $t("control.no") }}
                  </div>
                  <div
                    v-if="card.used"
                    class="inline-block px-4 p-1 bg-red-600 text-red-200 text-center text-xs rounded-full"
                  >
                    {{ $t("control.yes") }}
                  </div>
                </div>
              </td>
              <td v-if="columns.profile.enabled">
                {{ card.profile.name }}
              </td>
              <td v-if="columns.batch.enabled">
                {{ card.batch.name }}
              </td>
              <td v-if="columns.expires_at.enabled">
                <div v-if="card.expires_at">
                  {{ formatDate(card.expires_at) }}
                </div>
                <div v-if="!card.expires_at"></div>
              </td>

              <td v-if="columns.remaining_quota_mb.enabled">
                <div v-if="hasPrivilege('cards_view_cards_quota')">
                  <div
                    v-if="card.profile.traffic_limited"
                    class="flex justify-center relative"
                  >
                    <div
                      class="w-12 h-12 relative flex justify-center items-center"
                    >
                      <pie-chart
                        :color="percentColor(card)"
                        :value="
                          parseFloat(
                            (card.used_quota_mb * 100) / card.allowed_quota_mb
                          ).toFixed(0)
                        "
                      />
                      <div class="absolute font-bold">
                        {{
                          parseFloat(
                            (card.used_quota_mb * 100) / card.allowed_quota_mb
                          ).toFixed(0) + "%"
                        }}
                      </div>
                    </div>
                  </div>
                  <div v-if="!card.profile.traffic_limited">
                    {{ $t("control.unlimited") }}
                  </div>
                </div>
              </td>
              <td v-if="columns.active.enabled">
                <div
                  v-if="hasPrivilege('cards_view_cards_status')"
                  :class="{
                    disabled: !this.$store.getters.settings.license.active,
                  }"
                >
                  <div
                    v-if="card.active && card.enabled"
                    @click="
                      selectedData = card;
                      showDisable = true;
                    "
                    class="p-1 bg-blue-600 text-white text-center text-xs rounded-full px-3 inline-block cursor-pointer"
                  >
                    {{ $t("control.active") }}
                  </div>
                  <div
                    v-if="!card.active && card.enabled"
                    class="p-1 bg-yellow-600 text-white text-center text-xs rounded-full px-3 inline-block"
                  >
                    {{ $t("control.expired") }}
                  </div>
                  <div
                    v-if="!card.enabled"
                    @click="
                      selectedData = card;
                      showEnable = true;
                    "
                    class="p-1 bg-red-600 text-white text-center text-xs rounded-full px-3 inline-block cursor-pointer"
                  >
                    {{ $t("control.disabled") }}
                  </div>
                </div>
              </td>
              <td v-if="columns.online.enabled">
                <div v-if="hasPrivilege('cards_view_cards_status')">
                  <div
                    v-if="card.online"
                    class="p-1 bg-green-600 text-white text-center text-xs rounded-full px-3 inline-block"
                  >
                    {{ $t("control.online") }}
                  </div>
                  <div
                    v-if="!card.online"
                    class="p-1 bg-gray-800 text-white text-center text-xs rounded-full px-3 inline-block"
                  >
                    {{ $t("control.offline") }}
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-if="loading" class="w-full flex justify-center">
        <img
          class="inline-block h-12 w-12"
          src="@/assets/loading.gif"
          alt="Loading"
        />
      </div>
      <div
        class="flex justify-start"
        v-if="selection_array.length == 1"
        :class="{
          disabled: !this.$store.getters.settings.license.active,
        }"
      >
        <div
          @click="
            selectedData = selection_array[0];
            showDetails = true;
          "
          class="ml-2 cursor-pointer group relative"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6 inline-block bg-yellow-300 rounded-lg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
            />
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
            />
          </svg>
          <div
            class="scale-0 transform transition-all ease-linear group-hover:scale-100 absolute"
          >
            {{ $t("actions.view") }}
          </div>
        </div>
        <div
          v-if="
            hasPrivilege('cards_edit_cards') && user.data.type != 'reseller'
          "
          class="cursor-pointer group relative ml-3"
          @click="
            selectedData = selection_array[0];
            showClear = true;
          "
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6 inline-block bg-green-400 rounded-lg text-white"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          <div
            class="scale-0 transform transition-all ease-linear group-hover:scale-100 absolute"
          >
            {{ $t("actions.clear") }}
          </div>
        </div>
        <div
          @click="
            selectedData = selection_array[0];
            this.$store.commit('changeKey');
            this.$router.push(`/sessions/${selectedData.username}`);
          "
          class="ml-3 cursor-pointer group relative"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6 inline-block bg-green-600 text-white rounded-lg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
            />
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
            />
          </svg>
          <div
            class="scale-0 transform transition-all ease-linear group-hover:scale-100 absolute"
          >
            {{ $t("actions.view_sessions") }}
          </div>
        </div>
        <div
          v-if="hasPrivilege('cards_disconnect_cards')"
          class="cursor-pointer group relative ml-3"
          @click="
            selectedData = selection_array[0];
            showDisconnect = true;
          "
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6 inline-block bg-red-400 text-white rounded-lg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          <div
            class="scale-0 transform transition-all ease-linear group-hover:scale-100 absolute"
          >
            {{ $t("actions.disconnect") }}
          </div>
        </div>
        <div
          v-if="
            hasPrivilege('cards_disable_cards') && selection_array[0].enabled
          "
          class="cursor-pointer group relative mx-3"
          @click="
            selectedData = selection_array[0];
            showDisable = true;
          "
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6 inline-block bg-gray-400 text-white rounded-lg"
            viewBox="0 0 24 24"
            fill="#FFFFFF"
          >
            <path d="M0 0h24v24H0V0z" fill="none" />
            <path
              d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM4 12c0-4.42 3.58-8 8-8 1.85 0 3.55.63 4.9 1.69L5.69 16.9C4.63 15.55 4 13.85 4 12zm8 8c-1.85 0-3.55-.63-4.9-1.69L18.31 7.1C19.37 8.45 20 10.15 20 12c0 4.42-3.58 8-8 8z"
            />
          </svg>
          <div
            class="scale-0 transform transition-all ease-linear group-hover:scale-100 absolute"
          >
            {{ $t("actions.disable") }}
          </div>
        </div>
        <div
          v-if="
            hasPrivilege('cards_disable_cards') && !selection_array[0].enabled
          "
          class="cursor-pointer group relative mx-3"
          @click="
            selectedData = selection_array[0];
            enableData();
          "
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6 inline-block bg-green-500 text-green-500 rounded-lg"
            viewBox="0 0 24 24"
            fill="currentColor"
          >
            <path d="M0 0h24v24H0V0z" fill="none" />
            <path
              d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM4 12c0-4.42 3.58-8 8-8 1.85 0 3.55.63 4.9 1.69L5.69 16.9C4.63 15.55 4 13.85 4 12zm8 8c-1.85 0-3.55-.63-4.9-1.69L18.31 7.1C19.37 8.45 20 10.15 20 12c0 4.42-3.58 8-8 8z"
            />
          </svg>
          <div
            class="scale-0 transform transition-all ease-linear group-hover:scale-100 absolute"
          >
            {{ $t("actions.enable") }}
          </div>
        </div>
        <div
          v-if="hasPrivilege('cards_delete_cards')"
          @click="
            selectedData = selection_array[0];
            showDelete = true;
          "
          class="group cursor-pointer relative"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6 inline-block bg-red-500 text-white rounded-lg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
            />
          </svg>
          <div
            class="scale-0 transform transition-all ease-linear group-hover:scale-100 absolute"
          >
            {{ $t("actions.delete") }}
          </div>
        </div>
      </div>
      <div
        class="flex justify-start"
        v-if="selection_array.length > 1"
        :class="{
          disabled: !this.$store.getters.settings.license.active,
        }"
      >
        <div
          v-if="hasPrivilege('cards_delete_cards')"
          @click="showDeleteAll = true"
          class="group cursor-pointer relative"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6 inline-block bg-red-500 text-white rounded-lg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
            />
          </svg>
          <div
            class="scale-0 transform transition-all ease-linear group-hover:scale-100 absolute"
          >
            {{ $t("actions.delete") }}
          </div>
        </div>
      </div>
      <pagination-row
        class="mt-2"
        @triggerupdate="fetchDataPortion"
        :meta="meta"
        :row="filters.rows"
      />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import PillDefault from "../components/PillDefault.vue";
import DeleteConfirm from "../components/modals/DeleteConfirm.vue";
import PaginationRow from "../components/PaginationRow.vue";
import TablesContext from "../components/contexts/TablesContext.vue";
import ColumnsSelect from "../components/modals/ColumnsSelect.vue";
import ProfileDetails from "../components/modals/ProfileDetails.vue";
import TableCheckBox from "../components/formElements/TableCheckBox.vue";
import PieChart from "../components/PieChart.vue";
import WarnningConfirm from "../components/modals/WarnningConfirm.vue";
import CardDetails from "../components/modals/CardDetails.vue";
import { mapGetters } from "vuex";
import { hasPrivilege } from "../global_constants.js";
import LoadingOverlay from "../components/modals/LoadingOverlay.vue";
import WarnningMessage from "../components/modals/WarnningMessage.vue";
export default {
  components: {
    PillDefault,
    DeleteConfirm,
    PaginationRow,
    TablesContext,
    ColumnsSelect,
    TableCheckBox,
    PieChart,
    WarnningConfirm,
    CardDetails,
    LoadingOverlay,
    WarnningMessage,
  },
  name: "Cards",
  computed: {
    ...mapGetters(["user"]),
  },
  data() {
    return {
      dataList: null,
      meta: { last_page: 0 },
      selectedData: null,
      showDetails: false,
      showDelete: false,
      showDisconnect: false,
      showDisable: false,
      showEnable: false,
      showClear: false,
      showDeleteAll: false,
      showContext: false,
      loading: true,
      loading_overlay: false,
      showFilters: false,
      fullList: false,
      filters: {
        rows: 50,
        page: "",
        save: false,
        username: "",
        password: "",
        used: "",
        expires_at: "",
        profile: "",
        batch: "",
        remaining_quota_mb: "",
        active: "",
        online: "",
      },
      checkedBox: true,
      allSelected: false,
      showColumnsSelect: false,
      columns: {
        username: {
          name: this.$t("cards.username"),
          enabled: true,
          sort: false,
        },
        password: {
          name: this.$t("cards.password"),
          enabled: false,
          sort: false,
        },
        used: { name: this.$t("cards.used"), enabled: true, sort: false },
        expires_at: {
          name: this.$t("cards.expires_at"),
          enabled: false,
          sort: false,
        },
        profile: {
          name: this.$t("cards.profile"),
          enabled: false,
          sort: false,
        },
        batch: { name: this.$t("cards.batch"), enabled: false, sort: false },
        remaining_quota_mb: {
          name: this.$t("cards.used_quota"),
          enabled: true,
          sort: false,
        },
        active: { name: this.$t("cards.status"), enabled: true, sort: false },
        online: {
          name: this.$t("cards.connection"),
          enabled: true,
          sort: false,
        },
      },
      autoUpdate: false,
      selection_array: [],
      debounce: null,
      showTruncate: false,
      truncateFailed: false,
      debounce_counter: 0,
    };
  },
  async created() {
    this.prepareFilters();
    this.prepareColumns();
    if (this.$route.query.type) {
      switch (this.$route.query.type) {
        case "online":
          this.filters.online = "online";
          break;
        case "expired":
          this.filters.active = "expired";
          break;
        case "disabled":
          this.filters.active = "disabled";
          break;
        case "expiring":
          this.filters.active = "expiring";
          break;
      }
    }
    if (this.$route.query.useranme) {
      this.filters.username = this.$route.query.useranme;
      this.columns.enabled = true;
    }
    this.applyFilters();
  },
  methods: {
    hasPrivilege(privilege) {
      return hasPrivilege(this.user, privilege);
    },
    async fetchData() {
      if (this.filters.rows > 0 || this.filters.page > 0) {
        this.fetchDataPortion(this.filters.rows, this.filters.page);
        this.selection_array = [];
        return;
      }
      if (this.$route.params.id != null)
        var response = await axios.get(`cards/${this.$route.params.id}/cards`);
      else var response = await axios.get(`cards/cards`);
      this.dataList = response.data.data;
      this.meta = response.data.meta;
      this.loading = false;
    },
    prepareFilters() {
      var filters = localStorage.getItem("cards_filters");
      if (filters != null) {
        filters = JSON.parse(filters);
        if (filters.save) this.filters = filters;
        else this.filters.rows = filters.rows;
      }
    },
    prepareColumns() {
      var cols = localStorage.getItem("cards_columns");
      if (cols != null) {
        cols = JSON.parse(cols);
        this.columns = {
          username: {
            name: this.$t("cards.username"),
            enabled: cols[0].enabled,
            sort: false,
          },
          password: {
            name: this.$t("cards.password"),
            enabled: cols[1].enabled,
            sort: false,
          },
          used: {
            name: this.$t("cards.used"),
            enabled: cols[2].enabled,
            sort: false,
          },
          expires_at: {
            name: this.$t("cards.expires_at"),
            enabled: cols[3].enabled,
            sort: false,
          },
          profile: {
            name: this.$t("cards.profile"),
            enabled: cols[4].enabled,
            sort: false,
          },
          batch: {
            name: this.$t("cards.batch"),
            enabled: cols[5].enabled,
            sort: false,
          },
          remaining_quota_mb: {
            name: this.$t("cards.used_quota"),
            enabled: cols[6].enabled,
            sort: false,
          },
          active: {
            name: this.$t("cards.status"),
            enabled: cols[7].enabled,
            sort: false,
          },
          online: {
            name: this.$t("cards.connection"),
            enabled: cols[8].enabled,
            sort: false,
          },
        };
      }
    },
    async applyFilters() {
      this.loading = true;
      if (this.$route.params.id != null)
        var requestString = `cards/${this.$route.params.id}/cards?filter=1`;
      else var requestString = `cards/cards?filter=1`;
      if (this.filters.page != "")
        requestString += `&page=${this.filters.page}`;
      if (this.filters.rows != "")
        requestString += `&rows=${this.filters.rows}`;
      if (this.filters.username != "")
        requestString += `&username=${this.filters.username}`;
      if (this.filters.password != "")
        requestString += `&password=${this.filters.password}`;
      if (this.filters.expires_at != "")
        requestString += `&expires_at=${this.filters.expires_at}`;
      if (this.filters.opened != "")
        requestString += `&opened=${this.filters.used}`;
      if (this.filters.active != "")
        requestString += `&active=${this.filters.active}`;
      if (this.filters.online != "")
        requestString += `&online=${this.filters.online}`;
      if (this.sort_col)
        requestString += `&order_by=${this.sort_col}&order_dir=${this.sort_dir}`;
      if (this.debounce != null) {
        this.debounce_counter++;
      } else {
        this.debounce_counter--;
      }
      var local_debounce_location = this.debounce_counter;
      clearTimeout(this.debounce);
      this.debounce = setTimeout(async () => {
        const response = await axios.get(requestString);
        if (this.debounce_counter == local_debounce_location) {
          this.dataList = Object.values(response.data.data);
          if (this.filters.save)
            localStorage.setItem("cards_filters", JSON.stringify(this.filters));
          if (this.dataList.length < 1 && this.filters.page != 1) {
            this.filters.page = 1;
            this.applyFilters();
          }
          if (this.sort_col) {
            if (this.sort_dir == "ASC") {
              this.dataList.sort((a, b) =>
                a[this.sort_col] > b[this.sort_col]
                  ? 1
                  : b[this.sort_col] > a[this.sort_col]
                  ? -1
                  : 0
              );
            } else {
              this.dataList.sort((a, b) =>
                a[this.sort_col] < b[this.sort_col]
                  ? 1
                  : b[this.sort_col] < a[this.sort_col]
                  ? -1
                  : 0
              );
            }
          }
          clearTimeout(this.debounce);
          this.meta = response.data.meta;
          this.loading = false;
        }
      }, 1000);
    },
    async fetchDataPortion(rows, currentPage) {
      this.filters.rows = rows;
      this.filters.page = currentPage;
      this.applyFilters();
    },
    editData(selectedData) {
      this.$router.push(
        `/cards/${this.$route.params.id}/cards/${selectedData.id}/edit`
      );
    },
    async deleteData() {
      this.showDelete = false;
      this.loading_overlay = true;
      const response = await axios.delete(
        `/cards/${this.selectedData.id}/card`
      );
      if (response.status == 200) {
        this.applyFilters();
        this.selection_array = [];
        this.$router.push({ query: { success: "success" } });
      }
      this.loading_overlay = false;
    },
    async cards_truncate() {
      this.showTruncate = false;
      this.loading = true;
      try {
        const response = await axios.post("cards/truncate");
        if (response.status == 200) {
          this.loading = false;
          this.applyFilters();
          this.$router.push({ query: { success: "success" } });
        }
      } catch (error) {
        this.loading = false;
        this.truncateFailed = true;
      }
    },
    async groupDelete() {
      this.showDeleteAll = false;
      this.loading_overlay = true;
      const response = await axios.post(`cards/group`, {
        cards: this.selection_array,
      });
      if (response.status == 200) {
        this.applyFilters();
        this.selection_array = [];
        this.$router.push({ query: { success: "success" } });
      }
      this.loading_overlay = false;
    },
    async disableData() {
      const response = await axios.put(`cards/${this.selectedData.id}/disable`);
      if (response.status == 200) {
        this.applyFilters();
        this.showDisable = false;
        this.showEnable = false;
      }
    },
    async enableData() {
      const response = await axios.put(`cards/${this.selectedData.id}/disable`);
      if (response.status == 200) {
        this.applyFilters();
        this.showDisable = false;
        this.showEnable = false;
      }
    },
    async disconnectData() {
      const response = await axios.put(
        `cards/disconnect/${this.selectedData.id}`
      );
      if (response.status == 200) {
        this.applyFilters();
        this.showDelete = false;
        this.$router.push({ query: { success: "success" } });
      }
    },
    async clearData() {
      const response = await axios.put(`cards/clear/${this.selectedData.id}`);
      if (response.status == 200) {
        this.applyFilters();
        this.showDelete = false;
        this.$router.push({ query: { success: "success" } });
      }
    },
    formatDate(date) {
      return moment(date).format("DD-MM-YYYY");
    },
    openContextMenu(e, card) {
      this.selectedData = card;
      this.$refs.menu.open(e);
    },
    checkAll() {
      if (this.allSelected) {
        this.selection_array = [];
        this.dataList.forEach((element) => {
          element.selected = false;
        });
        this.allSelected = false;
      } else {
        this.selection_array = [];
        this.dataList.forEach((element) => {
          element.selected = true;
          this.selection_array.push(element);
        });
        this.allSelected = true;
      }
    },
    selectData(data) {
      if (data.selected == false) {
        data.selected = true;
        this.selection_array.push(data);
      } else {
        data.selected = false;
        this.selection_array.splice(this.selection_array.indexOf(data), 1);
      }
    },
    updateColumns(updated) {
      this.columns = updated;
      this.showColumnsSelect = false;
    },
    async toggleAutoUpdate() {
      this.autoUpdate = !this.autoUpdate;
      while (this.autoUpdate) {
        this.applyFilters();
        await new Promise((r) => setTimeout(r, 30000));
      }
    },
    percentColor(card) {
      var percent = parseFloat(
        (card.used_quota_mb * 100) / card.allowed_quota_mb
      ).toFixed(0);
      if (percent > 0 && percent <= 49) return "#009BFF";
      else if (percent > 49 && percent <= 74) return "#fcba35";
      else if (percent > 74 && percent < 100) return "#ff2626";
      else return "#000000";
    },
    sort(param) {
      this.sort_col = param;
      if (!this.columns[param].sort) {
        this.sort_dir = "ASC";
        Object.entries(this.columns).forEach(([key, val]) => {
          val.sort = false;
        });
        this.columns[param].sort = true;
      } else {
        this.sort_dir = "DESC";
        Object.entries(this.columns).forEach(([key, val]) => {
          val.sort = false;
        });
        this.columns[param].sort = false;
      }
      this.applyFilters();
    },
    exportCsv(id) {
      window.location.href =
        localStorage.getItem("server_api") + `export/${id}`;
    },
    toggleSaveFilters() {
      this.filters.save = !this.filters.save;
      localStorage.setItem("cards_filters", JSON.stringify(this.filters));
    },
  },
};
</script>
