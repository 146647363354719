<template>
  <svg viewBox="0 0 74 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3 18.75H71"
      stroke="black"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M64.8182 0H9.18182C5.76769 0 3 2.79822 3 6.25V43.75C3 47.2018 5.76769 50 9.18182 50H64.8182C68.2323 50 71 47.2018 71 43.75V6.25C71 2.79822 68.2323 0 64.8182 0Z"
      fill="currentColor"
    />
    <path
      d="M3 18.75H71"
      stroke="white"
      stroke-width="5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "WarningIcon",
};
</script>
