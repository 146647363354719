<template>
  <div>
    <svg
      class=" absolute left-0 top-0"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 32 32"
    >
      <g>
        <circle viewBox="0 0 32 32" class="st0" cx="16" cy="16" r="16" />
        <path
          class="st1"
          viewBox="0 0 32 32"
          d="M200,37c43.54,0,84.47,16.95,115.26,47.74S363,156.46,363,200s-16.95,84.47-47.74,115.26S243.54,363,200,363
		s-84.47-16.95-115.26-47.74S37,243.54,37,200s16.95-84.47,47.74-115.26S156.46,37,200,37 M200,25c-96.65,0-175,78.35-175,175
		s78.35,175,175,175s175-78.35,175-175S296.65,25,200,25L200,25z"
        />
      </g>
      <g>
        <circle viewBox="0 0 32 32" class="st0" cx="16" cy="16" r="16" />
        <path
          class="st2"
          viewBox="0 0 32 32"
          d="M200,37c43.54,0,84.47,16.95,115.26,47.74S363,156.46,363,200s-16.95,84.47-47.74,115.26S243.54,363,200,363
		s-84.47-16.95-115.26-47.74S37,243.54,37,200s16.95-84.47,47.74-115.26S156.46,37,200,37 M200,25c-96.65,0-175,78.35-175,175
		s78.35,175,175,175s175-78.35,175-175S296.65,25,200,25L200,25z"
        />
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: "RingGauage",
};
</script>
<style scoped>
.st0 {
  fill: #ffffff;
}
.st1 {
  fill: #f3f3f3;
}
.st2 {
  fill: #636c9e;
}
</style>
