<template>
  <div class="main-content flex-1 relative bg-gray-100">
    <loading-overlay :visible="loading" />
    <div class="flex justify-center w-full">
      <div class="bg-gray-300 rounded-xl w-full m-6 flex flex-col pb-12 px-20">
        <div class="flex flex-col justify-center items-center">
          <div class="grid grid-cols-2 gap-6 mt-6 w-full lg:w-1/2">
            <div class="flex flex-col items-center col-span-2">
              <div
                class="bg-green-500 text-white rounded-full py-1 px-3 w-full lg:w-1/2 text-center text-xl cursor-pointer"
                @click="checkLicense()"
              >
                {{ $t("license.update") }}
              </div>
              <div v-if="license_error" class="text-sm text-red-600">
                {{ license_error }}
              </div>
              <div v-if="license_error" class="text-sm text-green-600">
                {{ license_error }}
              </div>
            </div>

            <div class="flex justify-center border-b border-black col-span-2">
              {{ $t("license.device") }}
            </div>
            <div class="flex justify-center col-span-2">
              <div class="w-full lg:w-1/2">
                <div class="font-bold text-center">{{ $t("license.id") }}</div>
                <div
                  type="text"
                  class="bg-white rounded-full shadow-lg p-1 pl-3 mt-2 focus:outline-none w-full text-center"
                >
                  {{ this.license.id }}
                </div>
              </div>
            </div>
            <div class="flex justify-center col-span-2">
              <div class="w-full lg:w-1/2">
                <div class="font-bold text-center">{{ $t("license.ip") }}</div>
                <div
                  type="text"
                  class="bg-white rounded-full shadow-lg p-1 pl-3 mt-2 focus:outline-none w-full text-center"
                >
                  {{ this.license.ip }}
                </div>
              </div>
            </div>

            <div class="flex justify-center border-b border-black col-span-2">
              {{ $t("license.subscription") }}
            </div>
            <div class="flex justify-center col-span-2">
              <div class="w-full lg:w-1/2">
                <div class="font-bold text-center">
                  {{ $t("license.realm") }}
                </div>
                <div
                  type="text"
                  class="bg-white rounded-full shadow-lg p-1 pl-3 mt-2 focus:outline-none w-full text-center"
                >
                  {{ this.license.realm }}
                </div>
              </div>
            </div>
            <div class="flex justify-center col-span-2">
              <div class="w-full lg:w-1/2">
                <div class="font-bold text-center">
                  {{ $t("license.full_name") }}
                </div>
                <div
                  type="text"
                  class="bg-white rounded-full shadow-lg p-1 pl-3 mt-2 focus:outline-none w-full text-center"
                >
                  {{ this.license.full_name }}
                </div>
              </div>
            </div>
            <div class="flex justify-center col-span-2">
              <div class="w-full lg:w-1/2">
                <div class="font-bold text-center">
                  {{ $t("license.name") }}
                </div>
                <div
                  type="text"
                  class="bg-white rounded-full shadow-lg p-1 pl-3 mt-2 focus:outline-none w-full text-center"
                >
                  {{ this.license.name }}
                </div>
              </div>
            </div>
            <div class="flex justify-center col-span-2">
              <div class="w-full lg:w-1/2">
                <div class="font-bold text-center">
                  {{ $t("license.network") }}
                </div>
                <div
                  type="text"
                  class="bg-white rounded-full shadow-lg p-1 pl-3 mt-2 focus:outline-none w-full text-center"
                >
                  {{ this.license.network }}
                </div>
              </div>
            </div>
            <div class="flex justify-center col-span-2">
              <div class="w-full lg:w-1/2">
                <div class="font-bold text-center">
                  {{ $t("license.phone") }}
                </div>
                <div
                  type="text"
                  class="bg-white rounded-full shadow-lg p-1 pl-3 mt-2 focus:outline-none w-full text-center"
                >
                  {{ this.license.phone }}
                </div>
              </div>
            </div>
            <div class="flex justify-center col-span-2">
              <div class="w-full lg:w-1/2">
                <div class="font-bold text-center">
                  {{ $t("license.email") }}
                </div>
                <div
                  type="text"
                  class="bg-white rounded-full shadow-lg p-1 pl-3 mt-2 focus:outline-none w-full text-center"
                >
                  {{ this.license.email }}
                </div>
              </div>
            </div>
            <div class="flex justify-center col-span-2">
              <div class="w-full lg:w-1/2">
                <div class="font-bold text-center">
                  {{ $t("license.expires_at") }}
                </div>
                <div
                  type="text"
                  class="bg-white rounded-full shadow-lg p-1 pl-3 mt-2 focus:outline-none w-full text-center"
                >
                  {{ this.license.expires_at }}
                </div>
              </div>
            </div>

            <div class="flex justify-center border-b border-black col-span-2">
              {{ $t("license.package") }}
            </div>
            <div class="flex justify-center col-span-2">
              <div class="w-full lg:w-1/2">
                <div class="font-bold text-center">
                  {{ $t("license.package_name") }}
                </div>
                <div
                  type="text"
                  class="bg-white rounded-full shadow-lg p-1 pl-3 mt-2 focus:outline-none w-full text-center"
                >
                  {{ this.license.package_name }}
                </div>
              </div>
            </div>
            <div class="flex justify-center col-span-2">
              <div class="w-full lg:w-1/2">
                <div class="font-bold text-center">
                  {{ $t("license.package_details") }}
                </div>
                <div
                  type="text"
                  class="bg-white rounded-full shadow-lg p-1 pl-3 mt-2 focus:outline-none w-full text-center"
                >
                  {{ this.license.package_details }}
                </div>
              </div>
            </div>
            <div class="flex justify-center col-span-2">
              <div class="w-full lg:w-1/2">
                <div class="font-bold text-center">
                  {{ $t("license.package_months") }}
                </div>
                <div
                  type="text"
                  class="bg-white rounded-full shadow-lg p-1 pl-3 mt-2 focus:outline-none w-full text-center"
                >
                  {{ this.license.package_months }}
                </div>
              </div>
            </div>
            <div class="flex justify-center col-span-2">
              <div class="w-full lg:w-1/2">
                <div class="font-bold text-center">
                  {{ $t("license.package_online_count") }}
                </div>
                <div
                  type="text"
                  class="bg-white rounded-full shadow-lg p-1 pl-3 mt-2 focus:outline-none w-full text-center"
                >
                  {{ this.license.package_online_count }}
                </div>
              </div>
            </div>
            <div class="flex justify-center col-span-2">
              <div class="w-full lg:w-1/2">
                <div class="font-bold text-center">
                  {{ $t("license.package_nases_count") }}
                </div>
                <div
                  type="text"
                  class="bg-white rounded-full shadow-lg p-1 pl-3 mt-2 focus:outline-none w-full text-center"
                >
                  {{ this.license.package_nases_count }}
                </div>
              </div>
            </div>
            <div class="flex justify-center col-span-2">
              <div class="w-full lg:w-1/2">
                <div class="font-bold text-center">
                  {{ $t("license.package_admins_count") }}
                </div>
                <div
                  type="text"
                  class="bg-white rounded-full shadow-lg p-1 pl-3 mt-2 focus:outline-none w-full text-center"
                >
                  {{ this.license.package_admins_count }}
                </div>
              </div>
            </div>
            <div class="flex justify-center col-span-2">
              <div class="w-full lg:w-1/2">
                <div class="font-bold text-center">
                  {{ $t("license.package_resellers_count") }}
                </div>
                <div
                  type="text"
                  class="bg-white rounded-full shadow-lg p-1 pl-3 mt-2 focus:outline-none w-full text-center"
                >
                  {{ this.license.package_resellers_count }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LoadingOverlay from "../components/modals/LoadingOverlay.vue";
import axios from "axios";
export default {
  name: "License",
  components: { LoadingOverlay },
  data() {
    return {
      loading: false,
      license_error: null,
      license_success: null,
      license: {
        address: "...",
        expires_at: "...",
        full_name: "...",
        id: "...",
        ip: "...",
        name: "...",
        network: "...",
        package_months: "...",
        package_name: "...",
        package_online_count: "...",
        package_details: "...",
        phone: "...",
        realm: "...",
      },
    };
  },
  async created() {
    this.fetchLicense();
  },
  methods: {
    async fetchLicense() {
      this.loading = true;
      const response = await axios.get("settings/license");
      if (response.status == 200) {
        this.license = response.data.data;
      }
      this.loading = false;
    },
    async checkLicense() {
      this.loading = true;
      const curr_url = window.location.origin;
      if (
        curr_url == "http://localhost:8080" ||
        curr_url == "https://s1.ssradius.com" ||
        curr_url == "https://s2.ssradius.com"
      ) {
        this.loading = false;
        return;
      }
      try {
        const response = await axios.get("update");
        if (response.status == 210) {
          license_error = this.$t("licnese_update_error");
        }
        if (response.status == 200) {
          license_success = this.$t("licnese_update_success");
        }
      } catch (error) {
        license_error = this.$t("licnese_update_error");
      }
      this.loading = false;
    },
  },
};
</script>
