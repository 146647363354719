<template>
  <svg viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M42.0747 15.2168C44.9585 18.1015 46.9222 21.7766 47.7175 25.7772C48.5128 29.7779 48.1039 33.9246 46.5427 37.6929C44.9814 41.4612 42.3378 44.682 38.9462 46.948C35.5546 49.214 31.5672 50.4235 27.4883 50.4235C23.4093 50.4235 19.422 49.214 16.0304 46.948C12.6387 44.682 9.99517 41.4612 8.43392 37.6929C6.87266 33.9246 6.46383 29.7779 7.2591 25.7772C8.05438 21.7766 10.018 18.1015 12.9018 15.2168"
      stroke="#161853"
      stroke-width="5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M27.5 4.5835V27.5002"
      stroke="#4ECE89"
      stroke-width="5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "WarningIcon",
};
</script>
