<template>
  <div
    @click.prevent="if (this.$refs.menu != null) this.$refs.menu.close();"
    class="main-content flex-1 bg-gray-100"
  >
    <loading-overlay :loading="loading_overlay" />
    <transition name="fade">
      <account-details
        v-if="selectedData"
        :visible="showDetails"
        :selectedAccount="selectedData.account"
        :compacted="true"
        @hide-details="showDetails = false"
      />
    </transition>
    <transition name="fade">
      <columns-select
        :visible="showColumnsSelect"
        :columns="columns"
        :name="'support_tickets_columns'"
        @hide="showColumnsSelect = false"
        @selectconfirmed="updateColumns(currentColumns)"
      />
    </transition>
    <transition name="fade">
      <delete-confirm
        :visible="showDelete"
        @hide="showDelete = false"
        @deleteconfirmed="deleteData"
      />
    </transition>
    <transition name="fade">
      <warnning-confirm
        :message="$t('messages.support_tickets.delete_all')"
        :visible="showDeleteAll"
        @confirmed="groupDelete()"
        @hide="showDeleteAll = false"
      />
    </transition>
    <transition name="fade">
      <push-account-notification
        v-if="selectedData"
        :visible="showNotification"
        :account="selectedData.account"
        @updated="showNotification = false"
        @hide="showNotification = false"
      />
    </transition>

    <transition name="expandy">
      <tables-context :display="showContext" ref="menu">
        <ul
          :class="{
            disabled: !this.$store.getters.settings.license.active,
          }"
        >
          <li
            v-if="hasPrivilege('support_tickets_edit_support_tickets')"
            @click="editData(selectedData)"
            class="m-2 border-b border-black hover:bg-gray-200"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6 inline-block rounded-lg bg-blue-300"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
              />
            </svg>
            {{ $t("actions.edit") }}
          </li>
          <li
            v-if="
              hasPrivilege('notifications_edit_manual_notifications') ||
                hasPrivilege('notifications_push_notifications')
            "
            @click="showNotification = true"
            class="m-2 border-b border-black hover:bg-gray-200"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6 rounded-lg inline-block bg-yellow-400 text-white"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
              />
            </svg>
            {{ $t("actions.notify") }}
          </li>
          <li
            v-if="hasPrivilege('support_tickets_delete_support_tickets')"
            @click="showDelete = true"
            class="m-2 border-b border-black hover:bg-gray-200"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6 inline-block bg-red-500 text-white rounded-lg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
              />
            </svg>
            {{ $t("actions.delete") }}
          </li>
        </ul>
      </tables-context>
    </transition>

    <div class="p-2 md:p-6" @click.prevent="showFilters = false">
      <div class="flex justify-between">
        <div>
          <button
            class="text-xs lg:text-base rounded-full text-black lg:py-1 px-4 mr-4"
            :class="{
              'bg-blue-300 text-black shadow-blue ': autoUpdate,
              'bg-gray-700 text-white': !autoUpdate,
            }"
            @click.prevent="toggleAutoUpdate"
          >
            {{
              autoUpdate
                ? "✓ " + $t("accounts.auto_update")
                : $t("accounts.auto_update")
            }}
          </button>
          <button
            class="text-xs lg:text-base rounded-full text-black lg:py-1 px-4 mr-4"
            :class="{
              'bg-blue-300 text-black shadow-blue ': filters.save,
              'bg-gray-700 text-white': !filters.save,
            }"
            @click.prevent="toggleSaveFilters"
          >
            {{
              autoUpdate
                ? "✓ " + $t("control.save_filters")
                : $t("control.save_filters")
            }}
          </button>
        </div>
        <div class="flex">
          <button
            class="
              rounded-full
              bg-ssr-side_bar-settings
              text-xs
              lg:text-base
              text-white
              py-1
              lg:py-2
              px-4
              font-bold
              mr-4
            "
            @click.prevent="showColumnsSelect = true"
          >
            ...
          </button>
          <button
            v-if="hasPrivilege('support_tickets_create_support_tickets')"
            @click.prevent="this.$router.push('/services/support/create')"
            class="
              rounded-full
              bg-ssr-side_bar-button_hover
              text-xs
              lg:text-base
              text-white
              lg:p-2
              px-2
            "
            :class="{
              disabled: !this.$store.getters.settings.license.active,
            }"
          >
            {{ $t("support_tickets.add_ticket") }}
          </button>
        </div>
      </div>
      <div
        class="w-full flex flex-col text-center overflow-auto"
        style="height: 30rem"
      >
        <table>
          <thead class="border-b-2 border-black text-xs md:text-base">
            <th></th>
            <th>
              <table-check-box
                class="w-5 h-5 my-3 mx-4 cursor-pointer"
                :value="allSelected"
                @click="checkAll"
              />
            </th>
            <th v-if="columns.id.enabled">
              <div class="inline-block">{{ $t("support_tickets.id") }}</div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="
                  h-6
                  w-6
                  inline-block
                  transform
                  transition-all
                  cursor-pointer
                "
                :class="{ 'rotate-180': columns.id.sort }"
                @click="sort('id')"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 15l7-7 7 7"
                />
              </svg>
              <input
                v-if="
                  hasPrivilege('support_tickets_view_search_support_tickets')
                "
                type="text"
                class="
                  bg-white
                  rounded-full
                  w-full
                  shadow-lg
                  p-1
                  m-1
                  focus:outline-none
                  text-center
                "
                :placeholder="$t('control.filter')"
                @input="applyFilters"
                v-model="filters.id"
              />
            </th>
            <th v-if="columns.username.enabled">
              <div class="inline-block">
                {{ $t("support_tickets.username") }}
              </div>
              <input
                v-if="
                  hasPrivilege('support_tickets_view_search_support_tickets')
                "
                type="text"
                class="
                  bg-white
                  rounded-full
                  w-full
                  shadow-lg
                  p-1
                  m-1
                  focus:outline-none
                  text-center
                "
                :placeholder="$t('control.filter')"
                @input="applyFilters"
                v-model="filters.username"
              />
            </th>
            <th v-if="columns.issue.enabled">
              <div class="inline-block">
                {{ $t("support_tickets.issue") }}
              </div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="
                  h-6
                  w-6
                  inline-block
                  transform
                  transition-all
                  cursor-pointer
                "
                :class="{ 'rotate-180': columns.issue.sort }"
                @click="sort('issue')"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 15l7-7 7 7"
                />
              </svg>
              <!-- <input
                v-if="
                  hasPrivilege('support_tickets_view_search_support_tickets')
                "
                type="text"
                class="bg-white rounded-full w-full shadow-lg p-1 m-1 focus:outline-none text-center"
                :placeholder="$t('control.filter')"
                @input="applyFilters"
                v-model="filters.issue"
              /> -->
            </th>
            <th v-if="columns.phone.enabled">
              <div class="inline-block">
                {{ $t("support_tickets.phone") }}
              </div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="
                  h-6
                  w-6
                  inline-block
                  transform
                  transition-all
                  cursor-pointer
                "
                :class="{ 'rotate-180': columns.phone.sort }"
                @click="sort('phone')"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 15l7-7 7 7"
                />
              </svg>
              <input
                v-if="
                  hasPrivilege('support_tickets_view_search_support_tickets')
                "
                type="text"
                class="
                  bg-white
                  rounded-full
                  w-full
                  shadow-lg
                  p-1
                  m-1
                  focus:outline-none
                  text-center
                "
                :placeholder="$t('control.filter')"
                @input="applyFilters"
                v-model="filters.phone"
              />
            </th>
            <th v-if="columns.address.enabled">
              <div class="inline-block">
                {{ $t("support_tickets.address") }}
              </div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="
                  h-6
                  w-6
                  inline-block
                  transform
                  transition-all
                  cursor-pointer
                "
                :class="{ 'rotate-180': columns.address.sort }"
                @click="sort('address')"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 15l7-7 7 7"
                />
              </svg>
              <input
                v-if="
                  hasPrivilege('support_tickets_view_search_support_tickets')
                "
                type="text"
                class="
                  bg-white
                  rounded-full
                  w-full
                  shadow-lg
                  p-1
                  m-1
                  focus:outline-none
                  text-center
                "
                :placeholder="$t('control.filter')"
                @input="applyFilters"
                v-model="filters.address"
              />
            </th>
            <th v-if="columns.priority.enabled">
              <div class="inline-block">
                {{ $t("support_tickets.priority.priority") }}
              </div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="
                  h-6
                  w-6
                  inline-block
                  transform
                  transition-all
                  cursor-pointer
                "
                :class="{ 'rotate-180': columns.priority.sort }"
                @click="sort('priority')"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 15l7-7 7 7"
                />
              </svg>
            </th>
            <th v-if="columns.status.enabled">
              <div class="inline-block">
                {{ $t("support_tickets.status") }}
              </div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="
                  h-6
                  w-6
                  inline-block
                  transform
                  transition-all
                  cursor-pointer
                "
                :class="{ 'rotate-180': columns.status.sort }"
                @click="sort('status')"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 15l7-7 7 7"
                />
              </svg>
            </th>
            <th v-if="columns.notes.enabled">
              <div class="inline-block">
                {{ $t("support_tickets.notes") }}
              </div>
            </th>
            <th v-if="columns.ssr_notes.enabled">
              <div class="inline-block">
                {{ $t("support_tickets.ssr_notes") }}
              </div>
            </th>
            <th v-if="columns.created_at.enabled">
              <div class="inline-block">
                {{ $t("control.created_at") }}
              </div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="
                  h-6
                  w-6
                  inline-block
                  transform
                  transition-all
                  cursor-pointer
                "
                :class="{ 'rotate-180': columns.created_at.sort }"
                @click="sort('created_at')"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 15l7-7 7 7"
                />
              </svg>
              <input
                v-if="
                  hasPrivilege('support_tickets_view_search_support_tickets')
                "
                type="text"
                class="
                  bg-white
                  rounded-full
                  w-full
                  shadow-lg
                  p-1
                  m-1
                  focus:outline-none
                  text-center
                "
                :placeholder="$t('control.filter')"
                @input="applyFilters"
                v-model="filters.created_at"
              />
            </th>
            <th></th>
          </thead>
          <tbody>
            <tr
              v-for="(ticket, index) in dataList"
              v-bind:key="ticket.id"
              class="text-xs md:text-base"
              :class="{
                'bg-gray-200': index % 2 == 0,
                'bg-blue-300': ticket.selected,
              }"
              @contextmenu.prevent="openContextMenu($event, ticket)"
            >
              <td class="font-bold">
                {{ index + 1 + (meta.current_page - 1) * meta.per_page }}
              </td>
              <td>
                <table-check-box
                  class="w-5 h-5 my-3 mx-4 cursor-pointer"
                  :value="ticket.selected"
                  @click="selectData(ticket)"
                />
              </td>
              <td v-if="columns.id.enabled">
                {{ ticket.id }}
              </td>
              <td
                v-if="columns.username.enabled"
                :class="{
                  disabled: !this.$store.getters.settings.license.active,
                }"
              >
                <a
                  class="cursor-pointer underline text-blue-800"
                  @click.prevent="
                    selectedData = ticket;
                    showDetails = true;
                  "
                >
                  {{ ticket.account.username }}
                </a>
              </td>
              <td
                v-if="columns.issue.enabled"
                class="max-w-16 max-h-16 overflow-auto"
              >
                {{ ticket.issue }}
              </td>
              <td v-if="columns.phone.enabled">{{ ticket.phone }}</td>
              <td v-if="columns.address.enabled">{{ ticket.address }}</td>
              <td v-if="columns.priority.enabled">
                <div
                  class="
                    rounded-full
                    px-2
                    bg-green-200
                    text-green-700
                    inline-block
                    my-2
                  "
                  v-if="ticket.priority == '0'"
                >
                  {{ $t("support_tickets.priority.low") }}
                </div>
                <div
                  class="
                    rounded-full
                    px-2
                    bg-yellow-200
                    text-yellow-700
                    inline-block
                    my-2
                  "
                  v-if="ticket.priority == '1'"
                >
                  {{ $t("support_tickets.priority.normal") }}
                </div>
                <div
                  class="
                    rounded-full
                    px-2
                    bg-red-400
                    text-red-800
                    inline-block
                    my-2
                  "
                  v-if="ticket.priority == '2'"
                >
                  {{ $t("support_tickets.priority.high") }}
                </div>
              </td>
              <td v-if="columns.status.enabled">
                <div
                  class="
                    rounded-full
                    px-2
                    bg-blue-200
                    text-blue-700
                    inline-block
                    my-2
                  "
                  v-if="ticket.status == 'new'"
                >
                  {{ $t("support_tickets.new") }}
                </div>
                <div
                  class="
                    rounded-full
                    px-2
                    bg-yellow-200
                    text-yellow-700
                    inline-block
                    my-2
                  "
                  v-if="ticket.status == 'in_progress'"
                >
                  {{ $t("support_tickets.in_progress") }}
                </div>
                <div
                  class="
                    rounded-full
                    px-2
                    bg-green-200
                    text-green-700
                    inline-block
                    my-2
                  "
                  v-if="ticket.status == 'resolved'"
                >
                  {{ $t("support_tickets.resolved") }}
                </div>
                <div
                  class="
                    rounded-full
                    px-2
                    bg-red-400
                    text-red-800
                    inline-block
                    my-2
                  "
                  v-if="ticket.status == 'failed'"
                >
                  {{ $t("support_tickets.failed") }}
                </div>
              </td>
              <td v-if="columns.notes.enabled">
                {{ ticket.notes }}
              </td>
              <td v-if="columns.ssr_notes.enabled">{{ ticket.ssr_notes }}</td>
              <td v-if="columns.created_at.enabled">
                {{ formatDate(ticket.created_at) }}
              </td>
              <td>
                <div
                  class="
                    px-3
                    p-1
                    mx-2
                    bg-ssr-side_bar-settings
                    text-white text-center
                    rounded-full
                    inline-block
                    cursor-pointer
                  "
                  :class="{
                    disabled: !this.$store.getters.settings.license.active,
                  }"
                  @click="
                    this.$store.commit('changeKey');
                    this.$router.push(`/services/support/${ticket.id}/edit`);
                  "
                >
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      stroke-width="2"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                      />
                    </svg>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-if="loading" class="w-full flex justify-center">
        <img
          class="inline-block h-12 w-12"
          src="@/assets/loading.gif"
          alt="Loading"
        />
      </div>
      <div
        class="flex justify-start"
        v-if="selection_array.length == 1"
        :class="{
          disabled: !this.$store.getters.settings.license.active,
        }"
      >
        <div
          v-if="hasPrivilege('support_tickets_edit_support_tickets')"
          @click="editData(selection_array[0])"
          class="mx-3 cursor-pointer group relative"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6 inline-block rounded-lg bg-blue-300"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
            />
          </svg>
          <div
            class="
              scale-0
              transform
              transition-all
              ease-linear
              group-hover:scale-100
              absolute
            "
          >
            {{ $t("actions.edit") }}
          </div>
        </div>
        <div
          v-if="
            hasPrivilege('notifications_edit_manual_notifications') ||
              hasPrivilege('notifications_push_notifications')
          "
          @click="
            selectedData = selection_array[0];
            showNotification = true;
          "
          class="cursor-pointer group relative"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="
              h-6
              w-6
              inline-block
              rounded-lg
              bg-yellow-300
              mr-3
              text-white
            "
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            stroke-width="2"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
            />
          </svg>
          <div
            class="
              scale-0
              transform
              transition-all
              ease-linear
              group-hover:scale-100
              absolute
            "
          >
            {{ $t("actions.notify") }}
          </div>
        </div>
        <div
          v-if="hasPrivilege('support_tickets_delete_support_tickets')"
          @click="
            selectedData = selection_array[0];
            showDelete = true;
          "
          class="group cursor-pointer relative"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6 inline-block bg-red-500 text-white rounded-lg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
            />
          </svg>
          <div
            class="
              scale-0
              transform
              transition-all
              ease-linear
              group-hover:scale-100
              absolute
            "
          >
            {{ $t("actions.delete") }}
          </div>
        </div>
      </div>
      <div
        class="flex justify-start"
        v-if="selection_array.length > 1"
        :class="{
          disabled: !this.$store.getters.settings.license.active,
        }"
      >
        <div
          v-if="hasPrivilege('support_tickets_delete_support_tickets')"
          @click="showDeleteAll = true"
          class="group cursor-pointer relative"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6 inline-block bg-red-500 text-white rounded-lg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
            />
          </svg>
          <div
            class="
              scale-0
              transform
              transition-all
              ease-linear
              group-hover:scale-100
              absolute
            "
          >
            {{ $t("actions.delete") }}
          </div>
        </div>
      </div>
      <pagination-row
        class="mt-2"
        @triggerupdate="fetchDataPortion"
        :meta="meta"
        :row="filters.rows"
      />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import PillDefault from "../components/PillDefault.vue";
import DeleteConfirm from "../components/modals/DeleteConfirm.vue";
import PaginationRow from "../components/PaginationRow.vue";
import TablesContext from "../components/contexts/TablesContext.vue";
import ColumnsSelect from "../components/modals/ColumnsSelect.vue";
import ProfileDetails from "../components/modals/ProfileDetails.vue";
import TableCheckBox from "../components/formElements/TableCheckBox.vue";
import { mapGetters } from "vuex";
import { hasPrivilege } from "../global_constants.js";
import AccountDetails from "../components/modals/AccountDetails.vue";
import WarnningConfirm from "../components/modals/WarnningConfirm.vue";
import LoadingOverlay from "../components/modals/LoadingOverlay.vue";
import PushAccountNotification from "../components/modals/PushAccountNotification.vue";
export default {
  components: {
    PillDefault,
    DeleteConfirm,
    PaginationRow,
    TablesContext,
    ColumnsSelect,
    TableCheckBox,
    ProfileDetails,
    AccountDetails,
    WarnningConfirm,
    LoadingOverlay,
    PushAccountNotification,
  },
  computed: {
    ...mapGetters(["user"]),
  },
  name: "SupportTickets",
  data() {
    return {
      dataList: null,
      meta: { last_page: 0 },
      selectedData: null,
      showDetails: false,
      showDelete: false,
      showDeleteAll: false,
      showContext: false,
      showNotification: false,
      loading: true,
      showFilters: false,
      fullList: false,
      filters: {
        rows: 50,
        page: "",
        save: false,
        id: "",
        username: "",
        issue: "",
        phone: "",
        address: "",
        priority: "",
        status: "",
        notes: "",
        ssr_notes: "",
        created_at: "",
      },
      checkedBox: true,
      allSelected: false,
      showColumnsSelect: false,
      columns: {
        id: { name: this.$t("support_tickets.id"), enabled: true },
        username: { name: this.$t("support_tickets.username"), enabled: true },
        issue: { name: this.$t("support_tickets.issue"), enabled: true },
        phone: { name: this.$t("support_tickets.phone"), enabled: false },
        address: { name: this.$t("support_tickets.address"), enabled: false },
        priority: {
          name: this.$t("support_tickets.priority.priority"),
          enabled: true,
        },
        status: {
          name: this.$t("support_tickets.status"),
          enabled: true,
        },
        notes: { name: this.$t("support_tickets.notes"), enabled: false },
        ssr_notes: {
          name: this.$t("support_tickets.ssr_notes"),
          enabled: false,
        },
        created_at: {
          name: this.$t("control.created_at"),
          enabled: true,
        },
      },
      autoUpdate: false,
      selection_array: [],
      debounce: null,
      loading_overlay: false,
    };
  },
  async created() {
    this.prepareFilters();
    this.prepareColumns();
    if (this.$route.query.id) {
      this.filters.id = this.$route.query.id;
      this.columns.id.enabled = true;
    }
    this.applyFilters();
  },
  methods: {
    hasPrivilege(privilege) {
      return hasPrivilege(this.user, privilege);
    },
    async fetchData() {
      if (this.filters.rows > 0 || this.filters.page > 0) {
        this.fetchDataPortion(this.filters.rows, this.filters.page);
        this.selection_array = [];
        return;
      }
      const response = await axios.get("support");
      this.dataList = response.data.data;
      this.meta = response.data.meta;
      this.loading = false;
    },
    prepareFilters() {
      var filters = localStorage.getItem("support_tickets_filters");
      if (filters != null) {
        filters = JSON.parse(filters);
        if (filters.save) this.filters = filters;
        else this.filters.rows = filters.rows;
      }
    },
    prepareColumns() {},
    async applyFilters() {
      this.loading = true;
      var requestString = "support?filters=1";
      if (this.filters.page != "")
        requestString += `&page=${this.filters.page}`;
      if (this.filters.rows != "")
        requestString += `&rows=${this.filters.rows}`;
      if (this.filters.username != "")
        requestString += `&username=${this.filters.username}`;
      if (this.filters.id != "") requestString += `&id=${this.filters.id}`;
      if (this.filters.address != "")
        requestString += `&address=${this.filters.address}`;
      if (this.filters.created_at != "")
        requestString += `&created_at=${this.filters.created_at}`;
      if (this.filters.status != "")
        requestString += `&status=${this.filters.status}`;
      if (this.filters.priority != "")
        requestString += `&priority=${this.filters.priority}`;
      if (this.filters.ssr_notes != "")
        requestString += `&ssr_notes=${this.filters.ssr_notes}`;
      if (this.sort_col)
        requestString += `&order_by=${this.sort_col}&order_dir=${this.sort_dir}`;
      clearTimeout(this.debounce);
      this.debounce = setTimeout(async () => {
        const response = await axios.get(requestString);
        this.dataList = response.data.data;
        if (this.filters.save)
          localStorage.setItem(
            "support_tickets_filters",
            JSON.stringify(this.filters)
          );
        if (this.dataList.length < 1 && this.filters.page != 1) {
          this.filters.page = 1;
          this.applyFilters();
        }
        if (this.sort_col) {
          if (this.sort_dir == "ASC") {
            this.dataList.sort((a, b) =>
              a[this.sort_col] > b[this.sort_col]
                ? 1
                : b[this.sort_col] > a[this.sort_col]
                ? -1
                : 0
            );
          } else {
            this.dataList.sort((a, b) =>
              a[this.sort_col] < b[this.sort_col]
                ? 1
                : b[this.sort_col] < a[this.sort_col]
                ? -1
                : 0
            );
          }
        }
        this.meta = response.data.meta;
        this.loading = false;
      }, 1000);
    },
    async fetchDataPortion(rows, currentPage) {
      this.filters.rows = rows;
      this.filters.page = currentPage;
      this.applyFilters();
    },
    editData(selectedData) {
      this.$store.commit("changeKey");
      this.$router.push(`/services/support/${selectedData.id}/edit`);
    },
    async deleteData() {
      const response = await axios.delete(`support/${this.selectedData.id}`);
      if (response.status == 200) {
        this.selection_array = [];
        this.fetchData();
        this.showDelete = false;
        this.$router.push({ query: { success: "success" } });
      }
    },
    async groupDelete() {
      this.showDeleteAll = false;
      this.loading_overlay = true;
      const response = await axios.post(`support/group`, {
        tickets: this.selection_array,
      });
      if (response.status == 200) {
        this.selection_array = [];
        this.applyFilters();
        this.$router.push({ query: { success: "success" } });
      }
      this.loading_overlay = false;
    },
    formatDate(date) {
      return moment(date).format("yyyy-MM-DD HH:mm");
    },
    openContextMenu(e, data) {
      this.selectedData = data;
      this.$refs.menu.open(e);
    },
    checkAll() {
      if (this.allSelected) {
        this.selection_array = [];
        this.dataList.forEach((element) => {
          element.selected = false;
        });
        this.allSelected = false;
      } else {
        this.selection_array = [];
        this.dataList.forEach((element) => {
          element.selected = true;
          this.selection_array.push(element);
        });
        this.allSelected = true;
      }
    },
    selectData(data) {
      if (data.selected == false) {
        data.selected = true;
        this.selection_array.push(data);
      } else {
        data.selected = false;
        this.selection_array.splice(this.selection_array.indexOf(data), 1);
      }
    },
    updateColumns(updated) {
      this.columns = updated;
      this.showColumnsSelect = false;
    },
    async toggleAutoUpdate() {
      this.autoUpdate = !this.autoUpdate;
      while (this.autoUpdate) {
        this.applyFilters();
        await new Promise((r) => setTimeout(r, 30000));
      }
    },
    sort(param) {
      this.sort_col = param;
      if (!this.columns[param].sort) {
        this.sort_dir = "ASC";
        Object.entries(this.columns).forEach(([key, val]) => {
          val.sort = false;
        });
        this.columns[param].sort = true;
      } else {
        this.sort_dir = "DESC";
        Object.entries(this.columns).forEach(([key, val]) => {
          val.sort = false;
        });
        this.columns[param].sort = false;
      }
      this.applyFilters();
    },
    toggleSaveFilters() {
      this.filters.save = !this.filters.save;
      localStorage.setItem(
        "support_tickets_filters",
        JSON.stringify(this.filters)
      );
    },
  },
};
</script>
