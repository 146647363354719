<template>
  <div id="chart-container">
    <canvas id="users_chart"></canvas>
  </div>
</template>

<script>
import Chart from "chart.js/auto";
export default {
  name: "NewUsersChart",
  props: ["x_data", "y_data", "title"],
  data() {
    return {
      chart: null,
    };
  },
  mounted() {
    // const ctx = this.$refs[1];
    const ctx = document.getElementById("users_chart");
    const labels = this.x_data;
    const data = {
      labels: labels,
      datasets: [
        {
          label: this.title,
          backgroundColor: "rgb(255, 99, 132)",
          borderColor: "rgb(255, 99, 132)",
          data: this.y_data,
          borderRadius: Number.MAX_VALUE,
          barPercentage: 0.6,
        },
      ],
    };
    const config = {
      type: "bar",
      data: data,
      options: {
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          x: {
            grid: {
              display: false,
            },
            ticks: {
              color: "#e74b75",
              backgroundColor: "#e74b75",
              font: {
                size: 14,
                weight: "bold",
              },
            },
          },
          y: {
            grid: {
              display: true,
            },
            ticks: {
              stepSize: 25,
              color: "#e74b75",
              backgroundColor: "#e74b75",
              font: {
                size: 14,
                weight: "bold",
              },
            },
          },
        },
      },
    };
    if (this.chart != null) {
      this.chart.destroy();
    }
    try {
      this.chart = new Chart(ctx, config);
    } catch (error) {}
  },
};
</script>
