<template>
  <div class="main-content flex-1 relative bg-gray-100">
    <loading-overlay :visible="loading" :message="message" />
    <div
      v-if="this.$store.getters.settings.license.active"
      class="flex justify-center w-full"
    >
      <div class="bg-gray-300 rounded-xl w-full m-6 flex flex-col pb-12 px-20">
        <div class="flex flex-col items-center mt-12 justify-start">
          <div
            v-if="
              (!file_ready || file_ready == 'dma') &&
                settings.features.dma_import
            "
            class="text-black font-bold"
          >
            {{ $t("restore.import_dma") }}
          </div>
          <div
            v-if="
              (!file_ready || file_ready == 'dma') &&
                settings.features.dma_import
            "
            class="flex items-center mt-2"
          >
            <span
              v-if="!file_ready && !uploading"
              ref="browse_btn"
              id="browse_btn"
              @click="
                file_ready = 'dma';
                initResumable();
              "
              class="
                bg-ssr-side_bar-button
                text-white
                px-4
                py-1
                rounded-full
                cursor-pointer
              "
              >{{ $t("restore.choose_file") }}</span
            >
            <span
              v-if="file_uploaded"
              class="
                bg-ssr-side_bar-button
                text-white
                px-4
                py-1
                rounded-full
                bg-opacity-50
              "
              >{{ $t("restore.file_ready") }}</span
            >
          </div>
          <div
            v-if="file_ready != 'dma' && file_ready != 'nas'"
            class="text-black font-bold mt-6"
          >
            {{ $t("restore.import_local") }}
          </div>
          <div
            v-if="file_ready != 'dma' && file_ready != 'nas'"
            class="flex items-center mt-2"
          >
            <span
              v-if="!file_ready && !uploading"
              ref="browse_btn2"
              id="browse_btn2"
              @click="
                file_ready = 'ssr';
                initResumable2();
              "
              class="
                bg-ssr-side_bar-button
                text-white
                px-4
                py-1
                rounded-full
                cursor-pointer
              "
              >{{ $t("restore.choose_file") }}</span
            >
            <span
              v-if="file_uploaded"
              class="
                bg-ssr-side_bar-button
                text-white
                px-4
                py-1
                rounded-full
                bg-opacity-50
              "
              >{{ $t("restore.file_ready") }}</span
            >
          </div>
          <div
            v-if="file_ready != 'nas' && file_ready != 'ssr'"
            class="text-black font-bold mt-6"
          >
            {{ $t("restore.import_nas") }}
          </div>
          <div
            v-if="file_ready != 'dma' && file_ready != 'ssr'"
            class="flex items-center mt-2"
          >
            <span
              v-if="!file_ready && !uploading"
              ref="browse_btn3"
              id="browse_btn3"
              @click="
                file_ready = 'nas';
                initResumable3();
              "
              class="
                bg-ssr-side_bar-button
                text-white
                px-4
                py-1
                rounded-full
                cursor-pointer
              "
              >{{ $t("restore.choose_file") }}</span
            >
            <span
              v-if="file_uploaded"
              class="
                bg-ssr-side_bar-button
                text-white
                px-4
                py-1
                rounded-full
                bg-opacity-50
              "
              >{{ $t("restore.file_ready") }}</span
            >
          </div>
          <div v-if="uploading" class="flex items-center">
            {{ uploading_message }}
          </div>
          <div
            v-if="file_uploaded && file_ready == 'dma'"
            class="flex items-end mt-6"
          >
            <toggle-button
              class="flex-1"
              :value="clean"
              :enabled_name="$t('restore.clean_restore')"
              :disabled_name="$t('restore.clean_restore')"
              @toggle="clean = !clean"
            />
          </div>
          <div
            v-if="file_uploaded && file_ready == 'dma'"
            class="flex items-end mt-6"
          >
            <toggle-button
              class="flex-1"
              :value="sessions"
              :enabled_name="$t('restore.restore_radacct')"
              :disabled_name="$t('restore.restore_radacct')"
              @toggle="sessions = !sessions"
            />
          </div>
          <p v-if="sessions" class="font-bold mt-6 text-yellow-600">
            {{ $t("restore.warning_radacct") }}
          </p>
          <p
            v-if="file_ready == 'nas'"
            class="font-bold text-lg mt-6 text-red-600"
          >
            {{ $t("restore.warning_nas") }}
          </p>
          <div class="flex items-start mt-24">
            <div
              class="
                inline-block
                bg-green-600
                text-white
                px-8
                py-1
                rounded-full
              "
              :class="{
                'cursor-default bg-opacity-50': !file_uploaded,
                'cursor-pointer': file_uploaded,
              }"
              @click.prevent="if (file_uploaded) store();"
            >
              {{ $t("restore.import") }}
            </div>
          </div>
        </div>
        <!-- <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-20 mt-6">
          <div class="flex flex-col w-full items-start">
            <div class="bg-blue-200 border-b border-black font-bold w-full">
              Users: (username ,password ,profile ,.....)
            </div>
          </div>
          <div class="flex flex-col w-full items-start">
            <div class="bg-blue-200 border-b border-black font-bold w-full">
              Profiles: (name ,upload_speed ,download_speed, date_limit,
              quota_limit ,.....)
            </div>
          </div>
        </div> -->
      </div>
    </div>
    <expired-window v-else />
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters, mapState } from "vuex";
import NumericalSpinner from "../components/formElements/NumericalSpinner.vue";
import ToggleButton from "../components/formElements/ToggleButton.vue";
import Dropdown from "../components/formElements/Dropdown.vue";
import LoadingOverlay from "../components/modals/LoadingOverlay.vue";
import Resumable from "resumablejs";
import { ref } from "vue";
import ExpiredWindow from "../components/ExpiredWindow.vue";
export default {
  components: {
    NumericalSpinner,
    ToggleButton,
    Dropdown,
    LoadingOverlay,
    ExpiredWindow,
  },
  name: "Restore",
  computed: {
    ...mapGetters(["settings"]),
  },
  data() {
    return {
      dma: null,
      loading: false,
      message: "",
      sessions: false,
      clean: false,
      file_ready: null,
      file_uploaded: false,
      uploading: false,
      uploading_message: "",
      r: new Resumable({
        target: localStorage.getItem("server_api") + "res/upload",
        fileType: ["sql"],
        chunkSize: 1 * 1024 * 1024 * 20,
        testChunks: false,
        throttleProgressCallbacks: 1,
        simultaneousUploads: 3,
        permanentErrors: [419, 500, 210],
        headers: {
          Accept: "application/json",
        },
        withCredentials: true,
      }),
      r2: new Resumable({
        target: localStorage.getItem("server_api") + "res/upload",
        fileType: ["sql"],
        chunkSize: 1 * 1024 * 1024 * 20,
        testChunks: false,
        throttleProgressCallbacks: 1,
        simultaneousUploads: 3,
        permanentErrors: [419, 500, 210],
        headers: {
          Accept: "application/json",
        },
        withCredentials: true,
      }),
      r3: new Resumable({
        target: localStorage.getItem("server_api") + "res/upload",
        fileType: ["sql"],
        chunkSize: 1 * 1024 * 1024 * 20,
        testChunks: false,
        throttleProgressCallbacks: 1,
        simultaneousUploads: 3,
        permanentErrors: [419, 500, 210],
        headers: {
          Accept: "application/json",
        },
        withCredentials: true,
      }),
    };
  },
  async mounted() {
    this.r.assignBrowse(this.$refs.browse_btn);
    this.r2.assignBrowse(this.$refs.browse_btn2);
    this.r3.assignBrowse(this.$refs.browse_btn3);
  },
  methods: {
    updateProgress(state, value) {
      this.message = value;
      this.uploading = true;
      this.uploading_message = "uploading ... " + value;
      if (value == "ready") {
        this.uploading_message = "ready %100";
        this.file_uploaded = true;
      } else if (value == "fail") {
        this.file_ready = null;
        this.uploading_message = "fail !!";
      }
    },
    initResumable() {
      let updateProgress = this.updateProgress;
      let r = this.r;
      r.on("fileAdded", function(file, event) {
        updateProgress(true, "%0");
        r.upload();
      });
      r.on("fileProgress", function(file, event) {
        updateProgress(true, "%" + Math.floor(file.progress() * 100));
      });
      r.on("fileSuccess", function(file, message) {
        updateProgress(false, "ready");
      });
      r.on("fileError", function(file, message) {
        updateProgress(false, "fail");
      });
    },
    initResumable2() {
      let updateProgress = this.updateProgress;
      let r2 = this.r2;
      r2.on("fileAdded", function(file, event) {
        updateProgress(true, "%0");
        r2.upload();
      });
      r2.on("fileProgress", function(file, event) {
        updateProgress(true, "%" + Math.floor(file.progress() * 100));
      });
      r2.on("fileSuccess", function(file, message) {
        updateProgress(false, "ready");
      });
      r2.on("fileError", function(file, message) {
        updateProgress(false, "fail");
      });
    },
    initResumable3() {
      let updateProgress = this.updateProgress;
      let r3 = this.r3;
      r3.on("fileAdded", function(file, event) {
        updateProgress(true, "%0");
        r3.upload();
      });
      r3.on("fileProgress", function(file, event) {
        updateProgress(true, "%" + Math.floor(file.progress() * 100));
      });
      r3.on("fileSuccess", function(file, message) {
        updateProgress(false, "ready");
      });
      r3.on("fileError", function(file, message) {
        updateProgress(false, "fail");
      });
    },
    selectFile() {
      this.dma = this.$refs.dmaFile.files.item(0);
      this.dma = new File(
        [this.dma.slice(0, this.dma.size, this.dma.type)],
        "test.sql",
        { type: this.dma.type }
      );
    },
    async store() {
      try {
        this.loading = true;
        // this.message = this.$t("messages.restore.uploading");
        // const data = new FormData();
        // data.append("dma", this.dma);
        // const response = await axios.post("dma/import", data, {
        //   headers: { "Content-Type": "multipart/form-data" },
        // });
        // if (response.status != 200) {
        //   this.message = this.$t("errors.restore.uploading");
        //   return;
        // }
        this.message = this.$t("messages.restore.parsing");
        var response2;
        if (this.file_ready == "dma") {
          response2 = await axios.post("dma/restore", {
            sessions: this.sessions,
            clean: this.clean,
            file_name: this.r.files[0].fileName,
          });
        } else if (this.file_ready == "ssr") {
          response2 = await axios.post("import", {
            file_name: this.r2.files[0].fileName,
          });
        } else if (this.file_ready == "nas") {
          response2 = await axios.post("nases/import", {
            file_name: this.r3.files[0].fileName,
          });
        }
        if (response2.status != 200) {
          this.message = this.$t("errors.restore.parsing");
          return;
        }
        this.message = this.$t("messages.restore.cleaning");
        const response3 = await axios.post("dma/flush");
        if (response3.status != 200) {
          this.message = this.$t("errors.restore.cleaning");
          return;
        }
        this.loading = false;
        this.$store.commit("changeKey");
        this.$router.push({
          path: "/dashboard",
          query: { success: "suceess" },
        });
      } catch (error) {
        console.log(error);
        this.message = this.$t("errors.restore.server");
      }
    },
  },
};
</script>
