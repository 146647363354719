<template>
  <div class="main-content flex-1 relative bg-gray-100">
    <loading-overlay :visible="loading" />
    <div
      v-if="this.$store.getters.settings.license.active"
      class="flex justify-center w-full"
    >
      <div
        class="bg-gray-300 rounded-xl w-5/6 flex flex-col items-center pb-12 px-20 m-6"
      >
        <div class="w-full  flex flex-col items-center">
          <div class="grid grid-cols-2 lg:w-1/2 gap-6 mt-6">
            <div>
              <div>
                {username} =>
                {{ $t("accounts.username") }}
              </div>
              <div>{full_name} => {{ $t("accounts.full_name") }}</div>
              <div>{expires_at} => {{ $t("accounts.expires_at") }}</div>
            </div>
            <div>
              <div>
                {profile_name} =>
                {{ $t("profiles.name") + " " + $t("profiles.profile") }}
              </div>
              <div>
                {profile_price} =>
                {{ $t("profiles.price") + " " + $t("profiles.profile") }}
              </div>
              <!-- <div>{profile_quota} => Profile Quota</div> -->
            </div>
            <div class="col-span-2">
              <toggle-button
                :value="config.notifications_expiring_enabled"
                :enabled_name="$t('notifications.config.expiring_enabled')"
                :disabled_name="$t('notifications.config.expiring_enabled')"
                @toggle="
                  config.notifications_expiring_enabled = !config.notifications_expiring_enabled
                "
              />
            </div>
            <div
              class="col-span-2 h-32 flex flex-col items-center justify-center"
            >
              <label class="font-bold">{{
                $t("notifications.config.expiring_body")
              }}</label>
              <textarea
                class="bg-white rounded-3xl w-full h-24 shadow-lg p-1 pl-3 focus:outline-none"
                :style="text_direction"
                @input="validate"
                v-model="config.notifications_expiring_body"
                :disabled="!config.notifications_expiring_enabled"
                :class="{
                  'opacity-50': !config.notifications_expiring_enabled,
                  'shadow-error': errors.expiring,
                }"
                cols="30"
                rows="10"
              ></textarea>
              <div v-if="errors.expiring" class="text-sm text-red-600">
                * {{ errors.expiring }}
              </div>
            </div>
            <div class="col-span-2">
              <toggle-button
                :value="config.notifications_expired_enabled"
                :enabled_name="$t('notifications.config.expired_enabled')"
                :disabled_name="$t('notifications.config.expired_enabled')"
                @toggle="
                  config.notifications_expired_enabled = !config.notifications_expired_enabled
                "
              />
            </div>
            <div
              class="col-span-2 h-32 flex flex-col items-center justify-center"
            >
              <label class="font-bold">{{
                $t("notifications.config.expired_body")
              }}</label>
              <textarea
                class="bg-white rounded-3xl w-full h-24 shadow-lg p-1 pl-3 focus:outline-none"
                :style="text_direction"
                @input="validate"
                v-model="config.notifications_expired_body"
                :disabled="!config.notifications_expired_enabled"
                :class="{
                  'opacity-50': !config.notifications_expired_enabled,
                  'shadow-error': errors.expired,
                }"
                cols="30"
                rows="10"
              ></textarea>
              <div v-if="errors.expired" class="text-sm text-red-600">
                * {{ errors.expired }}
              </div>
            </div>
            <div class="col-span-2">
              <toggle-button
                :value="config.notifications_renew_enabled"
                :enabled_name="$t('notifications.config.renew_enabled')"
                :disabled_name="$t('notifications.config.renew_enabled')"
                @toggle="
                  config.notifications_renew_enabled = !config.notifications_renew_enabled
                "
              />
            </div>
            <div
              class="col-span-2 h-32 flex flex-col items-center justify-center"
            >
              <label class="font-bold">{{
                $t("notifications.config.renew_body")
              }}</label>
              <textarea
                class="bg-white rounded-3xl w-full h-24 shadow-lg p-1 pl-3 focus:outline-none"
                :style="text_direction"
                @input="validate"
                v-model="config.notifications_renew_body"
                :disabled="!config.notifications_renew_enabled"
                :class="{
                  'opacity-50': !config.notifications_renew_enabled,
                  'shadow-error': errors.renew,
                }"
                cols="30"
                rows="10"
              ></textarea>
              <div v-if="errors.renew" class="text-sm text-red-600">
                * {{ errors.renew }}
              </div>
            </div>
            <div class="col-span-2">
              <button
                @click.prevent="store"
                class="w-full bg-green-500 text-white rounded-full  py-1 px-3"
                :class="{
                  'bg-opacity-50 cursor-not-allowed': !validated,
                }"
                :disabled="!validated"
              >
                {{ $t("profiles.save") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <expired-window v-else />
  </div>
</template>

<script>
import axios from "axios";
import NumericalSpinner from "../components/formElements/NumericalSpinner.vue";
import ToggleButton from "../components/formElements/ToggleButton.vue";
import Dropdown from "../components/formElements/Dropdown.vue";
import LoadingOverlay from "../components/modals/LoadingOverlay.vue";
import Datepicker from "vue3-date-time-picker";
import ExpiredWindow from "../components/ExpiredWindow.vue";
export default {
  components: {
    NumericalSpinner,
    ToggleButton,
    Dropdown,
    LoadingOverlay,
    Datepicker,
    ExpiredWindow,
  },
  name: "NotificationsConfig",
  async created() {
    this.loading = true;
    const response = await axios.get("notifications/config");
    if (response.status == 200) {
      var temp = response.data.data;
      temp.forEach((element) => {
        switch (element.key) {
          case "notifications_expiring_enabled":
            this.config.notifications_expiring_enabled = element.value == 1;
            break;
          case "notifications_expiring_body":
            this.config.notifications_expiring_body = element.value;
            break;
          case "notifications_expired_enabled":
            this.config.notifications_expired_enabled = element.value == 1;
            break;
          case "notifications_expired_body":
            this.config.notifications_expired_body = element.value;
            break;
          case "notifications_renew_enabled":
            this.config.notifications_renew_enabled = element.value == 1;
            break;
          case "notifications_renew_body":
            this.config.notifications_renew_body = element.value;
            break;
          default:
            break;
        }
      });
    }
    this.loading = false;
    this.validate();
  },
  data() {
    return {
      config: {
        notifications_expiring_enabled: false,
        notifications_expiring_body: "",
        notifications_expired_enabled: false,
        notifications_expired_body: "",
        notifications_renew_enabled: false,
        notifications_renew_body: "",
      },
      loading: false,
      errors: {
        expiring: null,
        expired: null,
        renew: null,
      },
      validated: true,
      text_direction:
        this.$i18n.locale == "ar" ? "direction:rtl" : "direction:ltr",
    };
  },
  methods: {
    async store() {
      this.loading = true;
      const response = await axios.post("notifications/config", this.config);
      if (response.status == 200) {
        this.$store.commit("changeKey");
        this.$router.push({
          path: "/dashboard",
          query: { success: "success" },
        });
      }
      this.loading = false;
    },
    validate() {
      this.validated = true;
      this.errors.expiring = null;
      this.errors.expired = null;
      this.errors.renew = null;
      if (this.config.notifications_expiring_body == "") {
        this.errors.expiring = this.$t("errors.notifications.empty");
        this.validated = false;
      }
      if (this.config.notifications_expired_body == "") {
        this.errors.expired = this.$t("errors.notifications.empty");
        this.validated = false;
      }
      if (this.config.notifications_renew_body == "") {
        this.errors.renew = this.$t("errors.notifications.empty");
        this.validated = false;
      }
    },
  },
};
</script>
