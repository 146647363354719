<template>
  <div
    v-if="visible"
    class="fixed z-40 inset-0 overflow-y-auto"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <div
      class="
        flex
        items-end
        justify-center
        min-h-screen
        pt-4
        px-4
        pb-20
        text-center
        sm:block sm:p-0
      "
      v-if="this.$store.getters.settings.license.active"
    >
      <div
        @click.prevent="hide"
        class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        aria-hidden="true"
      ></div>
      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
        >&#8203;</span
      >

      <div
        v-if="!loading"
        class="
          inline-block
          align-bottom
          md:align-middle
          bg-gray-300
          rounded-3xl
          text-left
          overflow-hidden
          shadow-xl
          transform
          transition-all
          my-24
          sm:my-8
          max-w-5xl
          w-full
        "
      >
        <div class="flex flex-col justify-center items-center">
          <div class="bg-ssr-side_bar-background flex w-full justify-between">
            <div class="md:text-2xl text-white font-bold py-2 pl-6">
              {{ $t("iptv.add") }}
            </div>
            <div
              @click.prevent="hide"
              class="
                rounded-full
                cursor-pointer
                bg-ssr-side_bar-button_hover
                text-white text-xs
                my-2
                mr-6
                w-28
                flex
                justify-center
                items-center
                font-bold
              "
            >
              {{ $t("accounts.cancel") }}
            </div>
          </div>
          <div
            class="
              flex flex-col
              w-full
              md:w-1/2
              items-center
              justify-center
              bg-gray-300
            "
          >
            <label class="font-bold mt-6">{{ $t("iptv.name") }}</label>
            <input
              v-model="channel.name"
              @input="validate"
              type="text"
              class="
                w-full
                text-2xl
                border-b border-black
                text-center
                p-1
                pl-3
                focus:outline-none
              "
            />
            <label class="font-bold mt-6">{{ $t("iptv.logo") }}</label>
            <div class="grid grid-cols-2">
              <div>
                <input
                  type="file"
                  class="
                    w-full
                    border-b border-black
                    text-center
                    p-1
                    pl-3
                    focus:outline-none
                  "
                  accept="image/*"
                  @change="selectImage"
                  ref="imageFile"
                />
              </div>
              <div class="flex w-full justify-center">
                <img
                  v-if="currentImageUrl"
                  class="h-16"
                  :src="currentImageUrl"
                  alt="Logo Image"
                />
              </div>
            </div>
            <label class="font-bold mt-6">{{ $t("iptv.url") }}</label>
            <input
              v-model="channel.url"
              @input="validate"
              type="text"
              class="
                w-full
                text-lg
                border-b border-black
                text-center
                p-1
                pl-3
                focus:outline-none
              "
            />
          </div>
          <div class="text-sm text-red-500 mt-6" v-if="!validated">
            <p v-if="errors.name">*{{ errors.name }}</p>
            <p v-if="errors.url">*{{ errors.url }}</p>
          </div>
          <div
            class="
              my-2
              gap-x-2
              w-full
              md:w-1/3
              items-center
              justify-center
              bg-gray-300
              text-white
            "
          >
            <button
              @click.prevent="store"
              class="flex justify-center bg-green-500 rounded-full py-1 mt-2 w-full"
              :class="{ disabled: !validated }"
            >
              {{ $t("accounts.add") }}
            </button>
          </div>
        </div>
      </div>
      <div
        class="
          inline-block
          align-bottom
          bg-gray-300
          rounded-3xl
          text-left
          overflow-hidden
          shadow-xl
          transform
          transition-all
          my-24
          sm:my-8 sm:align-middle
        "
        v-if="loading"
      >
        <div class="flex flex-col items-center">
          <img
            class="inline-block h-48 w-48"
            src="@/assets/loading.gif"
            alt="Loading"
          />
        </div>
      </div>
    </div>
    <ExpiredWindow v-else />
  </div>
</template>

<script>
import moment, { months } from "moment";
import { hasPrivilege } from "../../global_constants.js";
import { mapGetters } from "vuex";
import axios from "axios";
import NumericalSpinner from "../formElements/NumericalSpinner.vue";
import Datepicker from "vue3-date-time-picker";
import LoadingOverlay from "../modals/LoadingOverlay.vue";
import ExpiredWindow from "../ExpiredWindow.vue";
import ToggleButton from "../formElements/ToggleButton.vue";
export default {
  components: {
    NumericalSpinner,
    Datepicker,
    ExpiredWindow,
    ToggleButton,
  },
  name: "AddChannel",
  props: ["visible"],
  computed: {
    ...mapGetters(["user", "settings"]),
  },
  data() {
    return {
      loading: true,
      validated: true,
      paid: "0",
      notes: "",
      channel: {
        name: "",
        logo: null,
        url: "",
      },
      currentImageUrl: null,
      errors: {
        name: null,
        url: null,
      },
    };
  },
  async created() {
    this.validate();
    this.loading = false;
  },
  methods: {
    hide() {
      this.$emit("hide-details");
    },
    formatDate(date) {
      return moment(date).format("MMMM Do YYYY, h:mm a");
    },
    hasPrivilege(privilege) {
      return hasPrivilege(this.user, privilege);
    },
    async store() {
      this.loading = true;
      var data = new FormData();
      data.append("logo", this.channel.logo);
      data.append("name", this.channel.name);
      data.append("url", this.channel.url);
      const response = await axios.post("iptv", data, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      if (response.status == 200) {
        this.$router.push({
          path: "/iptv",
          query: { success: "success" },
        });
        this.$emit("updated");
      }
      this.loading = false;
    },
    selectImage() {
      this.channel.logo = this.$refs.imageFile.files.item(0);
      this.currentImageUrl = URL.createObjectURL(this.channel.logo);
    },
    validate() {
      this.validated = true;
      this.errors.name = null;
      this.errors.url = null;
      if (this.channel.name == "") {
        this.validated = false;
        this.errors.name = this.$t("errors.iptv.name_empty");
      }
      if (this.channel.url == "") {
        this.validated = false;
        this.errors.url = this.$t("errors.iptv.url_empty");
      }
    },
  },
};
</script>
