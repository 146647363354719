<template>
  <div
    class="flex flex-row justify-center items-center text-center text-black flex-1"
  >
    <!-- <div class="box gauge--3 relative z-0">
      <div class="mask">
        <div class="semi-circle"></div>
        <div class="semi-circle--mask" :style="rotate"></div>
      </div>
      <div class="font-bold text-4xl absolute right-1/3 top-1/2  z-10">
        {{ value }}%
      </div>
    </div> -->
    <Doughnut
      :chart-options="chartOptions"
      :chart-data="chartData"
      :chart-id="chartId"
      :dataset-id-key="datasetIdKey"
      :plugins="plugins"
      :css-classes="cssClasses"
      :styles="styles"
    />
  </div>
</template>

<script>
import { Doughnut } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  PluginOptionsByType,
} from "chart.js";
import { defineComponent, h, PropType } from "vue";
ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);
export default {
  name: "OnlineChart",
  components: { Doughnut },
  props: {
    chartId: {
      type: String,
      default: "bar-chart",
    },
    datasetIdKey: {
      type: String,
      default: "label",
    },
    width: {
      type: Number,
      default: 300,
    },
    height: {
      type: Number,
      default: 32,
    },
    cssClasses: {
      default: "",
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    plugins: {
      type: Object,
      default: () => {},
    },
    online: {
      type: Number,
      default: 1,
    },
    remaining: {
      type: Number,
      default: 10,
    },
  },
  data() {
    return {
      rotationVal: 90,
      chartData: {
        labels: [this.$t("license.online"), this.$t("license.remaining")],
        datasets: [
          {
            data: [this.online, this.remaining],
            backgroundColor: ["#EC255A", "#FFE3EA"],
          },
        ],
      },
      chartOptions: {
        responsive: true,
        rotation: -90,
        circumference: 180,
        plugins: {
          legend: {
            position: "top",
          },
        },
      },
    };
  },
  created() {},
  methods: {
    fetchSessions() {},
  },
};
</script>
