<template>
  <div
    v-if="visible"
    class="fixed z-40 inset-0 overflow-y-auto"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <div
      class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      v-if="this.$store.getters.settings.license.active"
    >
      <div
        @click.prevent="hide"
        class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        aria-hidden="true"
      ></div>
      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
        >&#8203;</span
      >

      <div
        v-if="!loading"
        class="inline-block bg-gray-300 rounded-3xl text-left overflow-hidden shadow-xl transform transition-all my-24 align-middle max-w-5xl w-full"
      >
        <div class="flex flex-col justify-center items-center">
          <div class="bg-ssr-side_bar-background flex w-full justify-between">
            <div class="md:text-2xl text-white font-bold py-2 pl-6">
              {{ $t("online.truncate.title") }}
            </div>

            <div
              @click.prevent="hide"
              class="rounded-full cursor-pointer bg-ssr-side_bar-button_hover text-white text-xs my-2 mr-6 w-28 flex justify-center items-center font-bold"
            >
              {{ $t("accounts.cancel") }}
            </div>
          </div>
          <div
            class="flex flex-col w-full md:w-1/3 items-center justify-center bg-gray-300 py-12"
          >
            <div>{{ $t("online.truncate.end_date") }}</div>
            <VueDatepicker
              v-model="truncate.end_date"
              :locale="$i18n.locale"
              :format="format"
              :enableTimePicker="false"
              :auto-apply="true"
              @update:model-value="validate"
            />
            <div class="w-2/3 my-6 gap-y-2 flex flex-col items-center">
              <div>{{ $t("online.truncate.only") }}</div>
              <toggle-button
                :value="truncate.only == 'all'"
                :enabled_name="$t('online.truncate.all')"
                :disabled_name="$t('online.truncate.all')"
                @toggle="truncate.only = 'all'"
              />
              <toggle-button
                :value="truncate.only == 'cards'"
                :enabled_name="$t('online.truncate.cards_only')"
                :disabled_name="$t('online.truncate.cards_only')"
                @toggle="truncate.only = 'cards'"
              />
              <toggle-button
                :value="truncate.only == 'accounts'"
                :enabled_name="$t('online.truncate.accounts_only')"
                :disabled_name="$t('online.truncate.accounts_only')"
                @toggle="truncate.only = 'accounts'"
              />
            </div>
            <div
              v-if="truncate.only != 'accounts'"
              class="col-span-2 flex justify-center text-center text-2xl text-yellow-700"
            >
              {{ $t("online.truncate.warning_cards") }}
            </div>

            <button
              v-if="!confirmation"
              @click.prevent="confirmation = true"
              class="bg-green-500 text-white rounded-full w-full py-1 px-3 mt-6"
              :class="{ 'bg-opacity-50 cursor-default': !validated }"
              :disabled="!validated"
            >
              {{ $t("online.truncate.truncate") }}
            </button>
            <div v-if="errors.end_date" class="text-red-600">
              {{ errors.end_date }}
            </div>
          </div>
          <div
            v-if="confirmation && validated"
            class="grid grid-cols-2 mb-6 gap-x-2 w-full md:w-1/3 items-center justify-center bg-gray-300 text-white"
          >
            <div
              class="col-span-2 flex justify-center text-black text-center text-2xl"
            >
              {{ $t("online.truncate.confirm") }}
            </div>
            <button
              @click.prevent="confirmation = false"
              class="flex justify-center bg-red-500 rounded-full py-1 mt-2"
            >
              {{ $t("control.no") }}
            </button>
            <button
              @click.prevent="store"
              class="flex justify-center bg-green-500 rounded-full py-1 mt-2"
            >
              {{ $t("control.yes") }}
            </button>
          </div>
        </div>
      </div>
      <div
        class="inline-block align-bottom bg-gray-300 rounded-3xl text-left overflow-hidden shadow-xl transform transition-all my-24 sm:my-8 sm:align-middle"
        v-if="loading"
      >
        <div class="flex flex-col items-center">
          <img
            class="inline-block h-48 w-48"
            src="@/assets/loading.gif"
            alt="Loading"
          />
        </div>
      </div>
    </div>
    <ExpiredWindow v-else />
  </div>
</template>

<script>
import moment, { months } from "moment";
import { hasPrivilege } from "../../global_constants.js";
import { mapGetters } from "vuex";
import axios from "axios";
import NumericalSpinner from "../formElements/NumericalSpinner.vue";
// import Datepicker from "vue3-date-time-picker";
import LoadingOverlay from "../modals/LoadingOverlay.vue";
import ExpiredWindow from "../ExpiredWindow.vue";
import ToggleButton from "../formElements/ToggleButton.vue";
import VueDatepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
export default {
  components: {
    NumericalSpinner,
    ExpiredWindow,
    ToggleButton,
    VueDatepicker,
  },
  name: "SessionsTruncate",
  props: ["visible"],
  computed: {
    ...mapGetters(["user", "settings"]),
  },
  data() {
    return {
      confirmation: false,
      loading: false,
      truncate: {
        only: "all",
        confirmation: true,
        end_date: new Date(),
      },
      errors: {
        end_date: null,
      },
      date_3months_prior: new Date(),
      format: (date) => {
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        const hour = date.getHours();
        const minute = date.getMinutes();

        return `${day}-${month}-${year}`;
      },
      validated: true,
    };
  },
  async created() {
    this.truncate.end_date.setMonth(this.truncate.end_date.getMonth() - 3);
    this.date_3months_prior.setMonth(this.date_3months_prior.getMonth() - 3);
  },
  methods: {
    hide() {
      this.$emit("hide-details");
    },
    formatDate(date) {
      return moment(date).format("MMMM Do YYYY, h:mm a");
    },
    hasPrivilege(privilege) {
      return hasPrivilege(this.user, privilege);
    },
    async store() {
      if (this.loading) return;
      this.loading = true;
      this.truncate.end_date = moment(this.truncate.end_date).format(
        "yyyy-MM-DD"
      );
      const response = await axios.post(`/sessions/truncate`, this.truncate);
      if (response.status == 200) {
        this.$emit("updated");
        this.loading = false;
      }
      this.loading = false;
    },
    validate() {
      this.validated = true;
      this.errors.end_date = null;
      if (this.truncate.end_date > this.date_3months_prior) {
        this.errors.end_date = this.$t("online.truncate.before_3months");
        this.validated = false;
      }
    },
  },
};
</script>
