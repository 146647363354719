<template>
  <div class="flex flex-col md:flex-row">
    <div class="flex flex-col flex-1 items-center p-12 md:p-0 md:pl-12">
      <label class="font-bold mt-2">Account Type</label>
      <toggle-button
        :name="'Prepaid'"
        @click="setAccountType(0)"
        :value="accountType.prepaid"
        class="w-full flex justify-start mt-2"
      />
      <toggle-button
        :name="'Postpaid'"
        @click="setAccountType(1)"
        :value="accountType.postpaid"
        class="w-full flex justify-start mt-2"
      />
      <toggle-button
        :name="'Monthly Subscription'"
        @click="setAccountType(2)"
        :value="accountType.monthly"
        class="w-full flex justify-start mt-2"
      />
    </div>
    <div class="flex flex-col flex-1 items-center md:items-start  p-12 md:p-0">
      <label class="font-bold mt-2">Duration</label>
      <toggle-button
        :name="'Weekly'"
        @click="setDurationType(0)"
        :value="durationType.weekly"
        class="w-full flex justify-start mt-2"
      />
      <toggle-button
        :name="'Monthly'"
        @click="setDurationType(1)"
        :value="durationType.monthly"
        class="w-full flex justify-start mt-2"
      />
      <toggle-button
        :name="'Yearly'"
        @click="setDurationType(2)"
        :value="durationType.yearly"
        class="w-full flex justify-start mt-2"
      />
      <toggle-button
        :name="'Hours'"
        @click="setDurationType(3)"
        :value="durationType.hours"
        class="w-full flex justify-start mt-2"
      />
    </div>
    <div class="flex flex-col flex-1 items-center md:items-start  p-12 md:p-0">
      <input-field
        v-if="durationType.hours"
        :name="'Hours'"
        class="w-full md:w-1/2 mt-2"
      />
    </div>
  </div>
</template>

<script>
import InputField from "../formElements/InputField.vue";
import ToggleButton from "../formElements/ToggleButton.vue";
export default {
  components: { InputField, ToggleButton },
  name: "Page1",
  data() {
    return {
      accountType: {
        prepaid: false,
        postpaid: false,
        monthly: false,
      },
      durationType: {
        weekly: false,
        monthly: false,
        yearly: false,
        hours: false,
      },
    };
  },
  methods: {
    setAccountType(val) {
      switch (val) {
        case 0:
          this.accountType.prepaid = true;
          this.accountType.postpaid = false;
          this.accountType.monthly = false;
          break;
        case 1:
          this.accountType.prepaid = false;
          this.accountType.postpaid = true;
          this.accountType.monthly = false;
          break;
        case 2:
          this.accountType.prepaid = false;
          this.accountType.postpaid = false;
          this.accountType.monthly = true;
          break;

        default:
          break;
      }
    },
    setDurationType(val) {
      switch (val) {
        case 0:
          this.durationType.weekly = true;
          this.durationType.monthly = false;
          this.durationType.yearly = false;
          this.durationType.hours = false;
          break;
        case 1:
          this.durationType.weekly = false;
          this.durationType.monthly = true;
          this.durationType.yearly = false;
          this.durationType.hours = false;
          break;
        case 2:
          this.durationType.weekly = false;
          this.durationType.monthly = false;
          this.durationType.yearly = true;
          this.durationType.hours = false;
          break;
        case 3:
          this.durationType.weekly = false;
          this.durationType.monthly = false;
          this.durationType.yearly = false;
          this.durationType.hours = true;
          break;

        default:
          break;
      }
    },
  },
};
</script>
