<template>
  <svg viewBox="0 0 69 57" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="path-1-inside-1_1141_9001" fill="white">
      <path
        d="M48.8889 55.9993V49.8882C48.8889 46.6467 47.6012 43.5379 45.3091 41.2458C43.0169 38.9537 39.9082 37.666 36.6666 37.666H12.2222C8.98068 37.666 5.87191 38.9537 3.5798 41.2458C1.28769 43.5379 0 46.6467 0 49.8882V55.9993"
      />
    </mask>
    <path
      d="M48.8889 55.9993V49.8882C48.8889 46.6467 47.6012 43.5379 45.3091 41.2458C43.0169 38.9537 39.9082 37.666 36.6666 37.666H12.2222C8.98068 37.666 5.87191 38.9537 3.5798 41.2458C1.28769 43.5379 0 46.6467 0 49.8882V55.9993"
      fill="currentColor"
    />
    <path
      d="M46.8889 55.9993C46.8889 57.1039 47.7843 57.9993 48.8889 57.9993C49.9934 57.9993 50.8889 57.1039 50.8889 55.9993H46.8889ZM36.6666 37.666V35.666V37.666ZM12.2222 37.666V35.666V37.666ZM0 49.8882H-2H0ZM-2 55.9993C-2 57.1039 -1.10457 57.9993 0 57.9993C1.10457 57.9993 2 57.1039 2 55.9993H-2ZM50.8889 55.9993V49.8882H46.8889V55.9993H50.8889ZM50.8889 49.8882C50.8889 46.1163 49.3904 42.4988 46.7233 39.8316L43.8948 42.66C45.8119 44.5771 46.8889 47.1771 46.8889 49.8882H50.8889ZM46.7233 39.8316C44.0561 37.1644 40.4386 35.666 36.6666 35.666V39.666C39.3777 39.666 41.9778 40.743 43.8948 42.66L46.7233 39.8316ZM36.6666 35.666H12.2222V39.666H36.6666V35.666ZM12.2222 35.666C8.45025 35.666 4.83277 37.1644 2.16559 39.8316L4.99402 42.66C6.91105 40.743 9.51112 39.666 12.2222 39.666V35.666ZM2.16559 39.8316C-0.501592 42.4988 -2 46.1163 -2 49.8882H2C2 47.1771 3.07698 44.5771 4.99402 42.66L2.16559 39.8316ZM-2 49.8882V55.9993H2V49.8882H-2Z"
      fill="currentColor"
      mask="url(#path-1-inside-1_1141_9001)"
    />
    <path
      d="M35.6671 13.2222C35.6671 19.4201 30.6427 24.4444 24.4449 24.4444C18.247 24.4444 13.2227 19.4201 13.2227 13.2222C13.2227 7.02436 18.247 2 24.4449 2C30.6427 2 35.6671 7.02436 35.6671 13.2222Z"
      fill="currentColor"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M67.2213 56.0001V49.889C67.2193 47.1809 66.318 44.5502 64.6589 42.4099C62.9997 40.2696 60.6767 38.741 58.0547 38.064"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M45.832 1.39648C48.4611 2.06963 50.7913 3.59862 52.4554 5.74243C54.1194 7.88623 55.0226 10.5229 55.0226 13.2368C55.0226 15.9506 54.1194 18.5873 52.4554 20.7311C50.7913 22.8749 48.4611 24.4039 45.832 25.077"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "AccountsIcon",
};
</script>
