<template>
  <div>
    <table class="table-auto w-full">
      <thead class="bg-ssr-side_bar-settings text-white font-bold">
        <tr>
          <th>#</th>
          <th>{{ $t("profiles.profile") }}</th>
          <th>{{ $t("profiles.total_accounts") }}</th>
          <th>{{ $t("profiles.online_accounts") }}</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(profile, index) in profiles"
          :key="profile.id"
          class="text-base text-center hover:bg-blue-200 cursor-pointer"
          :class="{
            'bg-gray-200': index % 2 == 0,
          }"
          @click="
            this.$store.commit('changeKey');
            this.$router.push(`/profiles?name=${profile.name}`);
          "
        >
          <td class="font-bold">
            {{ index + 1 + (meta.current_page - 1) * meta.per_page }}
          </td>
          <td>{{ profile.name }}</td>
          <td>
            <div
              class="px-3 p-1 bg-blue-400 text-white text-center text-xs rounded-full inline-block cursor-pointer"
            >
              {{ profile.total_accounts }}
            </div>
          </td>
          <td>
            <div
              class="px-3 p-1 bg-green-400 text-white text-center text-xs rounded-full inline-block"
            >
              {{ profile.online_accounts }}
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div v-if="loading" class="w-full flex justify-center">
      <img
        class=" inline-block h-12 w-12"
        src="@/assets/loading.gif"
        alt="Loading"
      />
    </div>
    <pagination-row
      class="mt-2"
      @triggerupdate="fetchDataPortion"
      :meta="meta"
      :small="true"
    />
  </div>
</template>
<script>
import axios from "axios";
import PaginationRow from "../PaginationRow.vue";
export default {
  components: { PaginationRow },
  name: "ProfilesTable",
  data() {
    return {
      profiles: null,
      meta: { last_page: 0, current_page: 1 },
      filters: {
        rows: 5,
        page: 1,
      },
      loading: false,
    };
  },
  async created() {
    await this.fetchProfiles();
  },
  methods: {
    async fetchProfiles() {
      this.loading = true;
      const response = await axios.get(
        `profiles?rows=${this.filters.rows}&page=${this.filters.page}`
      );
      this.profiles = response.data.data;
      this.meta = response.data.meta;
      this.loading = false;
    },
    async fetchDataPortion(rows, currentPage) {
      this.filters.rows = 5;
      this.filters.page = currentPage;
      this.fetchProfiles();
    },
  },
};
</script>
