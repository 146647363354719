<template>
  <div
    v-if="visible"
    class="fixed z-40 inset-0 overflow-y-auto"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <div
      class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      v-if="this.$store.getters.settings.license.active"
    >
      <div
        @click.prevent="this.$emit('hide')"
        class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        aria-hidden="true"
      ></div>
      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
        >&#8203;</span
      >
      <div
        v-if="!loading"
        class="inline-block align-bottom bg-gray-300 rounded-3xl text-left overflow-hidden shadow-xl transform transition-all my-24 sm:my-8 sm:align-middle sm:max-w-5xl sm:w-full"
      >
        <div class="flex flex-col justify-center items-center">
          <div class=" bg-ssr-side_bar-background flex w-full justify-between">
            <div class="md:text-2xl text-white font-bold py-2 pl-6">
              {{ $t("notifications.new_notification") }}
            </div>

            <div
              @click.prevent="this.$emit('hide')"
              class="rounded-full cursor-pointer bg-ssr-side_bar-button_hover text-white text-xs my-2 mr-6 w-28 flex justify-center items-center font-bold"
            >
              {{ $t("accounts.cancel") }}
            </div>
          </div>
          <div
            class="flex flex-col w-full md:w-1/3 items-center justify-center bg-gray-300"
            :style="text_direction"
          >
            <label class="font-bold mt-2">{{
              $t("notifications.type.type")
            }}</label>
            <select
              @change="changeType"
              v-model="notification.type"
              class="bg-white rounded-full w-full shadow-lg p-1 pl-3 focus:outline-none text-center"
            >
              <option
                v-for="ft in [
                  { id: 'account', name: $t('notifications.type.account') },
                  { id: 'profile', name: $t('notifications.type.profile') },
                  { id: 'nas', name: $t('notifications.type.nas') },
                  { id: 'ssr', name: $t('notifications.type.ssr') },
                ]"
                v-bind:key="ft.id"
                :value="ft.id"
                >{{ ft.name }}</option
              >
            </select>
            <div
              class="w-full flex flex-col justify-center items-center mt-2"
              v-if="notification.type == 'account'"
              :class="{ 'text-red-500': errors.id }"
            >
              <label class="font-bold">{{
                $t("notifications.type.account")
              }}</label>
              <MultiSelect
                class="p-1 h-9 rounded-full shadow-lg"
                @select="validate"
                v-model="this.notification.account_id"
                :searchable="true"
                :placeholder="$t('control.filter')"
                :options="accounts"
                :internal-search="false"
                open-direction="top"
                @search-change="fetchAccounts"
                :loading="int_loading"
                :classes="{
                  search:
                    'w-full absolute rounded rounded-full inset-0 outline-none h-full focus:ring-0 appearance-none text-base font-sans bg-white pl-3.5',
                  container:
                    'relative mx-auto w-full flex items-center justify-start cursor-pointer border border-gray-300 rounded bg-black rounded-full text-base leading-snug outline-none',
                  tag:
                    'bg-green-500 text-white text-sm font-semibold py-0.5 pl-2 rounded rounded-full mr-1 mb-1 flex items-center justify-center w-full whitespace-nowrap',
                }"
              />
            </div>

            <div
              class="w-full flex flex-col justify-center items-center mt-2"
              v-if="notification.type == 'profile'"
              :class="{ 'text-red-500': errors.id }"
            >
              <label class="font-bold">{{
                $t("notifications.type.profile")
              }}</label>
              <MultiSelect
                class="p-1 h-9 rounded-full shadow-lg"
                @select="validate"
                v-model="this.notification.profile_id"
                :searchable="true"
                :placeholder="$t('control.filter')"
                :options="profiles"
                :internal-search="false"
                open-direction="top"
                @search-change="fetchProfiles"
                :loading="int_loading"
                :classes="{
                  search:
                    'w-full absolute rounded rounded-full inset-0 outline-none focus:ring-0 appearance-none box-border border-0 text-base font-sans bg-white pl-3.5',
                  container:
                    'relative mx-auto w-full flex items-center justify-start box-border cursor-pointer border border-gray-300 rounded bg-black rounded-full text-base leading-snug outline-none',
                  tag:
                    'bg-green-500 text-white text-sm font-semibold py-0.5 pl-2 rounded rounded-full mr-1 mb-1 flex items-center justify-center w-full whitespace-nowrap',
                }"
              />
            </div>

            <div
              class="w-full flex flex-col justify-center items-center mt-2"
              v-if="notification.type == 'nas'"
              :class="{ 'text-red-500': errors.id }"
            >
              <label class="font-bold">{{
                $t("notifications.type.nas")
              }}</label>
              <MultiSelect
                class="p-1 h-9 rounded-full shadow-lg"
                @select="validate"
                v-model="this.notification.nas_id"
                :searchable="true"
                :placeholder="$t('control.filter')"
                :options="nases"
                :internal-search="false"
                open-direction="top"
                @search-change="fetchNases"
                :loading="int_loading"
                :classes="{
                  search:
                    'w-full absolute rounded rounded-full inset-0 outline-none focus:ring-0 appearance-none box-border border-0 text-base font-sans bg-white pl-3.5',
                  container:
                    'relative mx-auto w-full flex items-center justify-start box-border cursor-pointer border border-gray-300 rounded bg-black rounded-full text-base leading-snug outline-none',
                  tag:
                    'bg-green-500 text-white text-sm font-semibold py-0.5 pl-2 rounded rounded-full mr-1 mb-1 flex items-center justify-center w-full whitespace-nowrap',
                }"
              />
            </div>

            <label class="font-bold mt-2">{{
              $t("notifications.title")
            }}</label>
            <input
              v-model="notification.title"
              @input="validate"
              type="text"
              class="bg-white rounded-full w-full shadow-lg p-1 pl-3 focus:outline-none"
              :class="{ 'shadow-error': errors.title }"
            />
            <label class="font-bold mt-2">{{ $t("notifications.body") }}</label>
            <textarea
              class="bg-white rounded-3xl w-full h-24 shadow-lg p-1 pl-3 focus:outline-none"
              @input="validate"
              v-model="notification.body"
              :class="{
                'shadow-error': errors.body,
              }"
              cols="30"
              rows="10"
            ></textarea>
            <div v-if="!validated" class="text-sm text-red-600 my-2">
              * {{ $t("notifications.error") }}
            </div>
            <button
              v-if="!confirmation"
              @click.prevent="confirmation = true"
              :disabled="!validated"
              class="bg-green-500 text-white  rounded-full w-full py-1 px-3 my-6"
              :class="{ 'opacity-50 cursor-default': !validated }"
            >
              {{ $t("notifications.push") }}
            </button>
          </div>
          <div
            v-if="confirmation && !loading"
            class="grid grid-cols-2 my-4 gap-x-2 w-full md:w-1/3 items-center justify-center bg-gray-300 text-white"
          >
            <div class="col-span-2 mb-2 flex justify-center text-black">
              {{ $t("notifications.confirm") }}
            </div>
            <button
              @click.prevent="confirmation = false"
              class="flex justify-center bg-red-500 rounded-full py-1"
            >
              {{ $t("control.no") }}
            </button>
            <button
              @click.prevent="store"
              class="flex justify-center bg-green-500 rounded-full py-1"
            >
              {{ $t("control.yes") }}
            </button>
          </div>
          <div v-if="loading" class="w-full flex justify-center">
            <img
              class=" inline-block h-12 w-12"
              src="@/assets/loading.gif"
              alt="Loading"
            />
          </div>
        </div>
      </div>
      <div
        class="inline-block align-bottom bg-gray-300 rounded-3xl text-left overflow-hidden shadow-xl transform transition-all my-24 sm:my-8 sm:align-middle"
        v-if="loading"
      >
        <div class="flex flex-col items-center">
          <img
            class="inline-block h-48 w-48"
            src="@/assets/loading.gif"
            alt="Loading"
          />
        </div>
      </div>
    </div>
    <ExpiredWindow v-else />
  </div>
</template>

<script>
import moment, { months } from "moment";
import { hasPrivilege } from "../../global_constants.js";
import { mapGetters } from "vuex";
import axios from "axios";
import NumericalSpinner from "../formElements/NumericalSpinner.vue";
import MultiSelect from "@vueform/multiselect";
import ExpiredWindow from "../ExpiredWindow.vue";
export default {
  components: {
    NumericalSpinner,
    MultiSelect,
    ExpiredWindow,
  },
  name: "PushNotification",
  props: ["visible"],
  computed: {
    ...mapGetters(["user"]),
  },
  data() {
    return {
      profiles: [],
      accounts: [],
      nases: [],
      confirmation: false,
      loading: false,
      notification: {
        title: "",
        body: "",
        type: "account",
        account_id: null,
        profile_id: null,
        nas_id: null,
      },
      errors: {
        title: null,
        body: null,
        id: null,
      },
      validated: false,
      text_direction:
        this.$i18n.locale == "ar" ? "direction:rtl" : "direction:ltr",
      int_loading: false,
      debounce: null,
    };
  },
  async created() {
    await this.fetchProfiles();
    this.loading = false;
  },
  methods: {
    hide() {
      this.$emit("hide-details");
    },
    formatDate(date) {
      return moment(date).format("MMMM Do YYYY, h:mm a");
    },
    hasPrivilege(privilege) {
      return hasPrivilege(this.user, privilege);
    },
    async store() {
      this.loading = true;
      const response = await axios.post(`/notifications`, this.notification);
      if (response.status == 200) {
        this.$router.push({
          path: "/services/notifications",
          query: { success: "success" },
        });
        this.loading = false;
        this.$emit("updated");
      }
    },
    async fetchProfiles(query) {
      this.int_loading = true;
      const response = await axios.get(`/profiles?name=${query}`);
      if (response.status == 200) {
        if (!this.notification.profile_id) this.profiles = [];
        const profiles = response.data.data;
        profiles.forEach((element) => {
          this.profiles.push({ value: element.id, label: element.name });
        });
      }
      this.int_loading = false;
    },
    async fetchAccounts(query) {
      this.int_loading = true;
      const response = await axios.get(`/accounts?username=${query}`);
      if (response.status == 200) {
        if (!this.notification.account_id) this.accounts = [];
        const accounts = response.data.data;
        accounts.forEach((element) => {
          this.accounts.push({ value: element.id, label: element.username });
        });
      }
      this.int_loading = false;
    },
    async fetchNases(query) {
      this.int_loading = true;
      const response = await axios.get(`/nases?itr=true&shortname=${query}`);
      if (response.status == 200) {
        if (!this.notification.nas_id) this.nases = [];
        const nases = response.data.data;
        nases.forEach((element) => {
          this.nases.push({ value: element.id, label: element.shortname });
        });
      }
      this.int_loading = false;
    },
    changeType() {},
    validate() {
      this.validated = true;
      this.errors.title = null;
      this.errors.body = null;
      this.errors.id = null;
      if (this.notification.title == "") {
        this.errors.title = true;
        this.validated = false;
      }
      if (this.notification.body == "") {
        this.errors.body = true;
        this.validated = false;
      }
      if (
        this.notification.type == "account" &&
        this.notification.account_id == null
      ) {
        this.errors.id = true;
        this.validated = false;
      }
      if (
        this.notification.type == "profile" &&
        this.notification.profile_id == null
      ) {
        this.errors.id = true;
        this.validated = false;
      }
      if (this.notification.type == "nas" && this.notification.nas_id == null) {
        this.errors.id = true;
        this.validated = false;
      }
    },
  },
};
</script>
