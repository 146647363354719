<template>
  <div
    class="main-content flex-1 justify-center items-center bg-gray-100 h-screen pb-24 md:pb-5"
  >
    <div class="flex flex-wrap justify-center items-center my-auto h-full">
      <div class="w-full flex flex-col justify-center items-center">
        <img
          class="my-auto mx-auto w-1/2"
          src="@/assets/logo_full2.svg"
          alt="Logo image"
        />
        <img
          class="inline-block w-1/6"
          src="@/assets/loading.gif"
          alt="Loading"
        />
        <!-- <MultiSelect
          :mode="'tags'"
          :placeholder="'This is Test'"
          :options="[1, 2, 3]"
        /> -->
      </div>
    </div>
  </div>
</template>

<script>
import MultiSelect from "@vueform/multiselect";
import { mapGetters } from "vuex";
export default {
  name: "Home",
  components: {
    MultiSelect,
  },
  computed: {
    ...mapGetters(["user"]),
  },
  data() {
    return {
      currentUser: null,
    };
  },
  async mounted() {
    this.currentUser = await this.user;
    if (this.currentUser) {
      this.$router.push("/dashboard");
    }
  },
};
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
