<template>
  <div class="donut-chart transform rotate-90">
    <svg viewBox="0 0 32 32">
      <circle r="16" cx="16" cy="16" :style="rotate" />
    </svg>
    <div class="donut-center"></div>
  </div>
</template>

<script>
export default {
  name: "PieChart",
  props: ["value"],
  computed: {
    rotate() {
      var color = "rgb(5, 150, 105)";
      if (this.value <= 30) color = "rgb(5, 150, 105)";
      else if (this.value > 30 && this.value <= 76) color = "rgb(217, 119, 6)";
      else if (this.value > 76) color = "rgb(220, 38, 38)";
      return `stroke-dasharray: ${this.value} 100; stroke: ${color}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.donut-chart {
  position: relative;

  svg {
    width: 100%;
    height: 100%;
    transform: rotate(-90deg);
    background: rgb(255, 255, 255);
    border-radius: 50%;
  }
  circle {
    fill: #d8d8d8;
    stroke-width: 8;

    border-radius: 20px;
    stroke-dasharray: 0 100;
    transition: stroke-dasharray 0.5s ease;
  }
}
.donut-center {
  background: #fff;
  border-radius: 50%;
  position: absolute;
  height: 75%;
  width: 75%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.center-value {
  position: absolute;
  height: 80%;
  width: 80%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
