<template>
  <div class="main-content flex-1 relative bg-gray-100">
    <loading-overlay :visible="loading" :message="message" />
    <div
      v-if="this.$store.getters.settings.license.active"
      class="flex justify-center w-full"
    >
      <div class="bg-gray-300 rounded-xl w-full m-6 flex flex-col pb-12 px-20">
        <div class="flex flex-col items-center mt-12 justify-start">
          <div
            v-if="!up_to_date"
            class="grid grid-cols-2 text-2xl w-full md:w-1/2 text-center"
          >
            <div class="border-b-2 border-black">{{ $t("updates.name") }}</div>
            <div class="border-b-2 border-black">
              {{ update.name }}
            </div>
            <div class="mt-4">{{ $t("updates.change_log") }}</div>
            <div class="text-lg mt-4 text-left">{{ update.change_log }}</div>
            <div class="col-span-2 border-b-2 border-black"></div>
            <div class="col-span-2 flex justify-center">
              <div
                class="rounded-full bg-green-500 text-white px-8 py-1 mt-2 cursor-pointer"
                @click="downloadUpdate()"
              >
                {{ $t("updates.download_install") }}
              </div>
            </div>
          </div>
          <div v-if="up_to_date" class="text-4xl text-green-500">
            Super Speed Radius Is Up To Date
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import LoadingOverlay from "../components/modals/LoadingOverlay.vue";
export default {
  name: "Update",
  components: { LoadingOverlay },
  data() {
    return {
      loading: false,
      message: "",
      update: {
        name: "",
        url: "",
        change_log: "",
        count: "",
        version: -1,
      },
      up_to_date: false,
    };
  },
  async created() {
    await this.fetchUpdate();
  },
  methods: {
    async fetchUpdate() {
      this.loading = true;
      const update_status = await axios.get("settings/update/check");
      if (update_status.status == 200) {
        if (update_status.data.update == "yes") {
          this.update.name = update_status.data.data.name;
          this.update.url = update_status.data.data.url;
          this.update.change_log = update_status.data.data.change_log;
          this.update.version = update_status.data.data.id;
          this.update.count = update_status.data.count;
        } else {
          this.up_to_date = true;
        }
      }
      this.loading = false;
    },
    async downloadUpdate() {
      this.loading = true;
      this.message = this.$t("updates.downloading_message");
      const down_response = await axios.post(
        "settings/update/download",
        this.update
      );
      if (down_response.status == 210) {
        this.message = this.$t("updates.downloading_fail");
        return;
      }
      this.message = this.$t("updates.installing_message");
      const update_response = await axios.post(
        "settings/update/update",
        this.update
      );
      if (update_response.status == 210) {
        this.message = this.$t("updates.installing_fail");
        return;
      }
      this.message = "COMPLETED SUCCESSFULLY";
      window.location.reload();
    },
  },
};
</script>
