<template>
  <div class="main-content flex-1 relative">
    <loading-overlay :visible="loading" />
    <div v-if="settings.features.wa" class="flex justify-center w-full">
      <div
        class="
          bg-gray-300
          rounded-xl
          w-full
          m-6
          flex flex-col
          pb-12
          px-10
          md:px-20
          justify-center
          items-center
        "
      >
        <div class="flex flex-col lg:w-1/2 justify-center items-center mt-4">
          <div
            v-if="qr && this.status == 'qr'"
            class="w-full flex flex-col items-center"
          >
            <div class="font-bold text-xl">
              {{ $t("notifications.wa.scan_qr") }}
            </div>
            <img :src="`${qr}`" />
            <div style="direction: rtl;" class="mb-12">
              <p class="font-bold">ملاحظات لتجنب حظر الرقم:</p>
              <p>- لاتقم باستخدام رقم واتساب منشأ حديثا</p>
              <p>
                - يفضل استخدام رقم مستخدم مسبقا وعليه محادثات لمدة لاتقل عن 3
                ايام
              </p>
              <p>
                - استخدم جهاز هاتف ورقم للشبكة وسجل جميع اسماء الزبائن كجهات
                اتصال لان الوتس يعتبر الرسائل بدون اسم هي رسائل مزعجة
              </p>
            </div>
          </div>
          <div
            class="flex flex-col items-center justify-center gap-2 mb-2 text-xl"
          >
            <div>{{ $t("notifications.wa.status") }}:</div>
            <!-- <div
              v-if="this.status == 'no_instance'"
              class="font-bold text-green-500"
            >
              {{ $t("notifications.wa.statuses.free") }}
            </div> -->
            <div
              v-if="status"
              class="font-bold"
              :class="{
                'text-green-500': this.status == 'session_connected',
                'text-yellow-500': this.status == 'session_not_connected',
                'text-blue-500': this.status == 'qr',
                'text-red-500': this.status == 'session_not_found',
              }"
            >
              {{ $t(`notifications.wa.statuses.${this.status}`) }}
              <!-- خدمة ربط الرقم الخاص متوقفة مؤقتاً -->
            </div>
            <div>
              <button
                @click.prevent="updateStatus"
                class="w-full bg-green-500 text-white rounded-full  py-1 px-3 mt-4"
              >
                {{ $t("notifications.wa.refresh") }}
              </button>
            </div>
          </div>
        </div>
        <div
          v-if="
            status == 'no_instance' ||
              (status == 'session_not_found' && settings.type == 'local')
          "
          class="flex flex-col lg:w-1/2 justify-center items-start border border-black rounded-2xl p-1"
        >
          <label>{{ $t("notifications.wa.subscription_key") }}</label>
          <input
            class="bg-white
                  rounded-full
                  w-full
                  shadow-lg
                  p-1
                  pl-3
                  focus:outline-none"
            placeholder="xxxxxxxx-xxxx-xxxx-xxxxxxxxxxxx"
            v-model="instance.instance"
            type="text"
          />
          <button
            @click.prevent="instanceCheck"
            class="w-full bg-green-500 text-white rounded-full  py-1 px-3 mt-4"
          >
            {{ $t("notifications.wa.validate") }}
          </button>
          <div v-if="instance_error" class="text-sm text-red-600">
            * {{ instance_error }}
          </div>
        </div>
        <div class="flex flex-col lg:w-1/2 justify-center items-center">
          <div class="grid grid-cols-2 w-full gap-6 mt-6">
            <div>
              <div>
                {username} =>
                {{ $t("accounts.username") }}
              </div>
              <div>{full_name} => {{ $t("accounts.full_name") }}</div>
              <div>{expires_at} => {{ $t("accounts.expires_at") }}</div>
              <div>
                {profile_name} =>
                {{ $t("profiles.name") + " " + $t("profiles.profile") }}
              </div>
            </div>
            <div>
              <div>
                {profile_price} =>
                {{ $t("profiles.price") + " " + $t("profiles.profile") }}
              </div>
              <div>
                {paid_amount} =>
                {{ $t("transactions.paid_amount") }}
              </div>
              <div>
                {debt} =>
                {{ $t("transactions.remaining") }}
              </div>
              <div>
                {invoice_number} =>
                {{ $t("bills.invoice_number") }}
              </div>
              <!-- <div>{profile_quota} => Profile Quota</div> -->
            </div>
          </div>
          <toggle-button
            :value="notifications.expiring_enabled"
            class="mt-12"
            :enabled_name="$t('notifications.config.expiring_enabled')"
            :disabled_name="$t('notifications.config.expiring_enabled')"
            @toggle="
              notifications.expiring_enabled = !notifications.expiring_enabled;
              validate();
            "
          />
          <div
            v-if="notifications.expiring_enabled"
            class="flex flex-col items-center justify-center w-full"
          >
            <label class="font-bold">{{
              $t("notifications.config.expiring_body")
            }}</label>
            <div
              :style="text_direction"
              class="w-full bg-white rounded-3xl p-1 px-3 opacity-50"
            >
              <p>عزيزي المشترك {full_name}</p>
              <p>
                إن اشتراكك في شبكة {{ settings.network_name }} سوف ينتهي بعد 3
                ايام
              </p>
              <p>بتاريخ {expires_at}</p>
              <p>يرجى المبادرة بتجديد الحساب لتجنب انقطاع الخدمة</p>
              <p class="h-9"></p>
              <p>هذه الخدمة مقدمة من قبل Super Speed Radius</p>
            </div>

            <div v-if="errors.expiring" class="text-sm text-red-600">
              * {{ errors.expiring }}
            </div>
          </div>
          <toggle-button
            :value="notifications.expired_enabled"
            class="mt-12"
            :enabled_name="$t('notifications.config.expired_enabled')"
            :disabled_name="$t('notifications.config.expired_enabled')"
            @toggle="
              notifications.expired_enabled = !notifications.expired_enabled;
              validate();
            "
          />
          <div
            v-if="notifications.expired_enabled"
            class="flex flex-col items-center justify-center w-full"
          >
            <label class="font-bold">{{
              $t("notifications.config.expired_body")
            }}</label>
            <div
              :style="text_direction"
              class="w-full bg-white rounded-3xl p-1 px-3 opacity-50"
            >
              <p>عزيزي المشترك {full_name}</p>
              <p>لقد تم انتهاء حسابك في شبكة {{ settings.network_name }}</p>
              <p>يرجى مراجعة مركز الشبكة لتجديد حسابك</p>
              <p class="h-9"></p>
              <p>هذه الخدمة مقدمة من قبل Super Speed Radius</p>
            </div>

            <div v-if="errors.expired" class="text-sm text-red-600">
              * {{ errors.expired }}
            </div>
          </div>
          <toggle-button
            :value="notifications.renew_enabled"
            class="mt-12"
            :enabled_name="$t('notifications.config.renew_enabled')"
            :disabled_name="$t('notifications.config.renew_enabled')"
            @toggle="
              notifications.renew_enabled = !notifications.renew_enabled;
              validate();
            "
          />
          <div
            v-if="notifications.renew_enabled"
            class="flex flex-col items-center justify-center w-full"
          >
            <label class="font-bold">{{
              $t("notifications.config.renew_body")
            }}</label>
            <div
              :style="text_direction"
              class="w-full bg-white rounded-3xl p-1 px-3 opacity-50"
            >
              <p>عزيزي المشترك {full_name}</p>
              <p>لقد تم تجديد حسابك في شبكة {{ settings.network_name }}</p>
              <p>بروفايل: {profile_name}</p>
              <p>تاريخ الانتهاء: {expires_at}</p>
              <p>المدفوع: {paid_amount}</p>
              <p>المتبقي: {debt}</p>
              <p>رمز الفاتورة: {invoice_number}</p>
              <p>الحالة: {paid_amount == profile_price}</p>
              <p>مجموع الديون: {total_debt}</p>
              <p>شكرا لثقتك بشبكة {{ settings.network_name }}</p>
              <p class="h-9"></p>
              <p>هذه الخدمة مقدمة من قبل Super Speed Radius</p>
            </div>

            <div v-if="errors.renew" class="text-sm text-red-600">
              * {{ errors.renew }}
            </div>
          </div>
          <div class="w-full">
            <button
              @click.prevent="store"
              :class="{
                'bg-opacity-50 cursor-not-allowed': !validated,
              }"
              :disabled="!validated"
              class="w-full bg-green-500 text-white rounded-full  py-1 px-3 mt-4"
            >
              {{ $t("profiles.save") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import LoadingOverlay from "../components/modals/LoadingOverlay.vue";
import { mapGetters } from "vuex";
import ToggleButton from "../components/formElements/ToggleButton.vue";
import QrcodeVue from "qrcode.vue";

export default {
  name: "NotificationsWa",
  data() {
    return {
      qr: null,
      loading: false,
      status: "",
      notifications: {
        expired: "قم بتجديد الاشتراك لاستعادة الاتصال بالانترنت",
        expiring: "قم بتجديد الاشتراك كي لا تفقد الاتصال بالانترنت",
        renew: "شكرا لك على استخدامك شبكتنا",
        renew_enabled: false,
        expired_enabled: false,
        expiring_enabled: false,
      },
      instance: {
        instance: "",
      },
      instance_error: null,
      validated: false,
      errors: {
        expired: null,
        expiring: null,
        renew: null,
      },
      inside_page: true,
      text_direction:
        this.$i18n.locale == "ar" ? "direction:rtl" : "direction:ltr",
    };
  },
  computed: {
    ...mapGetters(["user", "settings"]),
  },
  async created() {
    this.statusCheck();
    this.getQr();
    this.fetchSettings();
    while (this.inside_page) {
      if (this.status == "qr") this.getQr();
      await new Promise((r) => setTimeout(r, 30000));
    }
  },
  watch: {
    $route(to, from) {
      this.inside_page = false;
    },
  },
  methods: {
    async getQr() {
      // this.loading = true;
      var res = await axios.get("wa/qr");
      if (res.status == 200) {
        this.qr = res.data.qr_code;
      }
      // this.loading = false;
    },
    async statusCheck() {
      var response = await axios.get("wa/status");
      if (response.status == 200) {
        if (response.data.status == "open") this.status = "session_connected";
        else this.status = "qr";
        if (this.status == "browser tab closed")
          this.status = "session_not_found";
      } else if (response.status == 210) {
        this.status = "no_instance";
      }
      //this.status = "no_instance";
    },
    async fetchSettings() {
      this.loading = true;
      const response = await axios.get("wa/notifications");
      if (response.status == 200) {
        var temp = response.data.data;
        temp.forEach((element) => {
          switch (element.key) {
            case "wa_notifications_expiring_enabled":
              this.notifications.expiring_enabled = element.value == 1;
              break;
            case "wa_notifications_expiring_body":
              this.notifications.expiring = element.value;
              break;
            case "wa_notifications_expired_enabled":
              this.notifications.expired_enabled = element.value == 1;
              break;
            case "wa_notifications_expired_body":
              this.notifications.expired = element.value;
              break;
            case "wa_notifications_renew_enabled":
              this.notifications.renew_enabled = element.value == 1;
              break;
            case "wa_notifications_renew_body":
              this.notifications.renew = element.value;
              break;
            default:
              break;
          }
        });
      }
      this.loading = false;
    },
    async store() {
      this.loading = true;
      const response = await axios.post("wa/notifications", this.notifications);
      if (response.status == 200) {
        this.validated = false;
      }
      this.loading = false;
    },
    async instanceCheck() {
      this.loading = true;
      const response = await axios.post("wa/instance", this.instance);
      if (response.status == 200) {
        location.reload();
      } else if (response.status == 210) {
        this.instance_error = this.$t(
          `errors.notifications.wa.${response.data.error}`
        );
      }
      this.loading = false;
    },
    async updateStatus() {
      this.statusCheck();
      if (this.status == "session_not_connected") this.getQr();
    },
    validate() {
      this.validated = true;
      this.errors.expired = null;
      this.errors.expiring = null;
      this.errors.renew = null;
      var urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
      if (
        this.notifications.expired.includes(".") ||
        this.notifications.expired.includes("http")
      ) {
        this.errors.expired = this.$t("errors.notifications.wa.url");
        this.validated = false;
      }
      if (
        this.notifications.expiring.includes(".") ||
        this.notifications.expiring.includes("http")
      ) {
        this.errors.expiring = this.$t("errors.notifications.wa.url");
        this.validated = false;
      }
      if (
        this.notifications.renew.includes(".") ||
        this.notifications.renew.includes("http")
      ) {
        this.errors.renew = this.$t("errors.notifications.wa.url");
        this.validated = false;
      }
    },
  },
  components: { LoadingOverlay, ToggleButton, QrcodeVue },
};
</script>
