<template>
  <div
    v-if="visible"
    class="fixed z-40 inset-0 overflow-y-auto"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <div
      class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
    >
      <div
        @click.prevent="hide"
        class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        aria-hidden="true"
      ></div>

      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
        >&#8203;</span
      >
      <div
        class="inline-block align-bottom bg-white rounded-3xl text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-5xl sm:w-full md:w-1/3"
      >
        <div class="flex flex-col items-center">
          <div class="bg-ssr-side_bar-background w-full flex justify-between">
            <div class="text-2xl text-gray-800 font-bold py-2 pl-6">.</div>
            <div
              @click.prevent="hide"
              class="rounded-full cursor-pointer bg-ssr-side_bar-button_hover text-white text-xs my-2 mr-6 w-28 flex justify-center items-center font-bold"
            >
              {{ $t("control.back") }}
            </div>
          </div>
          <div class="text-yellow-600 mt-12">
            <svg
              width="100"
              height="100"
              viewBox="0 0 339 339"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M145.342 54.5226L25.7037 254.25C23.2371 258.522 21.9319 263.365 21.9181 268.298C21.9043 273.23 23.1823 278.081 25.625 282.366C28.0677 286.652 31.59 290.223 35.8414 292.724C40.0927 295.226 44.925 296.571 49.8575 296.625H289.135C294.067 296.571 298.9 295.226 303.151 292.724C307.403 290.223 310.925 286.652 313.367 282.366C315.81 278.081 317.088 273.23 317.074 268.298C317.061 263.365 315.755 258.522 313.289 254.25L193.65 54.5226C191.132 50.3713 187.586 46.9391 183.356 44.5572C179.125 42.1752 174.352 40.9238 169.496 40.9238C164.641 40.9238 159.868 42.1752 155.637 44.5572C151.406 46.9391 147.861 50.3713 145.342 54.5226V54.5226Z"
                stroke="currentColor"
                stroke-width="15"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M169.5 127.125V183.625"
                stroke="currentColor"
                stroke-width="15"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M169.5 240.125H169.641"
                stroke="currentColor"
                stroke-width="15"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <div class="w-full bg-yellow-100 text-center mt-4 p-6">
            <div
              class="text-yellow-600 font-bold"
              style="direction: rtl;white-space: pre-line"
            >
              {{ message }}
            </div>
          </div>
          <div class="w-full flex flex-col my-12 items-center">
            <div
              @click.prevent="confirm"
              class="mt-6 rounded-full cursor-pointer bg-green-400 text-gray-800 px-6 flex justify-center items-center font-bold"
            >
              {{ $t("control.yes") }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ErrorMessage",
  props: ["visible", "message"],
  methods: {
    hide() {
      this.$emit("hide");
    },
    confirm() {
      this.$emit("confirmed");
    },
  },
};
</script>
