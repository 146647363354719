<template>
  <div class="main-content flex-1 relative h-screen bg-gray-100">
    <loading-overlay :visible="loading" />
    <transition name="fade">
      <delete-confirm
        :visible="showDelete"
        @hide="showDelete = false"
        @deleteconfirmed="deleteData"
      />
    </transition>
    <transition name="fade">
      <add-channel
        v-if="showAdd"
        :visible="showAdd"
        @hide-details="showAdd = false"
        @updated="
          showAdd = false;
          fetchChannels();
        "
      />
    </transition>
    <div
      v-if="this.$store.getters.settings.license.active"
      class="flex justify-center w-full"
    >
      <div
        v-if="hasPrivilege('settings_iptv')"
        class="bg-gray-300 rounded-xl w-full m-6 flex flex-col pb-12 md:px-20"
      >
        <div class="flex justify-center">
          <div class="mt-6 flex-col items-center">
            <div class="w-full text-center text-2xl">IPTV</div>
            <toggle-button
              :value="status"
              :enabled_name="$t('profiles.enabled')"
              :disabled_name="$t('profiles.disabled')"
              @toggle="toggleStatus"
            />
            <button
              v-if="hasPrivilege('settings_iptv')"
              class="
              mt-6
              rounded-full
              bg-ssr-side_bar-button_hover
              text-base
              text-white
              p-2
              w-full
              px-12
            "
              :class="{
                disabled:
                  !this.$store.getters.settings.license.active || !status,
              }"
              @click.prevent="showAdd = true"
            >
              {{ $t("iptv.add") }}
            </button>
          </div>
        </div>
        <table class="mt-6 text-xs md:text-base" :class="{ disabled: !status }">
          <thead
            class="
                bg-ssr-side_bar-settings
                text-white
                font-bold
                rounded-full
                border-b-2 border-black
              "
          >
            <th>{{ $t("iptv.logo") }}</th>
            <th>{{ $t("iptv.name") }}</th>
            <th>{{ $t("iptv.url") }}</th>
            <th></th>
          </thead>
          <tbody>
            <tr
              class="bg-white border-b border-black"
              v-for="(channel, index) in channels"
              v-bind:key="index"
            >
              <td class="text-center">
                <div
                  class="flex justify-center items-center"
                  v-if="channel.logo"
                >
                  <img
                    :src="server_url + channel.logo"
                    class=" max-h-9 md:max-h-28 md:max-w-sm"
                  />
                </div>
              </td>
              <td class="text-center">{{ channel.name }}</td>
              <td class="text-center">
                <a :href="channel.url" class=" text-blue-700 underline">{{
                  channel.url
                }}</a>
              </td>
              <td>
                <div
                  class="
                    px-3
                    p-1
                    mx-2
                    bg-red-500
                    text-white text-center
                    rounded-full
                    inline-block
                    cursor-pointer
                  "
                  :class="{
                    disabled: !this.$store.getters.settings.license.active,
                  }"
                  @click="
                    selectedData = channel;
                    showDelete = true;
                  "
                >
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      class="w-6 h-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                      />
                    </svg>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <expired-window v-else />
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import NumericalSpinner from "../components/formElements/NumericalSpinner.vue";
import ToggleButton from "../components/formElements/ToggleButton.vue";
import Dropdown from "../components/formElements/Dropdown.vue";
import LoadingOverlay from "../components/modals/LoadingOverlay.vue";
import ExpiredWindow from "../components/ExpiredWindow.vue";
import DeleteConfirm from "../components/modals/DeleteConfirm.vue";
import { hasPrivilege } from "../global_constants.js";
import AddChannel from "../components/modals/AddChannel.vue";
export default {
  components: {
    NumericalSpinner,
    ToggleButton,
    Dropdown,
    LoadingOverlay,
    ExpiredWindow,
    DeleteConfirm,
    AddChannel,
  },
  name: "IPTV",
  computed: {
    ...mapGetters(["settings", "user"]),
  },
  data() {
    return {
      dma: null,
      loading: false,
      message: "",
      sessions: false,
      channels: [],
      showDelete: false,
      showAdd: false,
      server_url: localStorage.getItem("server"),
      selectedData: null,
      status: false,
    };
  },
  async created() {
    await this.fetchChannels();
  },
  methods: {
    hasPrivilege(privilege) {
      return hasPrivilege(this.user, privilege);
    },
    selectFile() {
      this.dma = this.$refs.dmaFile.files.item(0);
    },
    async fetchChannels() {
      this.loading = true;
      const response = await axios.get("iptv");
      this.channels = response.data.data;
      const response2 = await axios.get("iptv/status");
      this.status = response2.data.data == 1;
      this.loading = false;
    },
    async toggleStatus() {
      this.loading = true;
      const response = await axios.post("iptv/enable");
      if (response.status == 200) {
        this.$router.push({
          path: "/iptv",
          query: { success: "success" },
        });
      }
      this.loading = false;
      this.fetchChannels();
    },
    async deleteData() {
      this.showDelete = false;
      this.loading = true;
      const response = await axios.post(`iptv/${this.selectedData.id}`);
      if (response.status == 200) {
        this.fetchChannels();
      }
      this.loading = false;
    },
  },
};
</script>
