<template>
  <div class="main-content flex-1 relative">
    <loading-overlay :visible="loading" />
    <div class="flex justify-center w-full">
      <div
        class="bg-gray-300 rounded-xl w-full m-6 flex flex-col pb-12 px-10 md:px-20 justify-center items-center"
      >
        <div class="flex flex-col lg:w-1/2 justify-center items-center">
          <div class="w-full items-start grid grid-cols-2 gap-6 mt-6">
            <div class="flex flex-col items-center mt-9 col-span-2">
              <img
                v-if="currentImageUrl"
                class="rounded-full h-32 w-32 mx-5"
                :src="currentImageUrl"
                alt="Network Image"
              />
              <img
                v-if="!currentImageUrl && !setting.network_logo"
                class="rounded-full h-32 w-32 mx-5"
                src="@/assets/logo.png"
                alt="Network Image"
              />
              <img
                v-if="setting.network_logo"
                class="rounded-full h-32 w-32 mx-5"
                :src="server_url + setting.network_logo"
                alt="Network Image"
              />
              <label
                for="image"
                class="bg-blue-400 rounded-2xl py-1 px-4 mt-2 cursor-pointer"
                >{{ $t("settings.change_logo") }}</label
              >
              <input
                id="image"
                type="file"
                accept="image/*"
                ref="imageFile"
                @change="selectImage"
                class="hidden"
              />
            </div>
            <div class="flex justify-center border-b border-black col-span-2">
              {{ $t("settings.system") }}
            </div>
            <div class="flex flex-col w-full items-start">
              <label class="font-bold">{{ $t("settings.network_name") }}</label>
              <input
                v-model="setting.network_name"
                type="text"
                class="bg-white rounded-full w-full shadow-lg p-1 pl-3 focus:outline-none"
              />
            </div>
            <div class="flex flex-col">
              <label class="font-bold">{{ $t("settings.time_zone") }}</label>
              <select
                v-model="setting.time_zone"
                class="bg-white rounded-full w-full shadow-lg p-1 pl-3 focus:outline-none"
              >
                <option
                  v-for="zone in [
                    { name: 'Istanbul', id: 'Europe/Istanbul' },
                    { name: 'Damascus', id: 'Asia/Damascus' },
                  ]"
                  v-bind:key="zone.id"
                  :value="zone.id"
                >
                  {{ zone.name }}
                </option>
              </select>
            </div>
            <div class="flex flex-col">
              <label class="font-bold">{{ $t("settings.currency") }}</label>
              <select
                v-model="setting.currency"
                class="bg-white rounded-full w-full shadow-lg p-1 pl-3 focus:outline-none"
              >
                <option
                  v-for="currency in [
                    { name: $t('currencies.usd'), id: '$' },
                    { name: $t('currencies.tr'), id: '₺' },
                    { name: $t('currencies.syp'), id: 'SYP' },
                    { name: $t('currencies.iqd'), id: 'IQD' },
                    { name: $t('currencies.jd'), id: 'JD' },
                    { name: $t('currencies.lbp'), id: 'LBP' },
                    { name: $t('currencies.egp'), id: 'EGP' },
                    { name: $t('currencies.sar'), id: 'SAR' },
                  ]"
                  v-bind:key="currency.id"
                  :value="currency.id"
                >
                  {{ currency.name }}
                </option>
              </select>
            </div>
            <div class="flex flex-col">
              <label class="font-bold">{{
                $t("settings.default_language")
              }}</label>
              <select
                v-model="setting.language"
                class="bg-white rounded-full w-full shadow-lg p-1 pl-3 focus:outline-none"
              >
                <option
                  v-for="language in [
                    { name: 'English', id: 'en' },
                    { name: 'العربية', id: 'ar' },
                    { name: 'Türkçe', id: 'tr' },
                  ]"
                  v-bind:key="language.id"
                  :value="language.id"
                >
                  {{ language.name }}
                </option>
              </select>
            </div>
            <div class="flex flex-col">
              <label class="font-bold">{{
                $t("settings.default_expiration_hour")
              }}</label>
              <select
                v-model="setting.default_expiration_hour"
                class="bg-white rounded-full w-full shadow-lg p-1 pl-3 focus:outline-none"
              >
                <option
                  v-for="unit in [
                    { name: '00:00', id: '0' },
                    { name: '01:00', id: '1' },
                    { name: '02:00', id: '2' },
                    { name: '03:00', id: '3' },
                    { name: '04:00', id: '4' },
                    { name: '05:00', id: '5' },
                    { name: '06:00', id: '6' },
                    { name: '07:00', id: '7' },
                    { name: '08:00', id: '8' },
                    { name: '09:00', id: '9' },
                    { name: '10:00', id: '10' },
                    { name: '11:00', id: '11' },
                    { name: '12:00', id: '12' },
                    { name: '13:00', id: '13' },
                    { name: '14:00', id: '14' },
                    { name: '15:00', id: '15' },
                    { name: '16:00', id: '16' },
                    { name: '17:00', id: '17' },
                    { name: '18:00', id: '18' },
                    { name: '19:00', id: '19' },
                    { name: '20:00', id: '20' },
                    { name: '21:00', id: '21' },
                    { name: '22:00', id: '22' },
                    { name: '23:00', id: '23' },
                  ]"
                  v-bind:key="unit.id"
                  :value="unit.id"
                >
                  {{ unit.name }}
                </option>
              </select>
            </div>
            <div class="flex flex-col">
              <label class="font-bold">{{ $t("settings.auto_backup") }}</label>
              <toggle-button
                v-if="settings.features.backups"
                class="shadow-lg"
                :value="setting.auto_backup"
                :enabled_name="$t('control.enabled')"
                :disabled_name="$t('control.disabled')"
                @toggle="
                  setting.auto_backup = !setting.auto_backup;
                  validate();
                "
              />
            </div>
            <div class="flex flex-col w-full items-start">
              <label class="font-bold">{{ $t("settings.status_url") }}</label>
              <input
                v-model="setting.status_url"
                type="text"
                class="bg-white rounded-full w-full shadow-lg p-1 pl-3 focus:outline-none"
              />
            </div>
            <div class="flex flex-col w-full items-start">
              <label class="font-bold">{{ $t("settings.phone_number") }}</label>
              <input
                v-model="setting.phone_number"
                type="text"
                class="bg-white rounded-full w-full shadow-lg p-1 pl-3 focus:outline-none"
              />
            </div>
            <div class="flex justify-center border-b border-black col-span-2">
              {{ $t("settings.ssra") }}
            </div>

            <label class="font-bold">{{
              $t("settings.ssr_account_password_change")
            }}</label>
            <label class="font-bold">{{
              $t("settings.ssr_account_profile_update")
            }}</label>
            <toggle-button
              v-if="settings.features.ssra"
              class="shadow-lg"
              :value="setting.ssr_account_password_change"
              :enabled_name="$t('control.enabled')"
              :disabled_name="$t('control.disabled')"
              @toggle="
                setting.ssr_account_password_change =
                  !setting.ssr_account_password_change;
                validate();
              "
            />
            <toggle-button
              v-if="settings.features.ssra"
              class="shadow-lg"
              :value="setting.ssr_account_profile_update"
              :enabled_name="$t('control.enabled')"
              :disabled_name="$t('control.disabled')"
              @toggle="
                setting.ssr_account_profile_update =
                  !setting.ssr_account_profile_update;
                validate();
              "
            />
            <div class="flex justify-center border-b border-black col-span-2">
              {{ $t("settings.nas") }}
            </div>
            <label class="font-bold">{{
              $t("settings.nas_interim_update")
            }}</label>
            <div></div>
            <select
              class="bg-white rounded-full w-full shadow-lg p-1 pl-3 focus:outline-none"
              v-model="setting.nas_interim_update"
            >
              <option value="1m">1m</option>
              <option value="2m">2m</option>
              <option value="5m">5m</option>
              <option value="10m">10m</option>
              <option value="30m">30m</option>
              <option value="1h">1h</option>
            </select>
            <div class="flex flex-col col-span-2 mt-9">
              <button
                @click.prevent="store"
                class="w-full bg-green-500 text-white rounded-full py-1 px-3"
              >
                {{ $t("settings.save") }}
              </button>
              <div class="text-red-600">{{ error_message }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import NumericalSpinner from "../components/formElements/NumericalSpinner.vue";
import ToggleButton from "../components/formElements/ToggleButton.vue";
import Dropdown from "../components/formElements/Dropdown.vue";
import LoadingOverlay from "../components/modals/LoadingOverlay.vue";
import { mapGetters } from "vuex";
export default {
  components: { NumericalSpinner, ToggleButton, Dropdown, LoadingOverlay },
  name: "Settings",
  inject: {
    server_url: {
      from: "server_url",
    },
  },
  computed: {
    ...mapGetters(["settings"]),
  },
  async created() {
    this.loading = true;
    const response = await axios.get("settings");
    if (response.status == 200) {
      this.setting = response.data.data;
      this.loading = false;
      if (this.setting.auto_backup == "true") {
        this.setting.auto_backup = true;
      } else {
        this.setting.auto_backup = false;
      }
      this.setting.ssr_account_password_change =
        this.setting.ssr_account_password_change == "1";
      this.setting.ssr_account_profile_update =
        this.setting.ssr_account_profile_update == "1";
    }
  },
  data() {
    return {
      error: "",
      message: "",
      currentImageUrl: null,
      setting: {
        network_name: "",
        network_logo: "",
        currency: "",
        language: "",
        time_zone: "",
        auto_backup: false,
        default_expiration_hour: "0",
        ssr_account_password_change: false,
        ssr_account_profile_update: false,
        nas_interim_update: "",
      },
      error_message: "",
      loading: false,
      profiles: null,
    };
  },
  methods: {
    selectImage() {
      this.setting.network_logo = this.$refs.imageFile.files.item(0);
      this.currentImageUrl = URL.createObjectURL(this.setting.network_logo);
    },
    async store() {
      this.loading = true;
      const data = new FormData();
      data.append("network_logo", this.setting.network_logo);
      data.append("network_name", this.setting.network_name);
      data.append("currency", this.setting.currency);
      data.append("language", this.setting.language);
      data.append("time_zone", this.setting.time_zone);
      data.append("status_url", this.setting.status_url);
      data.append("phone_number", this.setting.phone_number);
      data.append(
        "ssr_account_password_change",
        this.setting.ssr_account_password_change ? "true" : "false"
      );
      data.append(
        "ssr_account_profile_update",
        this.setting.ssr_account_profile_update ? "true" : "false"
      );
      data.append(
        "default_expiration_hour",
        this.setting.default_expiration_hour
      );
      data.append("auto_backup", this.setting.auto_backup ? "true" : "false");
      data.append("nas_interim_update", this.setting.nas_interim_update);
      try {
        const response = await axios.post("/settings/update", data, {
          headers: { "Content-Type": "multipart/form-data" },
        });
        if (response.status == 200) {
          const responseSettings = await axios.get("settings");
          this.$store.dispatch("settings", responseSettings.data.data);
          this.$router.push({ path: "/", query: { success: "success" } });
        }
      } catch (error) {
        this.loading = false;
        this.error_message = error;
      }
    },
    validate() {},
  },
};
</script>
