import axios from "axios";
import store from "./store/index.js";
//axios.defaults.baseURL = "http://192.168.1.100/api/";
axios.defaults.baseURL = localStorage.getItem("server_api");
// axios.defaults.headers.common["Authorization"] =
//   "Bearer " + localStorage.getItem("token");
axios.defaults.withCredentials = true;
axios.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    switch (error.response.status) {
      case 401:
        localStorage.removeItem("server_api");
        localStorage.removeItem("server");
        window.location.reload();
        break;
      case 460:
        store.dispatch("set_warning_message", "EXPIRED!");
        store.dispatch("show_warning_message", true);
        break;
      case 461:
        store.dispatch("set_warning_message", "LIC_ERROR!");
        store.dispatch("show_warning_message", true);
        break;
      case 464:
        store.dispatch("set_warning_message", error.response.data);
        store.dispatch("show_warning_message", true);
        break;
      case 469:
        store.dispatch("set_warning_message", "NO_CONNECTION!");
        store.dispatch("show_warning_message", true);
        break;
      case 500:
        store.dispatch("set_warning_message", "SERVER_ERROR!");
        store.dispatch("show_warning_message", true);
        break;
      default:
        break;
    }
  }
);
