<template>
  <div
    @click.prevent="if (this.$refs.menu != null) this.$refs.menu.close();"
    class="main-content flex-1 bg-gray-100"
  >
    <transition name="fade">
      <profile-details
        v-if="selectedData"
        :visible="showDetails"
        :selectedProfile="selectedData.profile"
        @hide-details="showDetails = false"
      />
    </transition>
    <transition name="fade">
      <delete-confirm
        :visible="showDelete"
        @hide="showDelete = false"
        @deleteconfirmed="deleteData"
      />
    </transition>
    <transition name="fade">
      <columns-select
        :visible="showColumnsSelect"
        :columns="columns"
        :name="'batches_columns'"
        @hide="showColumnsSelect = false"
        @selectconfirmed="updateColumns(currentColumns)"
      />
    </transition>

    <transition name="expandy">
      <tables-context :display="showContext" ref="menu">
        <ul
          :class="{
            disabled: !this.$store.getters.settings.license.active,
          }"
        >
          <!-- <li
            @click="showDetails = true"
            class="m-2 pr-8 border-b border-black hover:bg-gray-200"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6 inline-block bg-yellow-300 rounded-lg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
              />
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
              />
            </svg>
            {{ $t("actions.view") }}
          </li> -->
          <li
            @click="printCards(selectedData)"
            class="m-2 pr-8 border-b border-black hover:bg-gray-200"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6 inline-block rounded-lg bg-blue-300"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z"
              />
            </svg>
            {{ $t("actions.print") }}
          </li>
          <li
            @click="exportCsv(selectedData.id)"
            class="m-2 pr-8 border-b border-black hover:bg-gray-200"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6 inline-block rounded-lg bg-yellow-300"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
              />
            </svg>
            {{ $t("actions.export_csv") }}
          </li>
          <li
            v-if="hasPrivilege('cards_delete_cards')"
            @click="showDelete = true"
            class="m-2 border-b border-black hover:bg-gray-200"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6 inline-block bg-red-500 text-white rounded-lg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
              />
            </svg>
            {{ $t("actions.delete") }}
          </li>
        </ul>
      </tables-context>
    </transition>
    <div class="p-2 md:p-6" @click.prevent="showFilters = false">
      <div class="flex justify-between">
        <div>
          <button
            class="text-xs lg:text-base rounded-full text-black lg:py-1 px-4 mr-4"
            :class="{
              'bg-blue-300 text-black shadow-blue ': autoUpdate,
              'bg-gray-700 text-white': !autoUpdate,
            }"
            @click.prevent="toggleAutoUpdate"
          >
            {{
              autoUpdate
                ? "✓ " + $t("accounts.auto_update")
                : $t("accounts.auto_update")
            }}
          </button>
          <button
            class="text-xs lg:text-base rounded-full text-black lg:py-1 px-4 mr-4"
            :class="{
              'bg-blue-300 text-black shadow-blue ': filters.save,
              'bg-gray-700 text-white': !filters.save,
            }"
            @click.prevent="toggleSaveFilters"
          >
            {{
              autoUpdate
                ? "✓ " + $t("control.save_filters")
                : $t("control.save_filters")
            }}
          </button>
        </div>
        <div class="flex">
          <button
            class="rounded-full bg-ssr-side_bar-settings text-xs lg:text-base text-white py-1 lg:py-2 px-4 font-bold mr-4"
            @click.prevent="showColumnsSelect = true"
          >
            ...
          </button>
          <button
            v-if="hasPrivilege('cards_edit_cards')"
            @click.prevent="this.$router.push('/cards/create')"
            class="rounded-full bg-ssr-side_bar-button_hover text-xs lg:text-base text-white lg:p-2 px-2 "
            :class="{
              disabled: !this.$store.getters.settings.license.active,
            }"
          >
            {{ $t("batches.add_cards") }}
          </button>
        </div>
      </div>
      <div
        class="w-full flex flex-col text-center  overflow-auto"
        style="height: 30rem"
      >
        <table>
          <thead class="border-b-2 border-black text-xs md:text-base">
            <th></th>
            <th>
              <table-check-box
                class="w-5 h-5 my-3 mx-4 cursor-pointer"
                :value="allSelected"
                @click="checkAll"
              />
            </th>
            <th v-if="columns.name.enabled">
              <div class="inline-block">{{ $t("batches.name") }}</div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6 inline-block transform transition-all cursor-pointer"
                :class="{ 'rotate-180': columns.name.sort }"
                @click="sort('name')"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 15l7-7 7 7"
                />
              </svg>
              <input
                v-if="hasPrivilege('cards_view_search_batches')"
                type="text"
                class="bg-white rounded-full w-full shadow-lg p-1 focus:outline-none text-center"
                :placeholder="$t('control.filter')"
                @input="applyFilters"
                v-model="filters.name"
              />
            </th>
            <th v-if="columns.quantity.enabled">
              <div class="inline-block">{{ $t("batches.quantity") }}</div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6 inline-block transform transition-all cursor-pointer"
                :class="{ 'rotate-180': columns.quantity.sort }"
                @click="sort('quantity')"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 15l7-7 7 7"
                />
              </svg>
              <input
                v-if="hasPrivilege('cards_view_search_batches')"
                type="text"
                class="bg-white rounded-full w-full shadow-lg p-1 focus:outline-none text-center"
                :placeholder="$t('control.filter')"
                @input="applyFilters"
                v-model="filters.quantity"
              />
            </th>
            <th v-if="columns.fresh_cards.enabled">
              <div class="inline-block">{{ $t("batches.fresh_cards") }}</div>
            </th>
            <th v-if="columns.price.enabled">
              <div class="inline-block">{{ $t("batches.price") }}</div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6 inline-block transform transition-all cursor-pointer"
                :class="{ 'rotate-180': columns.price.sort }"
                @click="sort('price')"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 15l7-7 7 7"
                />
              </svg>
              <input
                v-if="hasPrivilege('cards_view_search_batches')"
                type="text"
                class="bg-white rounded-full w-full shadow-lg p-1 focus:outline-none text-center"
                :placeholder="$t('control.filter')"
                @input="applyFilters"
                v-model="filters.price"
              />
            </th>
            <th v-if="columns.prefix.enabled">
              <div class="inline-block">{{ $t("batches.prefix") }}</div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6 inline-block transform transition-all cursor-pointer"
                :class="{ 'rotate-180': columns.prefix.sort }"
                @click="sort('prefix')"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 15l7-7 7 7"
                />
              </svg>
              <input
                v-if="hasPrivilege('cards_view_search_batches')"
                type="text"
                class="bg-white rounded-full w-full shadow-lg p-1 focus:outline-none text-center"
                :placeholder="$t('control.filter')"
                @input="applyFilters"
                v-model="filters.prefix"
              />
            </th>
            <th v-if="columns.profile.enabled">
              <div>{{ $t("batches.profile") }}</div>
              <input
                v-if="hasPrivilege('cards_view_search_batches')"
                type="text"
                class="bg-white rounded-full w-full shadow-lg p-1 focus:outline-none text-center"
                :placeholder="$t('control.filter')"
                @input="applyFilters"
                v-model="filters.profile"
              />
            </th>
            <th v-if="columns.username_length.enabled">
              <div class="inline-block">
                {{ $t("batches.username_length") }}
              </div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6 inline-block transform transition-all cursor-pointer"
                :class="{ 'rotate-180': columns.username_length.sort }"
                @click="sort('username_length')"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 15l7-7 7 7"
                />
              </svg>
              <input
                v-if="hasPrivilege('cards_view_search_batches')"
                type="text"
                class="bg-white rounded-full w-full shadow-lg p-1 focus:outline-none text-center"
                :placeholder="$t('control.filter')"
                @input="applyFilters"
                v-model="filters.username_length"
              />
            </th>
            <th v-if="columns.password_length.enabled">
              <div class="inline-block">
                {{ $t("batches.password_length") }}
              </div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6 inline-block transform transition-all cursor-pointer"
                :class="{ 'rotate-180': columns.password_length.sort }"
                @click="sort('password_length')"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 15l7-7 7 7"
                />
              </svg>
              <input
                v-if="hasPrivilege('cards_view_search_batches')"
                type="text"
                class="bg-white rounded-full w-full shadow-lg p-1 focus:outline-none text-center"
                :placeholder="$t('control.filter')"
                @input="applyFilters"
                v-model="filters.password_length"
              />
            </th>
            <th v-if="columns.allowed_sessions.enabled">
              <div class="inline-block">{{ $t("batches.sessions") }}</div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6 inline-block transform transition-all cursor-pointer"
                :class="{ 'rotate-180': columns.allowed_sessions.sort }"
                @click="sort('allowed_sessions')"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 15l7-7 7 7"
                />
              </svg>
              <input
                v-if="hasPrivilege('cards_view_search_batches')"
                type="text"
                class="bg-white rounded-full w-full shadow-lg p-1 focus:outline-none text-center"
                :placeholder="$t('control.filter')"
                @input="applyFilters"
                v-model="filters.allowed_sessions"
              />
            </th>
            <th v-if="columns.is_mac.enabled">
              <div class="inline-block">{{ $t("batches.is_mac") }}</div>
            </th>
            <th v-if="columns.created_at.enabled">
              <div class="inline-block">{{ $t("batches.created_at") }}</div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6 inline-block transform transition-all cursor-pointer"
                :class="{ 'rotate-180': columns.created_at.sort }"
                @click="sort('created_at')"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 15l7-7 7 7"
                />
              </svg>
              <input
                v-if="hasPrivilege('cards_view_search_batches')"
                type="text"
                class="bg-white rounded-full w-full shadow-lg p-1 focus:outline-none text-center"
                placeholder="Ex: 2021-01-01"
                v-model="filters.created_at"
                @input="applyFilters"
              />
            </th>
          </thead>
          <tbody>
            <tr
              v-for="(batch, index) in dataList"
              v-bind:key="batch.id"
              class="text-xs md:text-base"
              :class="{
                'bg-gray-200': index % 2 == 0,
                'bg-blue-300': batch.selected,
              }"
              @contextmenu.prevent="openContextMenu($event, batch)"
            >
              <td class="font-bold">
                {{ index + 1 + (meta.current_page - 1) * meta.per_page }}
              </td>
              <td>
                <table-check-box
                  class="w-5 h-5 my-3 mx-4 cursor-pointer"
                  :value="batch.selected"
                  @click="selectData(batch)"
                />
              </td>
              <td
                :class="{
                  disabled: !this.$store.getters.settings.license.active,
                }"
                v-if="columns.name.enabled"
              >
                <a
                  class="text-blue-700 cursor-pointer border-b border-blue-700 font-bold"
                  @click.prevent="
                    this.$router.push(`/cards/${batch.id}/cards`);
                    this.$store.commit('changeKey');
                  "
                  >{{ batch.name }}</a
                >
              </td>

              <td v-if="columns.quantity.enabled">{{ batch.quantity }}</td>
              <td v-if="columns.fresh_cards.enabled">
                {{ batch.fresh_cards }}
              </td>
              <td v-if="columns.price.enabled">
                {{ batch.price }}
              </td>
              <td v-if="columns.prefix.enabled">{{ batch.prefix }}</td>
              <td
                v-if="columns.profile.enabled"
                :class="{
                  disabled: !this.$store.getters.settings.license.active,
                }"
              >
                <a
                  v-if="user.data.type != 'reseller'"
                  class="text-blue-700 cursor-pointer border-b border-blue-700 font-bold"
                  @click.prevent="
                    this.$router.push(`/profiles/${batch.profile.id}/edit`)
                  "
                >
                  {{ batch.profile ? batch.profile.name : "" }}
                </a>
                <div v-if="user.data.type == 'reseller'">
                  {{ batch.profile ? batch.profile.name : "" }}
                </div>
              </td>
              <td v-if="columns.username_length.enabled">
                {{ batch.username_length }}
              </td>
              <td v-if="columns.password_length.enabled">
                {{ batch.password_length }}
              </td>

              <td v-if="columns.allowed_sessions.enabled">
                {{ batch.allowed_sessions }}
              </td>
              <td v-if="columns.is_mac.enabled">
                {{ batch.is_mac ? $t("control.yes") : $t("control.no") }}
              </td>
              <td v-if="columns.created_at.enabled">
                {{ formatDate(batch.created_at) }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-if="loading" class="w-full flex justify-center">
        <img
          class=" inline-block h-12 w-12"
          src="@/assets/loading.gif"
          alt="Loading"
        />
      </div>
      <div
        class="flex justify-start"
        v-if="selection_array.length == 1"
        :class="{
          disabled: !this.$store.getters.settings.license.active,
        }"
      >
        <!-- <div
          @click="
            selectedData = selection_array[0];
            showDetails = true;
          "
          class="ml-2 group cursor-pointer relative"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6 inline-block bg-yellow-300 rounded-lg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
            />
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
            />
          </svg>
          <div
            class="scale-0 transform transition-all ease-linear group-hover:scale-100 absolute"
          >
            {{ $t("actions.view") }}
          </div>
        </div> -->
        <div
          v-if="hasPrivilege('cards_edit_cards')"
          @click="
            selectedData = selection_array[0];
            printCards();
          "
          class="mx-3 group cursor-pointer relative"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6 inline-block rounded-lg bg-blue-300"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z"
            />
          </svg>
          <div
            class="scale-0 transform transition-all ease-linear group-hover:scale-100 absolute"
          >
            {{ $t("actions.print") }}
          </div>
        </div>
        <div
          v-if="hasPrivilege('cards_edit_cards')"
          @click="
            selectedData = selection_array[0];
            exportCsv(selectedData.id);
          "
          class="group cursor-pointer relative mr-3"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6 inline-block rounded-lg bg-yellow-300"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            stroke-width="2"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
            />
          </svg>
          <div
            class="scale-0 transform transition-all ease-linear group-hover:scale-100 absolute"
          >
            {{ $t("actions.export_csv") }}
          </div>
        </div>
        <div
          v-if="hasPrivilege('cards_delete_cards')"
          @click="
            selectedData = selection_array[0];
            showDelete = true;
          "
          class="group cursor-pointer relative"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6 inline-block bg-red-500 text-white rounded-lg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
            />
          </svg>
          <div
            class="scale-0 transform transition-all ease-linear group-hover:scale-100 absolute"
          >
            {{ $t("actions.delete") }}
          </div>
        </div>
      </div>
      <pagination-row
        class="mt-2"
        @triggerupdate="fetchDataPortion"
        :meta="meta"
        :row="filters.rows"
      />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import PillDefault from "../components/PillDefault.vue";
import DeleteConfirm from "../components/modals/DeleteConfirm.vue";
import PaginationRow from "../components/PaginationRow.vue";
import TablesContext from "../components/contexts/TablesContext.vue";
import ColumnsSelect from "../components/modals/ColumnsSelect.vue";
import ProfileDetails from "../components/modals/ProfileDetails.vue";
import TableCheckBox from "../components/formElements/TableCheckBox.vue";
import { mapGetters } from "vuex";
import { hasPrivilege } from "../global_constants.js";
export default {
  components: {
    PillDefault,
    DeleteConfirm,
    PaginationRow,
    TablesContext,
    ColumnsSelect,
    TableCheckBox,
    ProfileDetails,
  },
  computed: {
    ...mapGetters(["user"]),
  },
  name: "Batches",
  data() {
    return {
      dataList: null,
      meta: { last_page: 0 },
      selectedData: null,
      showDetails: false,
      showDelete: false,
      showContext: false,
      loading: true,
      showFilters: false,
      fullList: false,
      filters: {
        rows: 50,
        page: "",
        save: false,
        name: "",
        quantity: "",
        price: "",
        prefix: "",
        profile: "",
        username_length: "",
        password_length: "",
        allowed_sessions: "",
        created_at: "",
      },
      checkedBox: true,
      allSelected: false,
      showColumnsSelect: false,
      columns: {
        name: { name: this.$t("batches.name"), enabled: true },
        quantity: { name: this.$t("batches.quantity"), enabled: false },
        fresh_cards: { name: this.$t("batches.fresh_cards"), enabled: false },
        price: { name: this.$t("batches.price"), enabled: false },
        prefix: { name: this.$t("batches.prefix"), enabled: false },
        profile: { name: this.$t("batches.profile"), enabled: true },
        username_length: {
          name: this.$t("batches.username_length"),
          enabled: false,
        },
        password_length: {
          name: this.$t("batches.password_length"),
          enabled: false,
        },
        allowed_sessions: { name: this.$t("batches.sessions"), enabled: false },
        is_mac: { name: this.$t("batches.is_mac"), enabled: false },
        created_at: { name: this.$t("batches.created_at"), enabled: true },
      },
      autoUpdate: false,
      selection_array: [],
      debounce: null,
    };
  },
  async created() {
    this.prepareFilters();
    this.applyFilters();
    this.prepareColumns();
  },
  methods: {
    hasPrivilege(privilege) {
      return hasPrivilege(this.user, privilege);
    },
    async fetchData() {
      if (this.filters.rows > 0 || this.filters.page > 0) {
        this.fetchDataPortion(this.filters.rows, this.filters.page);
        this.selection_array = [];
        return;
      }
      const response = await axios.get("cards");
      this.dataList = response.data.data;
      this.meta = response.data.meta;
      this.loading = false;
    },
    prepareFilters() {
      var filters = localStorage.getItem("batches_filters");
      if (filters != null) {
        filters = JSON.parse(filters);
        if (filters.save) this.filters = filters;
        else this.filters.rows = filters.rows;
      }
    },
    prepareColumns() {
      var cols = localStorage.getItem("batches_columns");
      if (cols != null) {
        cols = JSON.parse(cols);
        this.columns = {
          name: {
            name: this.$t("batches.name"),
            enabled: cols[0].enabled,
            sort: false,
          },
          quantity: {
            name: this.$t("batches.quantity"),
            enabled: cols[1].enabled,
            sort: false,
          },
          fresh_cards: {
            name: this.$t("batches.fresh_cards"),
            enabled: cols[2].enabled,
            sort: false,
          },
          price: {
            name: this.$t("batches.price"),
            enabled: cols[3].enabled,
            sort: false,
          },
          prefix: {
            name: this.$t("batches.prefix"),
            enabled: cols[4].enabled,
            sort: false,
          },
          profile: {
            name: this.$t("batches.profile"),
            enabled: cols[5].enabled,
            sort: false,
          },
          username_length: {
            name: this.$t("batches.username_length"),
            enabled: cols[6].enabled,
            sort: false,
          },
          password_length: {
            name: this.$t("batches.password_length"),
            enabled: cols[7].enabled,
            sort: false,
          },
          allowed_sessions: {
            name: this.$t("batches.sessions"),
            enabled: cols[8].enabled,
            sort: false,
          },
          is_mac: {
            name: this.$t("batches.is_mac"),
            enabled: cols[9] != null ? cols[9].enabled : false,
            sort: false,
          },
          created_at: {
            name: this.$t("batches.created_at"),
            enabled: cols[10] != null ? cols[10].enabled : false,
            sort: false,
          },
        };
      }
    },
    async applyFilters() {
      this.loading = true;
      var requestString = "cards?filters=1";
      if (this.filters.page != "")
        requestString += `&page=${this.filters.page}`;
      if (this.filters.rows != "")
        requestString += `&rows=${this.filters.rows}`;
      if (this.filters.name != "")
        requestString += `&name=${this.filters.name}`;
      if (this.filters.quantity != "")
        requestString += `&quantity=${this.filters.quantity}`;
      if (this.filters.price != "")
        requestString += `&price=${this.filters.price}`;
      if (this.filters.prefix != "")
        requestString += `&prefix=${this.filters.prefix}`;
      if (this.filters.profile != "")
        requestString += `&profile=${this.filters.profile}`;
      if (this.filters.username_length != "")
        requestString += `&username_length=${this.filters.username_length}`;
      if (this.filters.password_length != "")
        requestString += `&password_length=${this.filters.password_length}`;
      if (this.filters.allowed_sessions != "")
        requestString += `&allowed_sessions=${this.filters.allowed_sessions}`;
      if (this.filters.created_at != "")
        requestString += `&created_at=${this.filters.created_at}`;
      if (this.sort_col)
        requestString += `&order_by=${this.sort_col}&order_dir=${this.sort_dir}`;
      clearTimeout(this.debounce);
      this.debounce = setTimeout(async () => {
        const response = await axios.get(requestString);
        this.dataList = response.data.data;
        if (this.filters.save)
          localStorage.setItem("batches_filters", JSON.stringify(this.filters));
        if (this.dataList.length < 1 && this.filters.page != 1) {
          this.filters.page = 1;
          this.applyFilters();
        }
        if (this.sort_col) {
          if (this.sort_dir == "ASC") {
            this.dataList.sort((a, b) =>
              a[this.sort_col] > b[this.sort_col]
                ? 1
                : b[this.sort_col] > a[this.sort_col]
                ? -1
                : 0
            );
          } else {
            this.dataList.sort((a, b) =>
              a[this.sort_col] < b[this.sort_col]
                ? 1
                : b[this.sort_col] < a[this.sort_col]
                ? -1
                : 0
            );
          }
        }
        this.meta = response.data.meta;
        this.loading = false;
      }, 1000);
    },
    async fetchDataPortion(rows, currentPage) {
      this.filters.rows = rows;
      this.filters.page = currentPage;
      this.loading = true;
      var requestString = `cards?rows=${rows}&page=${currentPage}`;
      const response = await axios.get(requestString);
      this.dataList = response.data.data;
      this.meta = response.data.meta;
      this.loading = false;
    },
    editData(selectedData) {
      this.$router.push(`/cards/${selectedData.id}/edit`);
    },
    async deleteData() {
      const response = await axios.delete(`cards/${this.selectedData.id}`);
      if (response.status == 200) {
        this.selection_array = [];
        this.fetchData();
        this.showDelete = false;
        this.$router.push({ query: { success: "success" } });
      }
    },
    formatDate(date) {
      return moment(date).format("yyyy-MM-DD HH:mm");
    },
    openContextMenu(e, data) {
      this.selectedData = data;
      this.$refs.menu.open(e);
    },
    checkAll() {
      if (this.allSelected) {
        this.selection_array = [];
        this.dataList.forEach((element) => {
          element.selected = false;
        });
        this.allSelected = false;
      } else {
        this.selection_array = [];
        this.dataList.forEach((element) => {
          element.selected = true;
          this.selection_array.push(element);
        });
        this.allSelected = true;
      }
    },
    selectData(data) {
      if (data.selected == false) {
        data.selected = true;
        this.selection_array.push(data);
      } else {
        data.selected = false;
        this.selection_array.splice(this.selection_array.indexOf(data), 1);
      }
    },
    updateColumns(updated) {
      this.columns = updated;
      this.showColumnsSelect = false;
    },
    async toggleAutoUpdate() {
      this.autoUpdate = !this.autoUpdate;
      while (this.autoUpdate) {
        this.applyFilters();
        await new Promise((r) => setTimeout(r, 30000));
      }
    },
    printCards() {
      this.$router.push(`/cards/${this.selectedData.id}/print`);
      this.$store.commit("changeKey");
    },
    sort(param) {
      this.sort_col = param;
      if (!this.columns[param].sort) {
        this.sort_dir = "ASC";
        Object.entries(this.columns).forEach(([key, val]) => {
          val.sort = false;
        });
        this.columns[param].sort = true;
      } else {
        this.sort_dir = "DESC";
        Object.entries(this.columns).forEach(([key, val]) => {
          val.sort = false;
        });
        this.columns[param].sort = false;
      }
      this.applyFilters();
    },
    exportCsv(id) {
      window.location.href =
        localStorage.getItem("server_api") + `export/${id}`;
    },
    toggleSaveFilters() {
      this.filters.save = !this.filters.save;
      localStorage.setItem("batches_filters", JSON.stringify(this.filters));
    },
  },
};
</script>
