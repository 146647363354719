export var SERVER_URL = localStorage.getItem("server");
export var POP_MESSAGE = true;
export function hasPrivilege(user, privilege) {
  if (!user.data.privileges) return false;
  if (user.data.type == 'sysadmin') return true
  return user.data.privileges.indexOf(privilege) != -1;
}
export function hasSsrPrivilege(settings, privilege) {
  return settings.data.features[privilege];
}
export function TOGGLE_POP() {
  POP_MESSAGE = !POP_MESSAGE;
}
